import { useState, useEffect } from "react";
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from "@mui/material";
import { DialogContentText } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import MapAlertCard from "../MapAlertCard";

export const AddRouteDialog = ({
    open,
    onConfirm,
    onClose,
}) => {

    useEffect(() => {
        setTimeout(() => {
            setAlertBox(false);
          }, 8000);
    })

    const handleTitleFieldChange = (e) => {
        setTitle(e.target.value)
    }
    const handleDescFieldChange = (e) => {
        setDesc(e.target.value)
    }
    const handleSubmit = () => {
        if (isAlertBox) setAlertBox(false)
        if (handleValidation()) {
            onConfirm(desc, title)
            setDesc(null)
            setTitle(null)
            openInstructionBox()
        }
        else {
            setAlertBox(true)
        }

    }

    function handleValidation() {
        let formIsValid = true;
        if (!title) {
            formIsValid = false
        }
        return formIsValid
    }

    const openInstructionBox = () => {
        if(localStorage.getItem('instructionAddRoad') === undefined || localStorage.getItem('instructionAddRoad') === null) {
            if(instructionAlert){
                setInstructionAlert(false)
            }else{
                setInstructionAlert(true)
            }
        }
    }

    const closeInstructionBox = () => {
        localStorage.setItem('instructionAddRoad', true)
        setInstructionAlert(false)
    }

    var [desc, setDesc] = useState("")
    var [title, setTitle] = useState("")
    const [isAlertBox, setAlertBox] = useState(false)
    const [instructionAlert, setInstructionAlert] = useState(false)

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Wprowadź informacje o nowej trasie
                </DialogTitle>
                <DialogContent>
                    <TextField
                        name="title"
                        margin="dense"
                        id="user-phone"
                        label="Tytuł trasy"
                        type="text"
                        required
                        fullWidth
                        onChange={handleTitleFieldChange}
                    />
                    <TextField
                        name="desc"
                        margin="dense"
                        id="user-phone"
                        label="Opis Trasy"
                        type="text"
                        fullWidth
                        onChange={handleDescFieldChange}
                    />
                    <DialogActions>
                        <Button onClick={onClose} color="error">
                            Zrezygnuj
                        </Button>

                        <Button onClick={handleSubmit} color="primary" autoFocus>
                            Zacznijmy dodawać punkty
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>


            <Dialog
                open={instructionAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <InfoIcon color="warning" fontSize="large" style={{marginRight:'5px'}}/>
                        <span> Informacja</span>
                    </div>
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Podczas tworzenia nowej trasy, pierwszymi punktami jakie dodajesz to punkt początkowy a następnie końcowy trasy.<br/>
                    <b>S</b> oznacza punkt początkowy<br/>
                    <b>X</b> oznacza punkt końcowy
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={closeInstructionBox} autoFocus>
                    OK, nie pokazuj mi tego ponownie
                </Button>
                <Button onClick={openInstructionBox} autoFocus>
                    OK
                </Button>
                </DialogActions>
            </Dialog>



            {isAlertBox ? <MapAlertCard type="error" value="Nie podano tytułu trasy!" /> : ''}
        </>
    )

}

export default AddRouteDialog