import * as React from 'react';
import { Box, Collapse, Typography, Paper } from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TableFooter,
  TablePagination,
  TextField,
  InputAdornment,
  Tooltip
} from '@mui/material';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import GroupIcon from '@mui/icons-material/Group';
import { getPermission } from '../auth/ApiAuth';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import { useSelector } from 'react-redux'

const infoRow ={
  marginTop:"7px",
  marginBottom:"7px",
  marginLeft:"5px"

}

const searchBlock = {
  display: 'block',
}

const searchNone = {
  display: 'none',
}

const useStyles = makeStyles(()=> ({
    collapseBox: {
      borderRadius: '10px',
    },
  })
)

const UserListStats = ({
  users,
  counters,
  isAddable,
  onAddUser,
  onEditUser,
  onDeleteUser,
  editableUsers,
  addableUsers,
  isActiveField,
  overallLessonsField,
  monthLessonsField,
  isArchive
}) => {
  const classes = useStyles()
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [filteredUsers, setFilteredUsers] = React.useState(users)
  const [mappedUsers, setMappedUsers] = React.useState([])
  const [paginatedUsers, setPaginatedUsers] = React.useState()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const [rowCounter, setRowCounter] = React.useState(users.length)
  const [viewSearch, setViewSearch] = React.useState(false)

  const isCounterLoaded = useSelector(state => state.lesson.isCounterLoaded)

  function searchUser() {
    if (viewSearch === false) {
        setViewSearch(true)
    } else {
        setViewSearch(false)
    }
}

  function handleRowsPerPageChange(e){
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  function paginateUsers(array = mappedUsers){
    if (rowsPerPage === -1) {
      setPaginatedUsers(mappedUsers)
      return
    }
    let result = mappedUsers.slice(page * rowsPerPage, (parseInt(page, 10) + 1) * rowsPerPage)
    setPaginatedUsers(result)
  }

  function filterUsers(value){
    if (value === '') {
        setFilteredUsers(users)
        return
    }
    let result = users.filter(elem => {
        let valueLowerCase = value.toLowerCase()

        if (elem.first_name.toLowerCase().includes(valueLowerCase)) return true
        else if (elem.last_name.toLowerCase().includes(valueLowerCase)) return true
        else if (elem.email.toLowerCase().includes(valueLowerCase)) return true
        else if (elem.email.toLowerCase().includes(valueLowerCase)) return true
        else if (elem.phoneNumber.toLowerCase().includes(valueLowerCase)) return true
        else return false
    })

    setFilteredUsers(result)
    setRowCounter(result.length)
  }

  function mapUsers(array){
    
    let filteredResults = array.filter( user => user.isActive !== isArchive );

    let results = filteredResults.map((user) => (
      <Row key={user.pk} row={user}/>
    ))

    if (results.length === 0){
      results = [<TableRow key={'EmptyUsers'}>
        <TableCell colSpan={7}>
            Nie znaleziono użytkowników
        </TableCell>
      </TableRow>]
    }

    if (Array.isArray(results)){
      let paginatedResults = results.slice(page * rowsPerPage, (parseInt(page, 10) + 1) * rowsPerPage)
      setPaginatedUsers(paginatedResults);
    }
    return results;
  }

  function timeConv(value) {
    var sec_num = parseInt(value, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
  }

  function getCounter(userID){
    let results = counters.filter((counter) => {
      // eslint-disable-next-line
        if (counter.pk == userID) return true
        else return false
    })

    if (results.length > 0)
        return results[0]['summaryTimeOfLessons'] / 60
    else
        return <RotateRightIcon style={{animation: 'spin 1s linear infinite'}}/>
  }

  function Row(props){
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [detailOpen, setDetailOpen] = React.useState(false);

    return (

      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          {matchesMobile ?
            (
                <TableCell >
                    <div style={infoRow}>{row.first_name}</div>
                    <div style={infoRow}>{row.last_name}</div>

                    {getPermission('admin') ? (<>
                      <div style={infoRow}>{row.email}</div>
                    </>):(<></>)}

                    <div style={infoRow}>{row.phoneNumber}</div>
                </TableCell>

            )
            :
            (
                <>
                    <TableCell scope="row" >{row.first_name}</TableCell>
                    <TableCell scope="row" >{row.last_name}</TableCell>

                    {getPermission('admin') ? (<>
                      <TableCell scope="row" >{row.email}</TableCell>
                    </>):(<></>)}

                    <TableCell scope="row" >{row.phoneNumber || 'brak'}</TableCell>

                </>
            )}

            {overallLessonsField && (
                <TableCell scope="row" align="center">
                    {
                      getCounter(row.pk)
                    }
                </TableCell>
            )}
            {monthLessonsField && (
                <TableCell scope="row" align="center">
                    {row.numberOfLessons}
                </TableCell>
            )}
            {isActiveField && (
                <TableCell>
                    {row.isActive ? <CheckCircleIcon sx={{ color: "#00b300" }} /> : <CancelIcon sx={{ color: "#CF0000" }} />}
                </TableCell>
            )}

            {editableUsers && (
                <>
                    <TableCell align="right">
                        <Tooltip title="Szczegółowe statystyki">
                          <IconButton
                              onClick={() => setOpen(!open)}
                              style={{ borderRadius: "50%" }}
                          >
                                <EqualizerIcon color="warning"/>
                          </IconButton>
                        </Tooltip>
                        {getPermission('admin') ? (<>
                          <Tooltip title="Edycja danych kursanta">
                            <IconButton
                                onClick={() => onEditUser(row)}
                                style={{ borderRadius: "50%" }}
                            >
                                <EditIcon color="primary"/>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Usuń kursanta">
                            <IconButton
                                onClick={() => onDeleteUser(row)}
                                style={{ borderRadius: "50%" }}
                            >
                                <DeleteIcon color="error"/>
                            </IconButton>
                          </Tooltip>
                        </>):(<></>)}
                    </TableCell>
                </>
            )}
        </TableRow>

        {/*Lista rozwijalna*/}
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit className={classes.collapseBox} style={{backgroundColor: '#f3f7ff'}}>
              <Box sx={{ margin: 1 }}>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                  <Box>
                    <Typography variant="h6" gutterBottom component="div">
                      Szczegółowe informacje dotyczące kursanta
                    </Typography>
                  </Box>
                  <Box>
                    <Tooltip title="Lista popełnionych błędów">
                      <IconButton onClick={()=>setDetailOpen(!detailOpen)}>
                        <FormatListNumberedIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                {matchesMobile ? (
                  <Table size="small" aria-label="purchases">
                  <TableBody>

                      <TableRow key={row.pk}>
                        <TableCell>
                          <p>Numer identyfikacyjny: {row.pk}</p>
                          <p>Numer identyfikacyjny: {row.pk}</p>
                          <p>Przejechany dystans (km): {parseFloat(row.distanceTraveled/1000).toFixed(2)}</p>
                          <p>Sumaryczny czas jazdy: {timeConv(row.drivingTime)}</p>
                          <p>Liczba popełnionych błędów {row.mistakesCount}</p>
                          <p>Sumaryczna wartość błędów {row.mistakesValue}</p>
                        </TableCell>
                      </TableRow>

                  </TableBody>
                  </Table>
                ) : (
                  <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Numer identyfikacyjny</TableCell>
                      <TableCell>Przejechany dystans (km)</TableCell>
                      <TableCell>Sumaryczny czas jazdy</TableCell>
                      <TableCell>Liczba popełnionych błędów</TableCell>
                      <TableCell>Sumaryczna wartość błędów</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                      <TableRow key={row.pk}>
                        <TableCell component="th" scope="row">
                          {row.pk}
                        </TableCell>
                        <TableCell>{parseFloat(row.distanceTraveled/1000).toFixed(2)}</TableCell>
                        <TableCell>{timeConv(row.drivingTime)}</TableCell>
                        <TableCell>{row.mistakesCount}</TableCell>
                        <TableCell>{row.mistakesValue}</TableCell>
                      </TableRow>

                  </TableBody>
                  </Table>
                )}
              </Box>

              {/* Szczegółowe info o popełnionych błędach */}
              <Collapse in={detailOpen} timeout="auto" unmountOnExit className={classes.collapseBox} style={{backgroundColor: '#eaf0ff', paddingBottom: '10px'}}>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div" sx={{ml: 5}}>
                    Lista popełnionych błędów
                  </Typography>
                </Box>
                {matchesMobile ?
                  <Table size="small" aria-label="purchases">
                    <TableBody>
                      {
                        row.mistakesList.length === 0 ?
                          <TableRow>
                            <TableCell>Brak błędów</TableCell>
                          </TableRow>
                        :
                          row.mistakesList.map((elem)=>
                            <TableRow key={elem}>
                              <TableCell>
                                <p>Nazwa trasy: {elem.routeName}</p>
                                <p>Data: {elem.date}</p>
                                <p>Wartość: {elem.value}</p>
                                <p>Opis: {elem.description}</p>
                                <hr />
                              </TableCell>
                            </TableRow>
                          )
                      }
                    </TableBody>
                  </Table>
                :
                  <Table size="small" aria-label="purchases" sx={{ml: 5, mr: 5}}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nazwa trasy</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Wartość</TableCell>
                        <TableCell>Opis</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        row.mistakesList.length === 0 ?
                          <TableRow>
                            <TableCell colSpan={4}>Brak błędów</TableCell>
                          </TableRow>
                        :
                          row.mistakesList.map((elem)=>
                            <TableRow key={elem}>
                              <TableCell>{elem.routeName}</TableCell>
                              <TableCell>{elem.date}</TableCell>
                              <TableCell>{elem.value}</TableCell>
                              <TableCell>{elem.description}</TableCell>
                            </TableRow>
                          )
                      }
                    </TableBody>
                  </Table>
                }
              </Collapse>

            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  /* eslint-disable */
  React.useEffect(() => {
    setMappedUsers(mapUsers(filteredUsers))
  },[filteredUsers, isCounterLoaded])

  React.useEffect(() => {
      setFilteredUsers(users)
      setRowCounter(users.length)
  },[users])

  React.useEffect(() => {
      if (mappedUsers.length !== 0 && Array.isArray(mappedUsers)) paginateUsers()
  },[rowsPerPage, page])
  /* eslint-disable */
  return (
    <TableContainer component={Paper} style={{marginTop:'100px'}}>
      <Table padding={matchesMobile ? "none" : "normal"}>
        <TableHead>
          <TableRow style={viewSearch ? searchBlock : searchNone}>
            <TableCell colSpan={7} align="right">
              <TextField
                label="Szukaj użytkownika"
                variant="standard"
                size="small"
                type="search"
                onChange={e => filterUsers(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            {matchesMobile ?
            (
                <TableCell >
                    <div style={infoRow}>Kursant</div>
                </TableCell>
            )
            :
            (
                <>
                    <TableCell scope="row" >Imię</TableCell>
                    <TableCell scope="row" >Nazwisko</TableCell>

                    {getPermission('admin') ? (<>
                      <TableCell scope="row" >Email</TableCell>
                    </>):(<></>)}

                    <TableCell scope="row" >Telefon</TableCell>

                </>
            )}

            {overallLessonsField && (
                <TableCell align="center">
                    Liczba odbytych lekcji
                </TableCell>
            )}
            {monthLessonsField && (
                <TableCell align="center">
                    Liczba lekcji w tym miesiącu
                </TableCell>
            )}
            {isActiveField && (
                <TableCell>Aktywny</TableCell>
            )}

            {addableUsers && (
                <TableCell align="right">
                  {getPermission('admin') ? (<>
                    {isArchive ? (
                      <Tooltip title="Aktywni kursanci">
                        <IconButton to="/Students" component={RouterLink} style={{ borderRadius: "50%" }}>
                            <GroupIcon color='warning' />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Archiwum kursantów">
                        <IconButton to="/ArchiveStudents" component={RouterLink} style={{ borderRadius: "50%" }}>
                            <HistoryToggleOffIcon color='warning' />
                        </IconButton>
                      </Tooltip>
                    )}
                    { isAddable ?
                      <Tooltip title="Tworzenie nowego kursanta">
                        <IconButton onClick={onAddUser} style={{ borderRadius: "50%" }}>
                            <PersonAddIcon color='primary' />
                        </IconButton>
                      </Tooltip>
                    : null }
                  </>):(<></>)}
                  <Tooltip title="Wyszukaj kursanta">
                    <IconButton onClick={searchUser}>
                      <SearchIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
            )}
            </TableRow>
        </TableHead>

        <TableBody>
          {paginatedUsers}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
                count={rowCounter}
                onPageChange={(e, newPage) => {setPage(newPage); }}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10,20,50,{ value: -1, label: 'Wszystko' }]}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count !== -1 ? count : `więcej niż ${to}`}`}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
export default UserListStats
