import React from "react";
//import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import icon from '../../images/currentLocation.png'
import L from "leaflet"
import { Box } from "@mui/system";
import { Marker, Popup, LayerGroup, Circle } from 'react-leaflet'

const DefaultIcon = new L.icon({
    iconUrl: icon,
    iconShadow: iconShadow,
    iconSize: [40, 35]
    //iconAnchor: [13, 41],
}
)

const LocationMarker = ({
    position,
}) => {

    return position === null ? null : (
        <Box>
            <Marker position={position} icon={DefaultIcon} >
                <Popup>Jesteś tutaj</Popup>
            </Marker>
            <LayerGroup>
                <Circle
                    center={position}
                    stroke={false}
                    radius={position.accuracy}
                />
            </LayerGroup>
        </Box>



    )


};

export default LocationMarker