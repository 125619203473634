import { MAPS } from "../const/actionTypes";
import Route from "../../model/Route";

const initialState = {
    center: {
        lat: 51.12415747884065,
        lng: 17.034409162354304,
    },
    zoom: 13,
    draggable: true,
    Routes: [],
    editedRoute: new Route(),
    navigationRoute: new Route(),
    removedPk: [],
    routeDistance: 0,
    routeTime: 0,
    count: 0,
    userCurrentLocation: {
        lat: null,
        lng: null,
        accuracy: null,
    },
    teacherCurrrentLesson: [],
    showRoute: true,
    routeSavingNeeded: false,
    teachersPoints: [],
    Points: []
}

const mapReducer = (state = initialState, action) => {
    switch (action.type) {
        case MAPS.SET_ROUTES:
            return {
                ...state,
                Routes: action.Data,
                routeSavingNeeded: false
            }
        case MAPS.DELETE_MARKER:
            const editedRoute = state.editedRoute
            editedRoute.routes = editedRoute.routes.filter((item) => item.pk !== action.index)
            return {
                ...state,
                editedRoute: editedRoute,
                routeSavingNeeded: true
            };
        case MAPS.SET_ROUTE_INFO:
            return {
                ...state,
                routeDistance: action.Distance,
                routeTime: action.Time
            };
        case MAPS.CHANGE_MARKER_ORDER:
            return {
                ...state,
                editedRoute: new Route(action.edited),
                routeSavingNeeded: true
            }
        case MAPS.EDITED_ROUTE_POINT_DRAGGED:
            return {
                ...state,
                editedRoute: new Route(action.newRoute),
                routeSavingNeeded: true
            };
        case MAPS.SET_EDITED_ROUTE:
            return {
                ...state,
                editedRoute: new Route(action.editedRoute),
                routeSavingNeeded: false
            }
        case MAPS.ADD_POINT_TO_ROUTE:
            return {
                ...state,
                editedRoute: new Route(action.edited),
                routeSavingNeeded: true,
                count: action.count,
            }
        case MAPS.RESET_EDITED_ROUTE:
            return {
                ...state,
                editedRoute: new Route(),
                removedPk: []
            }
        case MAPS.RESET_ROUTE_INFO:
            return {
                ...state,
                routeDistance: 0,
                routeTime: 0,
            };
        case MAPS.ADD_REMOVED_ID:
            return {
                ...state,
                removedPk: [...state.removedPk, action.removedPk]
            }
        case MAPS.SET_USER_CURRENT_LOCATION:
            return {
                ...state,
                userCurrentLocation: action.latlng
            }
        case MAPS.SET_MAP_VISIBILITY:
            return {
                ...state,
                showRoute: action.visable
            }
        case MAPS.SET_NAVIGATION_ROUTE:
            return {
                ...state,
                navigationRoute: action.navigationRoute
            }
        case MAPS.RESET_NAVIGATION_ROUTE:
            return {
                ...state,
                navigationRoute: new Route()
            }
        case MAPS.DELETE_NAVIGATION_MARKER:
            const navigationRoute = state.navigationRoute
            navigationRoute.routes = navigationRoute.routes.filter((item) => item.pk !== action.index)
            return {
                ...state,
                navigationRoute: navigationRoute,
            };
        case MAPS.ADD_NAVIGATION_MARKER:
            return {
                ...state,
                navigationRoute: new Route(action.edited),
                count: action.count,
            }
        case MAPS.NAVIGATION_MARKER_DRAGED:
            return {
                ...state,
                navigationRoute: new Route(action.newRoute),
            };
        case MAPS.SET_TEACHER_POINTS:
            return {
                ...state,
                teachersPoints: action.tPoints
            }
        case MAPS.SET_NONE:
            return {
                ...state
            }
        case MAPS.SET_IS_CREATED_BY_ADMIN:
            return {
                ...state,
                editedRoute: action.editedRoute
            }
        case MAPS.SET_TEACHER_CURRENT_LESSON:
            return {
                ...state,
                teacherCurrrentLesson: action.currentLesson
            }
        case MAPS.RESET_TEACHER_CURRENT_LESSON:
            return {
                ...state,
                teacherCurrrentLesson: []
            }
        case MAPS.SET_POINT:
            return {
                ...state,
                Points: action.Data,
            }
        default:
            return state;
    }
}

export default mapReducer