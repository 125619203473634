import { React, useState, useEffect } from "react";
import { Box, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import HistoryIcon from '@mui/icons-material/History';
import BarChartIcon from '@mui/icons-material/BarChart';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { Tooltip } from '@mui/material';
import Zoom from '@mui/material/Zoom';

const theme = createTheme({
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    border: "1px solid #909090",
                    backdropFilter: "blur(2px)"
                },
            },
        }
    },
});

const useStyles = makeStyles((theme) => ({
    InfoBox: {
        display: "flex",
        flexDirection: "row",
        width: '75%',
    },
    Card: {
        margin: "5px -0.5px 5px -0.5px",
        width: "100%",
        overflow: 'visible',
        borderLeft: "1px solid gray",
        borderRight: "1px solid gray",
        padding: '8px',
        textAlign: 'center',
    },
    Grid: {
        margin: "-12px"
    },
    Bar: {
        marginLeft: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto'
    },

    //MOBILE VERSION

    InfoBoxMobile: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "1%"
    },
    CardMobile: {
        margin: "-0.5px",
        padding: "5px 5px 5px 15px",
        borderBottom: '1px solid gray',
        borderTop: '1px solid gray',
        overflow: 'visible',
        //textAlign: 'center'
    },
    MobileBar: {
        padding: '4px',
        display: 'flex',
        justifyContent: 'space-evenly',
        gap: 5
    },
}))

const MapFixedSettings = (props) => {

    const [time, setTime] = useState(props.time)
    const [timerInterval, setTimerInterval] = useState()
    const [open, setOpen] = useState(true)

    const matchesMobile = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();

    function timeConv(value) {
        var sec_num = parseInt(value, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    }
    /* eslint-disable */
    useEffect(() => {
        if (props.isNavigationStared === false){
            clearInterval(timerInterval);
            setTime(0)
        } else {
            if (props.isNavigationPaused === false) {
                setTimerInterval(setInterval(()=>{
                    setTime((time) => time + 1)
                    props.onSetTime()
                },1000))
            } else {
                clearInterval(timerInterval);
            }
        }
    },[props.isNavigationStared, props.isNavigationPaused])
    /* eslint-enable */

    return (
        <ThemeProvider theme={theme}>
            <Collapse in={open || !matchesMobile} timeout="auto" collapsedSize={40}>
                <Box>
                    <Box className={matchesMobile ? classes.InfoBoxMobile : classes.InfoBox} style={{width: '100%'}}>
                        <Box sx={{ mr: matchesMobile ? 0 : 'auto', ml: matchesMobile ? 'auto' : 0 }}>
                            <Button onClick={() => setOpen(!open)} style={{display: matchesMobile ? 'block' : 'none' }}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                            </Button>
                        </Box>
                        <Box className={matchesMobile ? classes.InfoBoxMobile : classes.InfoBox}>
                            <Box className={matchesMobile ? classes.CardMobile : classes.Card}>
                                <Typography variant="subheading" component="h4" >
                                    <b>Długość trasy</b>
                                </Typography>
                                {Math.round(props.routeData.routeDistance * 1) / 1000} km
                            </Box>
                            <Box className={matchesMobile ? classes.CardMobile : classes.Card} >
                                <Typography variant="subheading" component="h4" >
                                    <b>Czas przejazdu</b>
                                </Typography>
                                {timeConv(time)}
                            </Box>
                            <Box className={matchesMobile ? classes.CardMobile : classes.Card} >
                                <Typography variant="subheading" component="h4" >
                                    <b>Planowy czas trasy</b>
                                </Typography>
                                {timeConv(props.routeData.routeTime)}
                            </Box>
                            <Box className={matchesMobile ? classes.CardMobile : classes.Card}>
                                <Typography variant="subheading" component="h4" >
                                    <b> Tytuł trasy </b>
                                </Typography>
                                {props.routeData.editedRoute.title ? props.routeData.editedRoute.title : '---'}
                            </Box>
                            <Box className={matchesMobile ? classes.CardMobile : classes.Card}>
                                <Typography variant="subheading" component="h4" >
                                    <b> Lekcja</b>
                                </Typography>
                                {props.currentLesson.constructor === Object ? "Lp. " + props.currentLesson.driverLessons + " - " + props.currentLesson.driverFullName : '---'}
                            </Box>
                        </Box>
                        <Box className={!matchesMobile ? classes.Bar : classes.MobileBar}>
                            <Button onClick={props.onStatisticsClick} sx={{mr: !matchesMobile ? 1 : 0, lineHeight: 1}} disabled={!props.isStatsDisabled} variant="outlined">
                                <Tooltip title="Statystyki wybranej lekcji" placement='left' TransitionComponent={Zoom} arrow>
                                    <BarChartIcon/>
                                </Tooltip>
                                {matchesMobile ? 'Statystyki lekcji' : null}
                            </Button>
                            <Button onClick={props.onArchiveClick} sx={{mr: !matchesMobile ? 3 : 0, lineHeight: 1}} disabled={props.isNavigationStared} variant="outlined">
                                <Tooltip title="Archiwum przejechanych tras" placement='left' TransitionComponent={Zoom} arrow>
                                    <HistoryIcon/>
                                </Tooltip>
                                {matchesMobile ? 'Archiwum tras' : null}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Collapse>
        </ThemeProvider>
    )
}

export default MapFixedSettings