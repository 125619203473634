import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    TableBody,
    TableRow,
    TableHead,
    TableCell,
    Table,
    TextField,
    Box,
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { getUserCounter } from '../redux/actions/lessons';

import moment from 'moment';

const Calculator = ({user}) => {
    const dispatch = useDispatch()

    const [date, setDate] = useState();
    const [rate, setRate] = useState(0);
    const [userHours, setUserHours] = useState(0);
    const [userRealHours, setUserRealHours] = useState(0);

    const counters = useSelector(state => state.lesson.userCounter)
    const defaultRate = useSelector(state => state.settings.settingsList.defaultRate)

    useEffect(()=>{
        if (counters['userID'] === user['pk']){
            setUserHours(counters['counter'] / 60);
            setUserRealHours(counters['realCounter'] / 60);
        }
    //eslint-disable-next-line
    },[counters])

    useEffect(()=>{
        setRate(defaultRate)
    },[defaultRate])

    useEffect(()=>{
        let startDate = moment(date).startOf('month').format('YYYY-MM-DD HH:mm')
        let endDate = moment(date).endOf('month').format('YYYY-MM-DD HH:mm')
        dispatch(getUserCounter(user.pk, startDate, endDate))
        //eslint-disable-next-line
    },[date])

    return (
        <Box style={{backgroundColor: '#eaf0ff', padding: '5px', borderRadius: '7px', marginBottom: '10px'}}>
            <Box fullWidth style={{'borderBottom' : '1px solid rgb(224, 224, 224)', 'padding':'10px'} }>
                <TextField
                    type="number"
                    label="Stawka godzinowa"
                    value={rate}
                    onChange={(event)=>{ setRate(event.target.value) }}
                    size="small"
                    sx={{m: 2}}
                />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        views={['year', 'month']}
                        label="Miesiąc"
                        value={date}
                        onChange={(newValue) => setDate(newValue)}
                        renderInput={(params) => <TextField {...params} helperText={null} size="small" sx={{m: 2}}/>}
                    />
                </LocalizationProvider>
            </Box>
            <Table size="small" aria-label="purchases" style={{'marginBottom':'10px'}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Numer identyfikacyjny</TableCell>
                        <TableCell>Godziny zarezerwowane</TableCell>
                        <TableCell>Godziny rzeczywistych kursów</TableCell>
                        <TableCell>Kwota</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={user.pk}>
                        <TableCell component="th" scope="row">
                            {user.pk}
                        </TableCell>
                        <TableCell>
                            {userHours}
                        </TableCell>
                        <TableCell>
                            {userRealHours}
                        </TableCell>
                        <TableCell>
                            {userRealHours * rate}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    )
}

export default Calculator