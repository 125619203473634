import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'

import { Container } from '@mui/material'

import { getToken, checkToken, getPermission } from '../auth/ApiAuth';
import Footer from "../components/Footer";
import Box from '@mui/material/Box';
import { useSelector, useDispatch, connect } from 'react-redux';
import { getAllTeachers } from '../redux/actions/teacher';
import { getTypeCounter, setCounterLoading } from '../redux/actions/lessons'
import { setEditedTeacher, editTeacher, resetEditedTeacher, deleteTeacher, updateTeacher, createTeacher } from '../redux/actions/teacher'
import { getAllSettings } from '../redux/actions/settings';

import UsersList from '../components/UserList';
import Navbar from '../components/Navbar';
import DialogEditTeacher from '../components/DialogEditTeacher'
import ConfirmDialog from '../components/DialogConfirmDelete';

import moment from 'moment';

const Instructors = ({
  editedTeacher,
  editTeacher
}) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [teacherDialogOpen, setTeacherDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deletedTeacherId, setDeletedTeacherId] = useState('');

  const teacherCounters = useSelector(state => state.lesson.typeCounter.counter)
  const teachers = useSelector(state => state.teacher)

  useEffect(() => {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD HH:mm');
    const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD HH:mm');

    dispatch(getAllSettings())
    dispatch(setCounterLoading(false))
    dispatch(getAllTeachers())
    dispatch(getTypeCounter('teacher', startOfMonth, endOfMonth))
  }, [dispatch])

  if (checkToken(getToken()) || getPermission('student') || getPermission('teacher')) {
    history.push("/Login");
  }

  const handleSubmitTeacher = (RegData) => {
    if (editedTeacher.isEdited()) {
      dispatch(updateTeacher(RegData, editedTeacher.pk))
    } else {
      dispatch(createTeacher(RegData))
    }
    setTeacherDialogOpen(false)
  }
  const handleEditTeacherClick = (teacher) => {
    dispatch(setEditedTeacher(teacher))
    setTeacherDialogOpen(true)
  }
  const handleAddTeacherClick = () => {
    setTeacherDialogOpen(true);
  }
  const handleTeacherDialogClose = () => {
    dispatch(resetEditedTeacher())
    setTeacherDialogOpen(false)
  }

  const handleDeleteTeacherClick = (teacher) => {
    setDeletedTeacherId(teacher.pk)
    setConfirmDialogOpen(true)
  }
  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false)
  }
  const handleConfirmDeleteTeacher = () => {
    dispatch(deleteTeacher(deletedTeacherId));
    setConfirmDialogOpen(false);
    setDeletedTeacherId('');
  }
  const confirmContentText = `Usunięcie konta instruktora jest operacją nieodwracalną.
  Czy na pewno chcesz usunąć konto tego użytkownika?`;
  return (
    <Box>
      <Container component="main" maxWidth="lg">
        <Navbar />

        <DialogEditTeacher
          open={teacherDialogOpen}
          editedTeacher={editedTeacher}
          onChange={editTeacher}
          onClose={handleTeacherDialogClose}
          onSubmit={handleSubmitTeacher}

        />
        <ConfirmDialog
          open={confirmDialogOpen}
          title="Usuwanie Konta instruktora"
          contentText={confirmContentText}
          onClose={handleConfirmDialogClose}
          onConfirm={handleConfirmDeleteTeacher}
        />
        <UsersList
          users={teachers.TeacherList}
          counters={teacherCounters}
          onEditUser={handleEditTeacherClick}
          onAddUser={handleAddTeacherClick}
          onDeleteUser={handleDeleteTeacherClick}          
        />
      </Container>
      <Footer/>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  editedTeacher: state.teacher.editedTeacher
})
const mapDispatchToProps = (dispatch) => ({
  editTeacher: (editedTeacherData) => dispatch(editTeacher(editedTeacherData))
})

export default connect(mapStateToProps, mapDispatchToProps)(Instructors);
