import { NAVIGATION } from "../const/actionTypes";

const initialState = {
    prevLocation: [],
    distance: 0,
    speed: 0,
    time: 0,
};
const navigationReducer = (state = initialState, action) => {
    switch (action.type) {
        case NAVIGATION.SET_PREV_LOCATION:
            return {
                ...state,
                prevLocation: action.prevLocation
            };
        case NAVIGATION.RESET_PREV_LOCATION:
            return {
                ...state,
                prevLocation: []
            };
        case NAVIGATION.SET_SPEED:
            return {
                ...state,
                speed: action.speed,
            };
        case NAVIGATION.RESET_SPEED:
            return {
                ...state,
                speed: 0,
            };
        case NAVIGATION.SET_DISTANCE:
            return {
                ...state,
                distance: action.distance
            };
        case NAVIGATION.RESET_DISTANCE:
            return {
                ...state,
                distance: 0,
            };
        case NAVIGATION.SET_TIME:
            return {
                ...state,
                time: state.time + 1
            }
        case NAVIGATION.RESET_TIME:
            return {
                ...state,
                time: 0
            }
        default:
            return state;
    }
};

export default navigationReducer;
