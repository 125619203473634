import { LESSONS } from "../const/actionTypes";
import { callAjax, getPermission } from "../../auth/ApiAuth";

export const setLessons = (item) => {
    item.forEach(element => {
        element['title'] = 'K: ' + element['driverFullName'] + '\n';
        element['title'] += 'Tel: ' + element['driverPhoneNumber'] + '\n';
        element['title'] += element['location'] ? 'Lok: ' + element['location'] + '\n' : 'Lok: Brak\n';
        element['title'] += 'Lp: ' + element['driverLessons']+ '\n';
        element['title'] += 'Opis: ' + (element['description']==null ? "Brak" : element['description']) + '\n'
        element['start'] = new Date(element['start']);
        element['end'] = new Date(element['end']);
    });
    return {
        type: LESSONS.SET_LESSONS,
        item
    }
}
export const setAllLessons = (item) => {
    item['title'] = 'K: ' + item['driverFullName'] + '\n';
    item['title'] += 'Tel: ' + item['driverPhoneNumber'] + '\n';
    item['title'] += item['location'] ? 'Lok: ' + item['location'] + '\n' : 'Lok: Brak\n';
    item['title'] += 'Lp: ' + item['driverLessons'];
    item['title'] += 'Opis: ' + item['description'] + '\n';
    item['start'] = new Date(item['start']);
    item['end'] = new Date(item['end']);
    return {
        type: LESSONS.SET_ALL_LESSONS,
        item
    }
}
export const setUserCounter = (userID, counter, realCounter) => {
    let item = {
        'userID': userID,
        'counter': counter,
        'realCounter': realCounter
    }
    return {
        type: LESSONS.SET_USER_COUNTER,
        item
    }
}
export const setTypeCounter = (accountType, counter) => {
    let item = {
        'accountType': accountType,
        'counter': counter
    }
    return {
        type: LESSONS.SET_TYPE_COUNTER,
        item
    }
}
export const setCounterLoading = (state) => {
    return {
        type: LESSONS.SET_COUNTER_LOADING,
        state
    }
}

export const clearLessons = () => ({
    type: LESSONS.CLEAR_LESSONS
});

function fetchLessons(startDate=null, endDate=null){
    var baseurl
    if (startDate===null || endDate===null)
        baseurl = `${process.env.REACT_APP_BASE_URL}/api/lessons/`;
    else
        baseurl = `${process.env.REACT_APP_BASE_URL}/api/lessons/?startDate=` + startDate + '&endDate=' + endDate;
    let result = callAjax(baseurl);
    return result
}

function fetchUserCounter(userID, start, end){
    let baseurl = `${process.env.REACT_APP_BASE_URL}/api/counter/?user=` + userID + '&start=' + start + '&end=' + end;
    let result = callAjax(baseurl)
    return result
}

function fetchTypeCounter(accountType, start, end){
    let baseurl = `${process.env.REACT_APP_BASE_URL}/api/counter/?accountType=` + accountType + '&start=' + start + '&end=' + end;
    let result = callAjax(baseurl)
    return result
}

export function getLessons(startDate, endDate, teacherID=null) {
    function filter(element, index, array){
        return element['teacher']===teacherID;
    }
    function filterStudent(element, index, array){
        if (element['driver']===null || element['driver']===parseInt(localStorage.userPK)) return true;
        else return false;
    }
    return async (dispatch) => {
        let Lessons = await fetchLessons(startDate, endDate);
        dispatch(clearLessons());
        if (teacherID!=null) {
            Lessons = Lessons.filter(filter);
        }
        if (getPermission('student')){
            Lessons = Lessons.filter(filterStudent);
        }
        await dispatch(setLessons(Lessons));
    }
}

export function getAllLessons(teacherID=null) {
    function filter(element, index, array){
        return element['teacher']===teacherID;
    }
    return async (dispatch) => {
        let Lessons = await fetchLessons();
        if (teacherID!=null) {
            Lessons = Lessons.filter(filter);
        }
        await Lessons.map(lesson => dispatch(setAllLessons(lesson)));
    }
}

export function getUserCounter(userID, start, end) {
    return async (dispatch) => {
        let counter = await fetchUserCounter(userID, start, end);
        dispatch(setUserCounter(userID, counter[0]['summaryTimeOfLessons'], counter[0]['realTimeOfLessons']));
    }
}
export function getTypeCounter(accountType, start, end) {
    return async (dispatch) => {
        let counter = await fetchTypeCounter(accountType, start, end);
        dispatch(setTypeCounter(accountType, counter));
    }
}


export default (
    setLessons,
    getLessons,
    getAllLessons,
    clearLessons
)
