import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import RoomIcon from '@mui/icons-material/Room';


const useStyles = makeStyles((theme) => ({
    title:{
        fontWeight:"bold"
    },
    points:{
        marginLeft:"10px"
    },
    markerIcon:{
        marginRight:"10px"
    }
}))


const SaveRouteDialog = ({
    open,
    onClose,
    onConfirm,
    Route
}) => {

    const classes = useStyles();
    const {
        title,
        desc,
        routes
    } = Route


    return  (
        <>
            <>
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" style={{borderBottom:'1px solid #737373', margin:'10px'}}>
                        Tworzenie nowej trasy
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={5}>
                                <Typography className={classes.title}>
                                    Tytuł trasy:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} >
                                <Typography>
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography className={classes.title}>
                                    Opis trasy:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    {desc}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.title}>
                                    Punkty trasy:
                                </Typography>
                                {routes.sort((a,b)=>{return a.value-b.value}).map((point) =>
                                    <Grid container direction="row" alignItems="center" key={point} style={{flexWrap: 'nowrap', borderBottom: '1px solid black', padding: '5px 0px'}}>
                                        <Grid item>
                                            <RoomIcon className={classes.markerIcon}/>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" className={classes.points}>
                                                { point.description }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>

                        </Grid>

                        <DialogActions>
                            <Button onClick={onClose} color="error">
                                Zrezygnuj
                            </Button>

                            <Button onClick={onConfirm} color="primary" autoFocus>
                                Zapisz trasę
                            </Button>
                        </DialogActions>
                    </DialogContent>

                </Dialog>
            </>

        </>
    )
}

export default SaveRouteDialog