import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {Box, Typography} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton } from '@mui/material';
import Slide from '@mui/material/Slide';

const useStyles = makeStyles((theme) => ({
    footer:{
        backgroundColor: '#3f51b5!important',
        color:"#fff",
        width:'100%',
        borderRadius:'5px 5px 0px 0px',
        padding:'1px 0px',
        position: 'fixed',
        bottom:'0px',
        zIndex:'1200'
    },
    smallFooter:{
        backgroundColor: 'rgba(63, 81, 181, 0.64)!important',
        color:"#fff",
        borderRadius:'5px 5px 0px 0px',
        padding:'0px 0px',
        position: 'fixed',
        right:'30px',
        bottom:'0px',
        zIndex:'1199'
    },
  }));

export default function Footer(){
    const classes = useStyles();
    const [visibleFooter, setVisibleFooter] = useState(localStorage.getItem('footer') === 'true' ? true : false)

    const handleChange = () => {
        let currentState = localStorage.getItem('footer')
        localStorage.setItem('footer', currentState === 'true' ? 'false' : 'true' )
        currentState = localStorage.getItem('footer')
        setVisibleFooter(currentState === 'true' ? true : false)
    }

    useEffect(()=>{
        if (localStorage.getItem('footer',null)===null){
            localStorage.setItem('footer',true)
        }
    })


    return(
    <Box>
        <Slide direction="up" in={visibleFooter} timeout={800} mountOnEnter unmountOnExit>
            <Box className={classes.footer}>
                <Box style={{
                    textAlign: 'center',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    flexWrap:'wrap'
                }}>
                    <Typography variant='body2'>
                        © 2022 DriveProGPS NGITS
                    </Typography>
                    <IconButton size="small" color="inherit" onClick={handleChange}>
                        <KeyboardArrowDownIcon />
                    </IconButton>
                </Box>
            </Box>
        </Slide>

        <Slide direction="up" in={!visibleFooter} timeout={800}>
            <Box className={classes.smallFooter}>
                <IconButton size="small" color="inherit" onClick={handleChange}>
                    <KeyboardArrowUpIcon fontSize="small"/>
                </IconButton>
            </Box>
        </Slide>
    </Box>
    )
  }
