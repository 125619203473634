import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

const ConfirmDialog = ({
    open,
    onClose,
    onConfirm,
    title,
    contentText,
}) => (
    <>
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {contentText}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ m: 1 }}>
                <Button onClick={onClose} color="error">
                    Anuluj
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    Tak
                </Button>
            </DialogActions>
        </Dialog>
    </>
);

export default ConfirmDialog;