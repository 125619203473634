import React, { useState } from "react";
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from "@mui/styles";
import { Menu, IconButton, useTheme, useMediaQuery, MenuItem, Switch } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { getPermission, logout } from '../auth/ApiAuth';

const useStyles = makeStyles({
  buttonCollapse: {
    margin: "10px",
    boxShadow: "none"
  },
  menuIcon:{
    color:'#fff',
  },
  menuButton:{
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 13,
  }
});

const ButtonAppBarCollapse = props => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setanchorEl] = useState(null);
  const isAdmin = useState(getPermission('admin'))

  const handleSwitchChange=()=>{
    if(isAdmin[0])localStorage.setItem('permission',btoa("teacher"))
    else localStorage.setItem('permission',btoa("admin"))
    window.location.reload(true);
  }

  const getMenuItems = [
    <MenuItem
      key="menuButtonCcalendar"
      component={RouterLink}
      to="/calendar"
      color="inherit"
      className={classes.menuButton}
    >
      KALENDARZ
    </MenuItem>,
    <MenuItem
      key="menuButtonMap"
      component={RouterLink}
      to="/map"
      color="inherit"
      className={classes.menuButton}
    >
      MAPA
    </MenuItem>
  ]

  const getInstructorItems = [
    <MenuItem
      key="menuButtonStudents"
      component={RouterLink}
      to="/students"
      color="inherit"
      className={classes.menuButton}
    >
      KURSANCI
    </MenuItem>
  ]

  const getAdminMenuItems =  [
    <MenuItem
      key="menuButtonSettings"
      component={RouterLink}
      to="/settings"
      color="inherit"
      className={classes.menuButton}
    >
      USTAWIENIA
    </MenuItem>,
    <MenuItem
      key="menuButtonStudents"
      component={RouterLink}
      to="/students"
      color="inherit"
      className={classes.menuButton}
    >
      KURSANCI
    </MenuItem>,
    <MenuItem
      key="menuButtonInstructors"
      component={RouterLink}
      to="/instructors"
      color="inherit"
      className={classes.menuButton}
    >
      INSTRUKTORZY
    </MenuItem>
  ]

  return (
    <div className={classes.buttonCollapse} style={{display: !matches ? 'block' : 'none'}}>
      <IconButton onClick={(event)=>{setIsOpen(true); setanchorEl(event.currentTarget)}}>
        <MenuIcon
          className={classes.menuIcon}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        open={isOpen}
        onClose={()=>{setIsOpen(false)}}
      >
        <MenuItem
          disableRipple
          className={`classes.userIcon classes.menuButton`}
          color="inherit"
        >
          <AccountCircleIcon />
          {localStorage.getItem('userName')}
        </MenuItem>
        {getPermission('admin') || localStorage.getItem('email') === `${process.env.REACT_APP_ADMIN_APP_EMAIL}`?(
        <MenuItem
          color="inherit"
          className={classes.menuButton}
        >
       
          <Switch 
        checked={isAdmin[0]? true : false}
        onChange={handleSwitchChange} 
        />
        </MenuItem>
        ):(<></>)}
        {getPermission('admin') || getPermission('teacher') ? getMenuItems : '' }
        {getPermission('teacher') ? getInstructorItems : '' }
        {getPermission('admin') ? getAdminMenuItems : '' }
        <MenuItem
          onClick={logout}
          color="inherit"
          className={classes.menuButton}
        >
          WYLOGUJ
        </MenuItem>
      </Menu>
    </div>
  )};

export default ButtonAppBarCollapse;
