import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Autocomplete,
    TextField
} from "@mui/material"
import DirectionsIcon from '@mui/icons-material/Directions';
import { useEffect, useState } from "react";
import moment from 'moment';

const ArchiveDialog = ({
    lessons,
    onChange,
    isAdmin,
    onClose,
}) => {
    const [routeList, setRouteList] = useState([])

    function getRoutes() {
        let menuItems = []
        if (isAdmin){
            for (let array of lessons) {
                if (array['driver']!==null && array.isArchived){
                    let title = 'Kursant: ' + array['driverFullName'] + ", Lp: " + array['driverLessons'] + ', Start: ' + moment(new Date(array['start'])).format('MM-DD HH:mm') + '-' + moment(new Date(array['end'])).format('HH:mm')
                    menuItems.push({ label: title, id: {route: array['route'], lesson: array['id']} });
                }
            }
        } else {
            for (let array of lessons) {
                if (array['driver']!==null && array['teacher']===parseInt(localStorage.userPK) && array.isArchived){
                    let title = 'Kursant: ' + array['driverFullName'] + ", Lp: " + array['driverLessons'] + ', Start: ' + moment(new Date(array['start'])).format('MM-DD HH:mm') + '-' + moment(new Date(array['end'])).format('HH:mm')
                    menuItems.push({ label: title, id: {route: array['route'], lesson: array['id']} });
                }
            }
        }

        return menuItems
    }

    useEffect(() => {
        setRouteList(getRoutes())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box>
            <Dialog
                open={true}
                fullWidth={true}
                onClose={onClose}
            >
                <DialogTitle>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <DirectionsIcon fontSize="large" /> Archiwum przejechanych tras
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Autocomplete
                        options={routeList}
                        onChange={(event, value) => {onChange(value.id['route'], value.id['lesson'])}}

                        isOptionEqualToValue={(option, route) => option.id === route.id}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Wybierz lekcję"
                                variant="standard"
                                sx={{ mt: 2 }}
                                fullWidth
                            />
                        }
                    />
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default ArchiveDialog