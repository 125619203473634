export const ALERT = {
    SET_ALERT: 'SET_ALERT',
    REMOVE_ALERT: 'REMOVE_ALERT',
};

export const AUTH = {
    // register
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAIL: 'REGISTER_FAIL',
    // login / logout
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGOUT: 'LOGOUT',
    // user
    USER_LOADED: 'USER_LOADED',
    SET_USER_LOADING: 'SET_USER_LOADING',
    // auth token
    NO_AUTH_TOKEN: 'NO_AUTH_TOKEN',
    AUTH_ERROR: 'AUTH_ERROR',
};

export const DRIVERS = {
    SET_DRIVERS: 'SET_DRIVERS',
    CLEAR_DRIVERS: 'CLEAR_DRIVERS',
    SET_DRIVERS_LOADING: 'SET_DRIVERS_LOADING',
    SET_EDITED_DRIVER: 'SET_EDITED_DRIVER',
    EDIT_DRIVER: 'EDIT_DRIVER',
    RESET_EDITED_DRIVER: 'RESET_EDITED_DRIVER',
};

export const TEACHERS = {
    SET_TEACHERS: 'SET_TEACHERS',
    CLEAR_TEACHERS: 'CLEAR_TEACHERS',
    SET_TEACHERS_LOADING: 'SET_TEACHERS_LOADING',
    SET_EDITED_TEACHER: 'SET_EDITED_TEACHER',
    EDIT_TEACHER: 'EDIT_TEACHER',
    RESET_EDITED_TEACHER: 'RESET_EDITED_TEACHER',
};

export const LESSONS = {
    SET_LESSONS: 'SET_LESSONS',
    SET_ALL_LESSONS: 'SET_ALL_LESSONS',
    CLEAR_LESSONS: 'CLEAR_LESSONS',
    SET_LESSONS_LOADING: 'SET_LESSONS_LOADING',
    LESSON_CREATED: 'LESSON_CREATED',
    SET_LESSON_FORM_DATA: 'SET_LESSON_FORM_DATA',
    SET_LESSON_SEARCH_PARAMS: 'SET_LESSON_SEARCH_PARAMS',
    RESET_LESSON_FORM_DATA: 'RESET_LESSON_FORM_DATA',
    LESSONS_FETCH_FAIL: 'LESSONS_FETCH_FAIL',
    LESSONS_CREATE_FAIL: 'LESSONS_CREATE_FAIL',
    LESSON_EDIT_SUCCESS: 'LESSON_EDIT_SUCCESS',
    LESSON_EDIT_FAIL: 'LESSON_EDIT_FAIL',
    LESSON_DELETE_SUCCESS: 'LESSON_DELETE_SUCCESS',
    LESSON_DELETE_FAIL: 'LESSON_DELETE_FAIL',
    SET_USER_COUNTER: 'SET_USER_COUNTER',
    SET_TYPE_COUNTER: 'SET_TYPE_COUNTER',
    SET_COUNTER_LOADING: 'SET_COUNTER_LOADING'
};

export const CALENDAR = {
    SET_CALENDAR_FILTER: 'SET_CALENDAR_FILTER',
    SET_CALENDAR_DATES: 'SET_CALENDAR_DATES',
}

export const SETTINGS = {
    SET_CURRENT_SETTINGS: 'SET_CURRENT_SETTINGS',
    SET_SETTINGS_LOADING: 'SET_SETTINGS_LOADING',
    CHANGE_SETTINGS: 'EDIT_SETTINGS',
    RESET_SETTINGS: 'RESET_EDITED_SETTINGS',
    CLEAR_SETTINGS_STATE: 'CLEAR_SETTINGS_STATE',
    SET_ACTIVE_SETTINGS: 'SET_ACTIVE_SETTINGS',
    EDIT_SETTINGS: 'EDIT_SETTINGS',
    RESET_EDITED_SETTINGS: 'RESET_EDITED_SETTINGS',
};

export const MAPS = {
    RESET_MARKER_DATA: 'RESET_MARKER_DATA',
    RESET_EDITED_MARKER: 'RESET_EDITED_MARKER',
    DELETE_MARKER: 'DELETE_MARKER',
    SET_ROUTE_INFO: 'SET_ROUTE_INFO',
    RESET_ROUTE_INFO: 'RESET_ROUTE_INFO',
    CHANGE_MARKER_ORDER: 'CHANGE_MARKER_ORDER',
    SET_ROUTES: 'SET_ROUTES',
    SET_USER_ROUTES: 'SET_USER_ROUTES',
    SET_EDITED_ROUTE: 'SET_EDITED_ROUTE',
    EDITED_ROUTE_POINT_DRAGGED: 'EDITED_ROUTE_POINT_DRAGGED',
    RESET_EDITED_ROUTE: 'RESET_EDITED_ROUTE',
    ADD_POINT_TO_ROUTE: 'ADD_POINT_TO_ROUTE',
    EDIT_MARKER_ORDER: 'EDIT_MARKER_ORDER',
    ADD_REMOVED_ID: 'ADD_REMOVED_ID',
    SET_USER_CURRENT_LOCATION: 'SET_USER_CURRENT_LOCATION',
    SET_MAP_VISIBILITY: 'SET_MAP_VISIBILITY',
    SET_TEACHER_POINTS: 'SET_TEACHER_POINTS',
    RESET_TEACHER_POINTS: 'RESET_TEACHER_POINTS',
    SET_NAVIGATION_ROUTE: 'SET_NAVIGATION_ROUTE',
    RESET_NAVIGATION_ROUTE: 'RESET_NAVIGATION_ROUTE',
    SET_IS_CREATED_BY_ADMIN: 'SET_IS_CREATED_BY_ADMIN',
    SET_TEACHER_CURRENT_LESSON: 'SET_TEACHER_CURRENT_LESSON',
    RESET_TEACHER_CURRENT_LESSON: 'RESET_TEACHER_CURRENT_LESSON',
    DELETE_NAVIGATION_MARKER: 'DELETE_NAVIGATION_MARKER',
    ADD_NAVIGATION_MARKER: 'ADD_NAVIGATION_MARKER',
    NAVIGATION_MARKER_DRAGED: 'NAVIGATION_MARKER_DRAGED',
    SET_NONE: 'SET_NONE',
    SET_POINT: 'SET_POINT',
}

export const NAVIGATION = {
    SET_PREV_LOCATION: 'SET_PREV_LOCATION',
    RESET_PREV_LOCATION: 'RESET_PREV_LOCATION',
    SET_SPEED: 'SET_SPEED',
    RESET_SPEED: 'RESET_SPEED',
    SET_DISTANCE: 'SET_DISTANCE',
    RESET_DISTANCE: 'RESET_DISTANCE',
    SET_TIME: 'SET_TIME',
    RESET_TIME: 'RESET_TIME',
}
