import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import { checkToken, getToken, getPermission } from '../auth/ApiAuth';

import Footer from "../components/Footer";
import DialogEvent from '../components/DialogEvent'
import SideBar from '../components/SideBar'
import Navbar from '../components/Navbar'
import MainCalendar from '../components/MainCalendar'

import { getLessons, getUserCounter, getTypeCounter, setCounterLoading } from '../redux/actions/lessons';
import { getAllDrivers } from '../redux/actions/driver';
import { getAllTeachers } from '../redux/actions/teacher';
import { getAllSettings } from '../redux/actions/settings';
import { getAllPoints, getAllRoutes } from '../redux/actions/map'

import eventEmitter from '../event/EventEmitter'
import moment from 'moment'

export function Calendar() {
  const matches = useMediaQuery('(min-width:600px)', { noSsr: true });
  const dispatch = useDispatch();

  const [isWarning, setIsWarning] = useState(false)
  const [dates, setDates] = useState(getDates(new Date()))
  const [accountType, setAccountType] = useState(null);
  const [eventValues, setEventValues] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const lessons = useSelector(state => state.lesson);
  const teachers = useSelector(state => state.teacher);
  const drivers = useSelector(state => state.driver);
  const settings = useSelector(state => state.settings.settingsList);
  const userCounter = useSelector(state => state.lesson.userCounter);
  const typeCounter = useSelector(state => state.lesson.typeCounter);

  const userTypes = ['admin', 'teacher', 'student']

  let now = new Date();

  useEffect(()=>{
    if (localStorage.getItem('activeFilter') === 'null' || localStorage.getItem('activeFilter') === null)
      dispatch(getLessons(dates[0], dates[1]));
    else
      dispatch(getLessons(dates[0], dates[1], parseInt(localStorage.getItem('activeFilter'))));

    dispatch(getAllTeachers());
    dispatch(getAllDrivers());
    dispatch(getAllSettings());
    dispatch(getAllRoutes());
    dispatch(getAllPoints());

    for (const item of userTypes){
      if (getPermission(item)){
        setAccountType(item);
      }
    }
    //eslint-disable-next-line
  },[dates])

  useEffect(()=>{
    if (lessons.lessonsList.filter((lesson)=>{
      if (moment().isBetween(moment(lesson.start), moment(lesson.end)) && parseInt(localStorage.getItem('userPK'))===lesson.teacher && !lesson.isArchived) return true
      else return false
    }).length!==0) setIsWarning(true)
    else setIsWarning(false)

    if (lessons.lessonsLoaded) getCounter()

    //eslint-disable-next-line
  },[lessons.lessonsList])

  function getCounter(){
    switch (atob(localStorage.getItem('permission'))){
      case 'admin':
        dispatch(getTypeCounter('teacher', dates[0], dates[1]))
        break
      case 'teacher':
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD HH:mm');
        const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD HH:mm');
        dispatch(getUserCounter(localStorage.getItem('userPK'), startOfMonth, endOfMonth))
        break
      default:
        break
    }
  }

  function getDates(date){
    date = new Date(date)
    let day = date.getDay()
    let diff = date.getDate() - day + (day === 0 ? -6:1)
    let startDate = new Date(date.setDate(diff))

    startDate.setHours(0)
    startDate.setMinutes(0)

    let endDate = new Date(startDate)
    endDate.setDate(startDate.getDate() + 7)

    startDate = moment(startDate).format('YYYY-MM-DD HH:mm');
    endDate = moment(endDate).format('YYYY-MM-DD HH:mm');

    return [startDate, endDate]
  }

  let history = useHistory();
  if(checkToken(getToken())){
      history.push("/Login");
  }

  const handleRangeChange = (data) => {
    dispatch(setCounterLoading(false))
    let startDate = moment(data[0]).format('YYYY-MM-DD HH:mm');
    let endDate = data.length!==1 ? moment(data[6]).add(1, 'day').format('YYYY-MM-DD HH:mm') : moment(startDate).add(1, 'day').format('YYYY-MM-DD HH:mm')
    setDates([startDate, endDate])
  }

  const handleSelect = (event = null, isExist) => {
    if (getPermission('teacher') && !isExist){
      if (event.start<now || event.end<now ) return
    }
    if(getPermission('student')){
      if (event.start<now || event.end<now ) return
    }

    if (!isExist) {
      event['driver'] = null;
      event['route'] = null;
      event['startPoint'] = null;
      event['teacher'] = null;
    }

    event['isExist']=isExist;

    switch (accountType){
      case 'student':
        if (moment(event.start) > settings.endDate || moment(event.end) < settings.startDate){
          eventEmitter.emit('popupAlert', ['Nie można zarezerwować lekcji poza aktywnym tygodniem!', 'error'])
          return
        }

        let driverItem = drivers.driversList.filter((item)=>{
          if (item.username===localStorage.userName) return item;
          else return null
        })[0];

        let driverWeekLessonsCount = lessons.lessonsList.filter((item)=>{
          if (item.driver===driverItem.pk) return item
          else return null
        }).length;

        if (driverWeekLessonsCount>=settings.maxLessonsPerWeek){
          eventEmitter.emit('popupAlert', ['Osiągnieto tygodniowy limit lekcji!', 'error'])
          return
        }

        if (driverItem.lessonsCount>=settings.maxLessons){
          eventEmitter.emit('popupAlert', ['Osiągnieto limit lekcji!', 'error'])
          return
        }

        event['driver'] = driverItem.pk
        break
      case 'teacher':
        let teacherItem = teachers.TeacherList.filter((item)=>{
          if (item.username===localStorage.userName) return item;
          else return null
        })[0];

        if (isExist===false)
          event['teacher'] = teacherItem.pk
        break;

      default:
        break;
    }

    setEventValues(event)
    setIsDialogOpen(true)   // to i linia wyżej do poprawki w DialogEvent ! // Dobra byku poprawię ! // git dziena byku
  }
  const handleDialogEeventClose =()=>{
    setIsDialogOpen(false)
  }

  const handleFilterChange = ( pk = null ) => {
    dispatch(getLessons(dates[0], dates[1], pk));
    localStorage.setItem('activeFilter', pk)
  }

  return (
    <Box component="main" maxWidth='false'>
        <Navbar
          isWarning={isWarning}
        />
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: matches ? 'row' : 'column',
            alignContent: 'flex-start',
            p: 1
          }}
        >
          <SideBar
            onFilterChange={handleFilterChange}
          />
          <MainCalendar
            lessons={lessons}
            teachers={teachers}
            onRangeChange={handleRangeChange}
            onSelect={handleSelect}
            now={now}
            userCounter={userCounter}
            typeCounter={typeCounter}
          />
        </Box>
        <DialogEvent
          dates={dates}
          isOpen={isDialogOpen}
          defaultValues={eventValues}
          permission={accountType}
          onClose={handleDialogEeventClose}
        />
        <Footer/>
    </Box>
  );
}

export default Calendar;
