import React, {useEffect, useState} from 'react';
import { checkToken, getToken, getPermission } from '../auth/ApiAuth';
import { useHistory } from 'react-router-dom'
import "react-dates/lib/css/_datepicker.css";
import { getAllSettings, editSettings, saveSetting, resetSetting } from '../redux/actions/settings';
import { useDispatch, useSelector } from 'react-redux';
import SettingsForm from '../components/SettingsForm';
import { connect } from 'react-redux';
import ConfirmDialog from '../components/DialogConfirmDelete';
import AlertCard from "../components/AlertCard";
import Footer from "../components/Footer";

const Settings =({
  activeSettings, 
  editCurrentSettings}) => {

    const dispatch = useDispatch()
    let history = useHistory();
    var info;

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmDialogResetOpen, setConfirmDialogResetOpen] = useState(false);
    const [validDialog, setValidDialog] = useState(false);
    const [succesSave, setSuccesSave] = useState(false);
    const [errorSave, setErrorSave] = useState(false);
  
    if(checkToken(getToken()) || getPermission('student') || getPermission('teacher')){
        history.push("/Login");
    }
  
    useEffect(() => {
      dispatch(getAllSettings())
    },[dispatch])

    const settingsId = useSelector(state => state.settings)
          
    const handleOpenDialog = () =>{
      setConfirmDialogOpen(true)
    }

    const handleCloseDialog = () =>{
      setConfirmDialogOpen(false)
    }

    const handleSave = () => { 
      dispatch(saveSetting(activeSettings, settingsId.settingsList.id))
    }

    const handleDeleteSettings = () => {
      dispatch(resetSetting(settingsId.settingsList.id))
    }

    const handleShowValidDialog = () => {
      setValidDialog(true)
    }

    const handleHideValidDialog = () => {
      setValidDialog(false)
    }

    const handleShowValidResetDialog = () => {
      setConfirmDialogResetOpen(true)
    }

    const handleHideValidResetDialog = () => {
      setConfirmDialogResetOpen(false)
    }

    
    useEffect(() => {
      var params = new URLSearchParams(window.location.search);
      // eslint-disable-next-line
      if(params.get('success') == 1 ){
        setSuccesSave(true)
        // eslint-disable-next-line
      }else if(params.get('success') == 0 ){
        setErrorSave(true)
      }

      const timer = setTimeout(() => {
        setErrorSave(false)
        setSuccesSave(false)
      }, 4000);
          
      return () => clearTimeout(timer);
    },[])

    

    return (
      <>
        {info}
        {validDialog ? (
          <AlertCard value="Tygodniowa liczba lekcji powinna być mniejsza niż maksymalna liczba lekcji!" type="error"/>
        ) : (<></>)}
        
        {succesSave ? (<AlertCard value="Zmiany zapisano pomyślnie!" type="success"/>
        ) : (<></>)}

        {errorSave ? (<AlertCard value="Wystąpił błąd zapisu, spróbuj ponownie!" type="error"/>
        ) : (<></>)}
        
        <SettingsForm 
          settings={activeSettings}
          onChange={editCurrentSettings}
          onSave={handleOpenDialog}
          onDelete={handleShowValidResetDialog}
          showValidDialog={handleShowValidDialog}
          hideValidDialog={handleHideValidDialog}
        />
        <ConfirmDialog
          open={confirmDialogOpen}
          onConfirm={handleSave}
          onClose={handleCloseDialog}
          contentText="Czy na pewno chcesz zaakceptować zmiany?"
        />
        <ConfirmDialog
          open={confirmDialogResetOpen}
          onConfirm={handleDeleteSettings}
          onClose={handleHideValidResetDialog}
          contentText="Czy na pewno chcesz usunąć ustawienia?"
        />
        <Footer/>
      </>
  );
}

const mapStateToProps = (state) => ({
  activeSettings: state.settings.settingsList,
  editedSettings: state.settings.editedSettings
});

const mapDispatchToProps = (dispatch) =>({
  editCurrentSettings:(editedSettings)=>dispatch(editSettings(editedSettings))
})

export default connect(mapStateToProps, mapDispatchToProps)(Settings);