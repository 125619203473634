import { USER_EDITABLE_FIELDS } from "../redux/const/config";

class User {
    constructor(data = {}) {
        const {
            pk, username, accountType, first_name, last_name, email, isActive, mistakesList
        } = data;

        this.pk = pk || '';
        this.email = email || '';
        this.username = username || '';
        this.accountType = accountType || '';
        this.first_name = first_name || '';
        this.last_name = last_name || '';
        this.isActive = isActive !== undefined ? isActive : false;
        this.mistakesList = mistakesList || null;
    }
    isTeacher() {
        return this.accountType === 'teacher';
    }

    isDriver() {
        return this.accountType === 'student';
    }

    isAdmin() {
        return this.role === 'admin';
    }

    isEdited() {
        return !!this.pk;
    }
    getFullName() {
        if (this.firstName && this.lastName) {
            return `${this.first_name} ${this.last_name}`;
        }
        return '';
    }
    getUserEditData(password = '') {
        const data = {};
        Object.keys(this).forEach((key) => {
            if (USER_EDITABLE_FIELDS.includes(key)) {
                const value = this[key];
                data[key] = key === 'phoneNumber' && !value ? null : value;
            }
        });
        if (password) {
            data.password = password;
        }
        return data;
    }


}


export default User

