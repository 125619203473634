import React, { useState } from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


const DEFAULT_START_DATE = moment();
const DEFAULT_END_DATE = moment().add(7, 'days');

const DatePicker = ({
  startDate, endDate, onChange,
}) => {

  const [focusedInput, setFocusedInput] = useState(null);
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <DateRangePicker numberOfMonths={matchesMobile ? 1 : 2}
      startDate={startDate}
      startDateId="startDate"
      endDate={endDate}
      endDateId="endDate"
      onDatesChange={onChange}
      focusedInput={focusedInput}
      onFocusChange={setFocusedInput}
      startDatePlaceholderText="Początek"
      endDatePlaceholderText="Koniec"
      withPortal={matchesMobile}
      small={matchesMobile ? true : false}
      block={true}
    />
  );
};

DatePicker.propTypes = {
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  onChange: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  startDate: DEFAULT_START_DATE,
  endDate: DEFAULT_END_DATE,
};

export default DatePicker;
