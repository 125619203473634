import React, { useEffect, useState } from 'react';
import Slide from '@mui/material/Slide';
import { Alert } from '@mui/material';
import eventEmitter from '../../event/EventEmitter';
import { Snackbar } from '@mui/material';

const SnackAlert = () => {

    const [isError, setError]             = useState(false);
    const [textError, setTextError]       = useState("")
    const [alertVariant, setAlertVariant] = useState("info")
    useEffect(() => {
        eventEmitter.once('popupAlert', text => {
            setTextError(text[0])
            setAlertVariant(text[1])
            handleOpenSnackAlert()
        })
        return () => {
          eventEmitter.off('popupAlert', handleOpenSnackAlert)
        }
    })

    const handleCloseSnackError = () => {
      setError(false)
    }
    const handleOpenSnackAlert = ()=>{
      setError(true)
    }

    return(
        <Snackbar
          onClose={handleCloseSnackError}
          anchorOrigin={{ vertical:'top', horizontal: 'center' }}
          autoHideDuration={3000}
          open={isError}
          TransitionComponent={Slide}
          style={{marginTop:'50px'}}
        >
          <Alert severity={alertVariant} variant="filled">{textError}</Alert>
        </Snackbar>
    )

}

export default SnackAlert;