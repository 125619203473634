import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import { checkToken, getToken, getPermission } from '../auth/ApiAuth';
import Navbar from '../components/Navbar'
import Footer from "../components/Footer";
import Box from '@mui/material/Box';
import UserListStats from '../components/UserListStats'
import DialogEditDriver from '../components/DialogEditDriver'
import ConfirmDialog from '../components/DialogConfirmDelete';
import AlertCard from '../components/AlertCard'

import { getTypeCounter, setCounterLoading } from '../redux/actions/lessons'
import { getAllDrivers, updateDriver, setEditedDriver, editDriver, createDriver, resetEditedDriver, deleteDriver } from '../redux/actions/driver'

import moment from 'moment'

const Students = ({
  editedDriver,
  editDriver
}) => {
  const dispatch = useDispatch()
  let history = useHistory();
  if (checkToken(getToken()) || getPermission('student')) {
    history.push("/Login");
  }

  const [driverDialogOpen, setDriverDialogOpen]   = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deletedDriverId, setDeletedDriverId]     = useState('');
  const [isAlertCardOpen, setIsAlertCardOpen]     = useState(false);

  const studentCounters = useSelector(state => state.lesson.typeCounter.counter)

  const AlertCardConst = (
    <AlertCard
      type="error"
      value="Nie udało się zaktualizować danych kursanta. Spróbuj ponownie."
    />
  )

  useEffect(() => {
    const startDate = moment("2015-01-01T00:00:00").format('YYYY-MM-DD HH:mm');
    const endDate   = moment().format('YYYY-MM-DD HH:mm');

    dispatch(setCounterLoading(false))
    dispatch(getAllDrivers())
    dispatch(getTypeCounter('student', startDate, endDate))
  }, [dispatch])

  const handleSubmitDriver = (RegData, driverData) => {
    if (RegData.password) {
      if (RegData.password.length >= 8) {
        if (editedDriver.isEdited()) {
          dispatch(updateDriver(RegData, editedDriver.pk));
        } else {
          dispatch(createDriver(RegData, driverData));
        }
      } else {
        setIsAlertCardOpen(true);
        window.setTimeout(setIsAlertCardOpen, 2000, false);
      }
    }
    else {
      if (editedDriver.isEdited()) {
        dispatch(updateDriver(RegData, editedDriver.pk));
      } else {
        dispatch(createDriver(RegData, driverData));
      }
    }

    setDriverDialogOpen(false);
  };

  const handleAddDriverClick = () => {
    setDriverDialogOpen(true);
  };
  const handleDriverEditdDialogClose = () => {
    dispatch(resetEditedDriver())
    setDriverDialogOpen(false);
  };
  const handleEditDriverClick = (driver) => {
    dispatch(setEditedDriver(driver))
    setDriverDialogOpen(true);
  };
  const handleDeleteDriverClick = (driver) => {
    setDeletedDriverId(driver.pk)
    setConfirmDialogOpen(true)
  }
  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false)
  }
  const handleConfirmDeleteDriver = async () => {
    dispatch(deleteDriver(deletedDriverId))
    setConfirmDialogOpen(false)
    setDeletedDriverId('');
  }
  const confirmContentText = `Usunięcie konta kursanta jest operacją nieodwracalną.
  Czy na pewno chcesz usunąć konto tego użytkownika?`;

  //const driverAction = dispatch(editDriver())
  //const editedDriver = useSelector(state => state.driver.editedDriver)

  const drivers = useSelector(state => state.driver)
  return (
    <Box>
      <Container component="main" maxWidth="lg">
        <Navbar />
        {isAlertCardOpen ? AlertCardConst : ''}
       
        <DialogEditDriver
          editedDriver={editedDriver}
          open={driverDialogOpen}
          onChange={editDriver}
          onClose={handleDriverEditdDialogClose}
          onSubmit={handleSubmitDriver}
        />
        <ConfirmDialog
          open={confirmDialogOpen}
          onConfirm={handleConfirmDeleteDriver}
          onClose={handleConfirmDialogClose}
          title="Usuwanie konta kursanta"
          contentText={confirmContentText}
        />
        <UserListStats
          users={drivers.driversList}
          counters={studentCounters}
          isAddable={true}
          onAddUser={handleAddDriverClick}
          onEditUser={handleEditDriverClick}
          onDeleteUser={handleDeleteDriverClick}
          isArchive={false}
          isActiveField
          addableUsers
          editableUsers
          overallLessonsField
        />
      </Container>
      <Footer/>
    </Box>
  );
}
const mapStateToProps = (state) => ({
  editedDriver: state.driver.editedDriver
})

const mapDispatchToProps = (dispatch) => ({
  editDriver: (editDriverData) => dispatch(editDriver(editDriverData))
});


export default connect(mapStateToProps, mapDispatchToProps)(Students);
