import './App.css';
import Login from './views/Login';
import Settings from './views/Settings';
import Calendar from './views/Calendar';
import Instructors from './views/Instructors';
import Students from './views/Students';
import ResetPass from './views/ResetPass';
import ArchiveStudents from './views/ArchiveStudents';
import Map from './views/Map';
import ResetPassConfirm from './views/ResetPassConfirm';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Popups from './components/Popup/Popups';
function App() {
  return (

    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/settings" component={Settings} />
        <Route exact path="/map" component={Map} />
        <Route exact path="/calendar" component={Calendar} />
        <Route exact path="/instructors" component={Instructors} />
        <Route exact path="/students" component={Students} />
        <Route exact path="/resetPass" component={ResetPass} />
        <Route exact path="/ArchiveStudents" component={ArchiveStudents} />
        <Route exact path="/ResetPassConfirm/:id/:token" component={ResetPassConfirm} />
      </Switch>
      <Popups />
    </Router>
  );
}

export default App;
