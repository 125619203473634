import { DRIVERS } from "../const/actionTypes";
import { callAjax, postAjax, putAjax, deleteAjax } from "../../auth/ApiAuth";

const RegisterData = {
    username: '',
    password1: '',
    password2: '',
    email: '',
}

export const setDrivers = item => {
    return {
        type: DRIVERS.SET_DRIVERS,
        item
    }
}

export const editDriver = (editedDriverData) => ({
    type: DRIVERS.EDIT_DRIVER,
    editedDriverData
})

const clearDrivers = () => ({
    type: DRIVERS.CLEAR_DRIVERS
});

export const setEditedDriver = (editedDriver) => ({
    type: DRIVERS.SET_EDITED_DRIVER,
    editedDriver,
});
export const resetEditedDriver = () => ({
    type: DRIVERS.RESET_EDITED_DRIVER
})

const fetchDriver = async () => {
    let baseurl = `${process.env.REACT_APP_BASE_URL}/api/user/?accountType=student`;
    return callAjax(baseurl);
}

export function createDriver(RegData, driverData) {
    RegisterData.username = RegData.email.split("@")[0];
    RegisterData.password1 = RegData.password;
    RegisterData.password2 = RegData.password;
    RegisterData.email = RegData.email;
    driverData.username = driverData.email.split("@")[0];
    driverData.accountType = "student";
    driverData.photoPath = null;

    return async (dispatch) => {
        async function updateEndUser(results){
            let driverId = results.user
            driverData.pk = driverId
            let baseurl = `${process.env.REACT_APP_BASE_URL}/api/user/` + driverId + '/'
            await putAjax(baseurl, driverData);
            dispatch(getAllDrivers())
        }

        let baseurl = `${process.env.REACT_APP_BASE_URL}/rest-auth/registration/`
        await postAjax(baseurl, RegisterData, updateEndUser).catch(e => {console.log(e)});
    }
}

export function deleteDriver(driverId) {
    return async (dispatch) => {
        let baseurl = `${process.env.REACT_APP_BASE_URL}/api/user/` + driverId + '/'
        await deleteAjax(baseurl)
        dispatch(getAllDrivers())
    }
}

export function updateDriver(RegData, driverId) {
    RegData.color = ""
    RegData.photoPath = null;
    return async (dispatch) => {
        let baseurl = `${process.env.REACT_APP_BASE_URL}/api/user/` + driverId + '/'
        await putAjax(baseurl, RegData)
        dispatch(getAllDrivers())
    }
}

export function getAllDrivers() {
    return async (dispatch) => {
        try {
            const drivers = await fetchDriver()
            dispatch(clearDrivers());
            dispatch(setDrivers(drivers))
        }
        catch (err) {
        }
    }
}

export default (
    setDrivers,
    editDriver,
    setEditedDriver,
    getAllDrivers,
    createDriver,
    resetEditedDriver,
    updateDriver,
    deleteDriver
)
