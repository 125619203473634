import { TEACHERS } from "../const/actionTypes";

import { callAjax, postAjax, putAjax, deleteAjax } from "../../auth/ApiAuth";

const RegisterData = {
    username: '',
    password1: '',
    password2: '',
    email: '',
}

export const setTeachers = item => {
    return {
        type: TEACHERS.SET_TEACHERS,
        item
    }
}

export const editTeacher = (editedTeacherData) => ({
    type: TEACHERS.EDIT_TEACHER,
    editedTeacherData
});
export const setEditedTeacher = (editedTeacher) => ({
    type: TEACHERS.SET_EDITED_TEACHER,
    editedTeacher
})
export const resetEditedTeacher = () => ({
    type: TEACHERS.RESET_EDITED_TEACHER
})

const clearTeachers = () => ({
    type: TEACHERS.CLEAR_TEACHERS
});

const fetchTeacher = async () => {
    let baseurl = `${process.env.REACT_APP_BASE_URL}/api/user/?accountType=teacher`;
    return callAjax(baseurl);
}

export function createTeacher(RegData) {
    RegisterData.username = RegData.get("email").split("@")[0];
    RegisterData.password1 = RegData.get("password");
    RegisterData.password2 = RegData.get("password")
    RegisterData.email = RegData.get("email");
    RegData.delete("password")
    RegData.append("username", RegisterData.username)
    RegData.append("accountType", "teacher")
    if (typeof RegData.get("photoPath") === typeof null) {
        RegData.delete("photoPath")
    }

    return async (dispatch) => {
        async function updateEndUser(results){
            let teacherId = results.user
            let baseurl = `${process.env.REACT_APP_BASE_URL}/api/user/` + teacherId + '/'
            await putAjax(baseurl, RegData, false);
            dispatch(getAllTeachers())
        }

        let baseurl = `${process.env.REACT_APP_BASE_URL}/rest-auth/registration/`;
        await postAjax(baseurl, RegisterData, updateEndUser);
    }
}

export function deleteTeacher(teacherId) {
    return async (dispatch) => {
        let baseurl = `${process.env.REACT_APP_BASE_URL}/api/user/` + teacherId + '/'
        await deleteAjax(baseurl)
        dispatch(getAllTeachers())
    }
}

export function updateTeacher(RegData, teacherId) {
    if (RegData.get("photoPath") === null) {
        RegData.delete('photoPath');
    }
    return async (dispatch) => {
        let baseurl = `${process.env.REACT_APP_BASE_URL}/api/user/` + teacherId + '/'
        await putAjax(baseurl, RegData, false)
        dispatch(getAllTeachers())
    }

}

export function getAllTeachers() {
    return async (dispatch) => {
        try {
            const Teachers = await fetchTeacher();
            //dispatch(setTeachersLoading());   można użyć do loading screen
            dispatch(clearTeachers());
            dispatch(setTeachers(Teachers));
        }
        catch (err) {
            console.log(err)
        }

    }
}


export default (
    setTeachers,
    editTeacher,
    setEditedTeacher,
    deleteTeacher,
    createTeacher,
    updateTeacher,
    resetEditedTeacher,
    getAllTeachers
)
