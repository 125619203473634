import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getLessons } from '../redux/actions/lessons';
import { getAllDrivers } from '../redux/actions/driver'

import {
    Button,
    Typography,
    TextField,
    FormControl,
    Autocomplete,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material"
import { makeStyles } from "@mui/styles";

import eventEmitter from '../event/EventEmitter'
import { postAjax, deleteAjax, putAjax } from '../auth/ApiAuth'

import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    CancelButton: {
        backgroundColor: '#AA1111',
        '&:hover': {
            backgroundColor: "#CC1111",
        },
        color: "#fff"
    },
    inputForm: {
        marginTop: '10px',
    }
}));

function DialogEvent(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isConfirmBox, setConfirmBox] = useState(false)
    const [description, setDescription] = useState()
    const [eventDuration, setEventDuration] = useState()

    // for submit
    const [selectedDriver, setSelectedDriver] = useState()
    const [selectedTeacher, setSelectedTeacher] = useState()
    const [selectedRoute, setSelectedRoute] = useState()
    const [selectedPoint, setSelectedPoint] = useState()

    // for autocomplete
    const [driverList, setDriverList] = useState([])
    const [teacherList, setTeacherList] = useState([])
    const [routeList, setRouteList] = useState([])
    const [pointList, setPointList] = useState([])

    // selectors
    const drivers = useSelector(state => state.driver)
    const teachers = useSelector(state => state.teacher)
    const routes = useSelector(state => state.map.Routes)
    const points = useSelector(state => state.map.Points)

    const isLessonReserved = (
        props.defaultValues.isExist 
        && props.permission === 'student' 
        && props.defaultValues.driverFullName !== "Brak"
    )

    useEffect(()=>{
        setEventDuration(moment(props.defaultValues.start).format("HH:mm") + " - " + moment(props.defaultValues.end).format("HH:mm"))

        setSelectedDriver(props.defaultValues.driver)
        setSelectedTeacher(props.defaultValues.teacher)
        setSelectedRoute(props.defaultValues.route)
        setSelectedPoint(props.defaultValues.startPoint)
        setDescription(props.defaultValues.description)
    },[props])

    useEffect(()=>{
        setDriverList(getDrivers())
        // eslint-disable-next-line
    },[drivers])

    useEffect(()=>{
        setTeacherList(getTeachers())
        // eslint-disable-next-line
    },[teachers])

    useEffect(()=>{
        setRouteList(getRoutes())
        // eslint-disable-next-line
    },[routes])

    useEffect(()=>{
        setPointList(getPoints())
        // eslint-disable-next-line
    },[points])


    function getDrivers() {
        let options = []
        for (let driver of drivers.driversList) {
            // eslint-disable-next-line
            if(driver.isActive === true){
                let driverText = driver['first_name'] + ' ' + driver['last_name'] + ' (' + driver['email'] + ')';
                options.push({ label: driverText, id: driver['pk'] });
            }
        }
        return options
    }

    function getTeachers() {
        let options = []
        for (let teacher of teachers.TeacherList) {
            let teacherText = teacher['first_name'] + ' ' + teacher['last_name'] + ' (' + teacher['email'] + ')';
            options.push({ label: teacherText, id: teacher['pk'] });
        }
        return options
    }

    function getRoutes() {
        let options = []
        for (let route of routes) {
            if ((route['createdBy'] === Number(localStorage.getItem('userPK')) || route['createdByAdmin']) && !route['isArchived'])
            {
                options.push({ label: route['title'], id: route['pk'] });
            }
        }
        return options
    }

    function getPoints(){
        let options = []
        for (let point of points) {
            options.push({ label: point['description'], id: point['pk'] })
        }
        return options
    }


    const handleDescriptionFieldChange = (e) => {
        if(e.target.value.length>200)
            eventEmitter.emit('popupAlert', ['Zbyt długi ciąg znaków!', 'error'])
        else
            setDescription(e.target.value)
    }

    const deleteButton = (
        <Box sx={{ mr: 'auto' }}>
            <Button
                variant="contained"
                color="error"
                className={classes.CancelButton}
                onClick={() => { handleCancel() }}
            >
                <Typography variant="button">Odwołaj lekcję</Typography>
            </Button>
        </Box>
    );

    const cancelButton = (
        <Box sx={{ mr: 'auto' }}>
            <Button
                variant="contained"
                color="error"
                className={classes.CancelButton}
                onClick={() => { handleCancel() }}
            >
                <Typography variant="button">Anuluj rezewację</Typography>
            </Button>
        </Box>
    );

    function handleCancel() {
        setConfirmBox(true);
    }

    async function handleCancelLesson() {
        let baseurl = `${process.env.REACT_APP_BASE_URL}/api/lessons/` + props.defaultValues.id + '/'
        let data = {
            teacher: props.defaultValues.teacher,
            driver: null,
        }
        await putAjax(baseurl, data);

        if (localStorage.getItem('activeFilter') === 'null' || localStorage.getItem('activeFilter') === null)
            dispatch(getLessons(props.dates[0], props.dates[1]));
        else
            dispatch(getLessons(props.dates[0], props.dates[1], parseInt(localStorage.getItem('activeFilter'))));

        setConfirmBox(false);

        props.onClose()
    }

    async function handleDelete() {
        let baseurl = `${process.env.REACT_APP_BASE_URL}/api/lessons/` + props.defaultValues.id + '/'
        await deleteAjax(baseurl)

        if (localStorage.getItem('activeFilter') === 'null' || localStorage.getItem('activeFilter') === null)
            dispatch(getLessons(props.dates[0], props.dates[1]));
        else
            dispatch(getLessons(props.dates[0], props.dates[1], parseInt(localStorage.getItem('activeFilter'))));

        setConfirmBox(false);

        props.onClose()
    }

    async function handleSubmit() {
        let data = {
            'start': moment(props.defaultValues.start).format('YYYY-MM-DD HH:mm'),
            'end': moment(props.defaultValues.end).format('YYYY-MM-DD HH:mm'),
            'driver': selectedDriver,
            'teacher': selectedTeacher,
            'route': selectedRoute,
            'startPoint': selectedPoint,
            'description': description,
        }

        if (data['teacher'] === null){
            eventEmitter.emit('popupAlert', ['Nie podano instruktora!', 'error'])
            return
        }

        if (props.defaultValues.isExist) {
            let baseurl = `${process.env.REACT_APP_BASE_URL}/api/lessons/` + props.defaultValues.id + '/'
            await putAjax(baseurl, data).catch(e => { });
        } else {
            let baseurl = `${process.env.REACT_APP_BASE_URL}/api/lessons/`
            await postAjax(baseurl, data).catch(e => { });
        }

        if (localStorage.getItem('activeFilter') === 'null' || localStorage.getItem('activeFilter') === null)
            dispatch(getLessons(props.dates[0], props.dates[1]));
        else
            dispatch(getLessons(props.dates[0], props.dates[1], parseInt(localStorage.getItem('activeFilter'))));

        dispatch(getAllDrivers());
        props.onClose()
    }

    function handleOpen() {
        switch (props.permission) {
            case 'teacher':
                if (props.defaultValues.isExist === true && parseInt(localStorage.userPK) !== props.defaultValues.teacher) return false;
                else return props.isOpen;
            case 'student':
                if (props.defaultValues.isExist === false) return false;
                else return props.isOpen;
            case 'admin':
                return props.isOpen;
            default:
                return false;
        }
    }

    return (
        <>
            <Dialog open={handleOpen()} fullWidth>
                <DialogTitle>
                    Utwórz lekcję jazdy
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <DialogContentText>
                            Numer lekcji kursanta:
                        </DialogContentText>

                        <FormControl fullWidth sx={{ mt: 2 }}>

                            {/* duration */}
                            <TextField
                                className={classes.inputForm}
                                label="Godziny"
                                variant="standard"
                                value={eventDuration}
                                fullWidth
                            />

                            {/* teachers */}
                            <Autocomplete
                                disablePortal
                                className={classes.inputForm}
                                options={teacherList}
                                onChange={(event, value) => {
                                    setSelectedTeacher(value === null ? null : value.id)
                                }}
                                disabled={props.permission === 'admin' ? false : true}
                                // eslint-disable-next-line
                                defaultValue={teacherList.filter( (teacher) => { return teacher['id'] == props.defaultValues.teacher ? true : false })[0]}
                                fullwidth
                                renderInput={(params) => <TextField {...params} variant="standard" label="Instruktor" />}
                            />

                            {/* drivers */}
                            <Autocomplete
                                disablePortal
                                className={classes.inputForm}
                                options={driverList}
                                onChange={(event, value) => {
                                    setSelectedDriver(value === null ? null : value.id)
                                }}
                                disabled={props.permission === 'student' ? true : false}
                                // eslint-disable-next-line
                                defaultValue={driverList.filter( (driver) => { return driver['id'] == props.defaultValues.driver ? true : false })[0]}
                                fullwidth
                                renderInput={(params) => <TextField {...params} variant="standard" label="Kursant" />}
                            />

                            {/* routes */}
                            <Autocomplete
                                disablePortal
                                className={classes.inputForm}
                                options={routeList}
                                onChange={(event, value) => {
                                    setSelectedRoute(value === null ? null : value.id)
                                }}
                                disabled={props.permission === 'student' ? true : false}
                                // eslint-disable-next-line
                                defaultValue={routeList.filter( (route) => { return route['id'] == props.defaultValues.route ? true : false })[0]}
                                fullwidth
                                renderInput={(params) => <TextField {...params} variant="standard" label="Domyślna trasa" />}
                            />

                            {/* points */}
                            <Autocomplete
                                disablePortal
                                className={classes.inputForm}
                                options={pointList}
                                onChange={(event, value) => {
                                    setSelectedPoint(value === null ? null : value.id)
                                }}
                                disabled={props.permission === 'student' ? true : false}
                                // eslint-disable-next-line
                                defaultValue={pointList.filter( (point) => { return point['id'] == props.defaultValues.startPoint ? true : false })[0]}
                                fullwidth
                                renderInput={(params) => <TextField {...params} variant="standard" label="Punkt startowy" />}
                            />

                            {/* description */}
                            <TextField
                                value={description}
                                sx={{mb: 2}}
                                className={classes.inputForm}
                                onChange={handleDescriptionFieldChange}
                                label="Opis"
                                variant="standard"
                                fullWidth
                            />

                        </FormControl>

                        <DialogActions style={{ display:'felx', justifyContent: isLessonReserved ? 'space-between' : 'flex-end' , width:'100%' }}>
                            {props.defaultValues.isExist && ['admin', 'teacher'].includes(props.permission) ? deleteButton : ''}
                            {isLessonReserved ? 
                            <Box style={{justifyContent:'flex-start'}}>
                                <Typography style={{fontSize:13}}>
                                    TWOJA ZAREZERWOWANA LEKCJA
                                </Typography>  
                                <Typography style={{fontSize:12}}>
                                    Aby ją odwołać skontaktuj się z administratorem.
                                </Typography>  
                            </Box>
                            : ''}
                            <Box>
                                <Button
                                    color="error"
                                    onClick={() => {
                                        props.onClose()
                                    }}
                                    style={{
                                        alignSelf:'flex-end'
                                    }}
                                >
                                    <Typography sx={{ color: 'red' }} variant="button">Anuluj</Typography>
                                </Button>
                                <Button onClick={() => handleSubmit()}>
                                <Typography variant="button">
                                    {props.permission === 'student' ? "REZERWUJ" : "ZAPISZ"}
                                </Typography>
                            </Button>
                            </Box>
                        </DialogActions>

                    </form>
                </DialogContent>
            </Dialog>

            <Dialog open={isConfirmBox}>
                <DialogTitle>Odwołaj lekcję jazdy</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.permission !== 'student' ?
                            'Czy na pewno chcesz odwołać tą lekcję? To działanie jest nieodwracalne.' :
                            'Czy na pewno chcesz odwołać tą rezerwację?'
                        }
                    </DialogContentText>
                    <DialogActions sx={{ mt: 2 }}>
                        <Button onClick={() => { setConfirmBox(false); }}>
                            <Typography variant="button">Anuluj</Typography>
                        </Button>
                        <Button onClick={() => {
                            props.permission !== 'student' ? handleDelete() : handleCancelLesson();
                        }}>
                            <Typography sx={{ color: 'red' }} variant="button">TAK</Typography>
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default DialogEvent