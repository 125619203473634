import React, { useState } from 'react';

import RotateRightIcon from '@mui/icons-material/RotateRight';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/pl';

import { useSelector } from 'react-redux';

import moment from 'moment'

const localizer = momentLocalizer(moment);
moment.locale('pl', {
  week: {
    dow: 1,
    doy: 1,
  },
});

export default function MainCalendar({
  lessons,
  teachers,
  now,
  onRangeChange,
  onSelect,
  userCounter,
  typeCounter
}) {
  const matches = useMediaQuery('(min-width:600px)', { noSsr: true });

  const [view, setView] = useState(matches ? 'week' : 'day')

  const isCounterLoaded = useSelector(state => state.lesson.isCounterLoaded);

  function eventStyleGetter(event){
    function filter(element, index, array){
      return element['pk'] === event['teacher']
    }
    let eventTeacher = teachers.TeacherList.filter(filter)[0];
    var backgroundColor = eventTeacher ? eventTeacher['color'] : ''

    var style = {
      backgroundColor: backgroundColor,
    }
    return {
      style: style
    };
  }

  function TimeSlot({ children, value }) {
    return React.cloneElement(children, { style: { backgroundColor: now < value ? 'transparent' : '#ededed', borderColor: 'transparent' } })
  }

  function getView() {
    switch (atob(localStorage.getItem('permission'))) {
      case 'admin':
        return view === 'week' ? 'tygodniu' : 'dniu'
      case 'teacher':
        return 'miesiącu'
      default:
        return
    }
  }

  const changeView = () => {
    if (view === 'week') setView('day')
    else setView('week')
  }

  const showCounter = () => {
    if (isCounterLoaded) {
      switch (atob(localStorage.getItem('permission'))) {
        case 'teacher':
          return userCounter.counter / 60
        case 'admin':
          let sum = 0
          typeCounter.counter.forEach((item) => {
            sum += item.summaryTimeOfLessons
          })
          return sum / 60
        default:
          return null

      }
    } else return (<RotateRightIcon style={{ animation: 'spin 1s linear infinite', marginLeft: '5px' }} />)
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        mb: 13,
      }}
    >
      <Box style={{ height: '35px', display: 'flex', alignItems: 'center' }}>
        <Box>
          Liczba godzin w bieżącym {getView()}:&nbsp;
        </Box>
        <Box>
          {showCounter()}
        </Box>
      </Box>
      <BigCalendar
        selectable
        localizer={localizer}
        events={lessons.lessonsList}
        step={30}
        defaultView={view}
        onView={changeView}
        views={['week', 'day']}
        min={new Date(1979, 0, 1, 6, 30)}
        max={new Date(1979, 0, 1, 22, 30)}
        onRangeChange={(data) => onRangeChange(data)}
        onSelectSlot={event => onSelect(event, false)}
        onSelectEvent={event => onSelect(event, true)}
        eventPropGetter={(event) => eventStyleGetter(event)}
        style={{ 'whiteSpace': 'pre-line' }}
        components={{ timeSlotWrapper: TimeSlot }}
        messages={{
          next: 'Następny',
          previous: 'Poprzedni',
          today: 'Dzisiaj',
          tomorrow: 'Jutro',
          month: 'Miesiąc',
          week: 'Tydzień',
          day: 'Dzień',
          agenda: 'Plan',
          date: 'Data',
          event: 'Wydarzenie',
          time: 'Czas',
          noEventsInRange: 'Brak wydarzeń w tym czasie',
        }}
      />
    </Box>
  )
};