import React, { useState } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { Button, useTheme, useMediaQuery, Tooltip, Switch, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ButtonAppBarCollapse from "./ButtonAppBarCollapse";
import { getPermission, logout } from '../auth/ApiAuth';
import Avatar from '@mui/material/Avatar';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { IconButton } from '@mui/material';

const useStyles = makeStyles({
  root: {
    position: "absolute",
    right: 0,
    display: 'flex'
  },
  buttonBar: {
    margin: "10px",
    paddingLeft: "16px",
    right: 0,
    position: "relative",
    width: "100%",
    background: "transparent",
  },
  userIcon: {
    marginRight: "20px"
  },
  warningIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});


const AppBarCollapse = props => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const classes = useStyles();
  const isAdmin = useState(getPermission('admin'))
  
  const handleSwitchChange=()=>{
    if(isAdmin[0])localStorage.setItem('permission',btoa("teacher"))
    else localStorage.setItem('permission',btoa("admin"))
    window.location.reload(true);
  }

  
  return (
  <div className={classes.root}>
    {props.isWarning ?
      <div className={classes.warningIcon}>
        <Tooltip title="Nie rozpoczęto nawigacji dla aktywnej lekcji!" placement="left" sx={{mt: 'auto', mb: 'auto'}} arrow>
          <IconButton to="/map" component={RouterLink}>
            <WarningAmberIcon color="warning" fontSize="large" style={{animation: 'pulse 2s infinite'}}/>
          </IconButton>
        </Tooltip>
      </div>
      : null
    }
    <ButtonAppBarCollapse/>
    <div className={classes.buttonBar} style={{display: matches ? 'block' : 'none' }} id="appbar-collapse">
      <Button disableRipple
        className={classes.userIcon}
        color="inherit"
      >
        <Avatar
          src={localStorage.getItem('imagePath')}
          style={{ marginRight: '10px',}}
          sx={{ width: 35, height: 35 }}
        ></Avatar>

        {localStorage.getItem('userName')}
      </Button>
      {getPermission('admin') || localStorage.getItem('email') === `${process.env.REACT_APP_ADMIN_APP_EMAIL}`?(
        <div style={{
          display:"inline",
        }}>
        <Typography style={{
          display:"inline",
        }}
        fontSize={14}>
        
        {getPermission('admin')?"ADMIN":"INSTRUKTOR"}
          </Typography>
        <Switch 
        color="warning"
        checked={isAdmin[0]? true : false}
        onChange={handleSwitchChange} 
        />
        </div>
        ):(<></>)}
      {getPermission('admin') || getPermission('teacher') ? (
        <>
          <Button color="inherit" to="/calendar" component={RouterLink} >
            KALENDARZ
          </Button>
          <Button color="inherit" to="/map" component={RouterLink} >
            MAPA
          </Button>
          <Button color="inherit" to="/students" component={RouterLink}>
            KURSANCI
          </Button>
        {getPermission('admin') ? (
          <>
            <Button component={RouterLink} to="/instructors" color="inherit">
              INSTRUKTORZY
            </Button>
            <Button color="inherit" to="/settings" component={RouterLink}>
              USTAWIENIA
            </Button>
          </>
          ):(<></>)}
        </>
      ):(<></>)}
      <Button component={RouterLink} to="/wyloguj" color="inherit" onClick={logout}>
        WYLOGUJ
      </Button>
    </div>
  </div>
)};

export default AppBarCollapse;
