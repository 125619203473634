import React from "react";
import SnackAlert from "./SnackAlert";

const Popups = () => {
    
    return (
        <>
            <SnackAlert/>
        </>
    );
}

export default Popups;
