import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SketchPicker } from 'react-color';
import {
    Button,
    TextField,
    InputAdornment,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Box,
    IconButton,
    InputLabel,
    Tooltip
} from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { makeStyles } from "@mui/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme) => ({
    img: {
        height: 260,
        width: 210,
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    PhotoLabel: {
        marginTop:'15px',
    },
    colorPickerForMobile:{
        margin:'auto'
    },
    photoPreviewMobile:{
        textAlign:'center',
    }
}));



const DialogEditTeacher = ({
    open,
    onChange,
    onClose,
    onSubmit,
    title,
    contentText,
    editedTeacher
}) => {

    const classes = useStyles();
    const theme = useTheme();
    const matchesMobile = useMediaQuery(theme.breakpoints.down('md'));

    const {
        first_name,
        last_name,
        email,
        phoneNumber,
        color,
        photoPath,
    } = editedTeacher;

    const [password, setPassword] = useState('');
    const [generatedPassword, setGeneratedPassword] = useState('');

    const handleChange = (e) => {
        onChange({
            [e.target.name]: e.target.value
        });

    }
    const handleColorChange = ({ hex }) => {
        onChange({
            color: hex
        })
    };
    const handlePhotoChange = (e) => {
        const uploadedPhoto = e.target.files[0]
        if (uploadedPhoto) {
            onChange({
                photoPath: uploadedPhoto,
            });
        }
    };
    const getPhotoUploadPreview = () => {
        if (photoPath) {
            if (editedTeacher.isEdited() && editedTeacher.hasPhotoPath()) {
                return photoPath;
            }
            return URL.createObjectURL(photoPath)
        }
        return '';
    }
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }
    const handleGeneratePassword = async () => {
        let length = 12,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-=+/|,.<>?;:'",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        setGeneratedPassword(retVal)
    }
    const resetState = () => {
        setPassword('')
        setGeneratedPassword('')
    }
    const handleClose = () => {
        onClose();
        resetState()
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const RegData = editedTeacher.getTeacherFormData(password);
        const teacherFormData = editedTeacher
        onSubmit(RegData, teacherFormData);
        handleClose()
    };
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {contentText}
                    </DialogContentText>
                    <form
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    name="first_name"
                                    value={first_name}
                                    margin="dense"
                                    id="user-firstName"
                                    label="Imię"
                                    type="text"
                                    variant="standard"
                                    required
                                    fullWidth
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="last_name"
                                    value={last_name}
                                    margin="dense"
                                    id="user-lastName"
                                    label="Nazwisko"
                                    type="text"
                                    variant="standard"
                                    required
                                    fullWidth
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="email"
                                    value={email}
                                    margin="dense"
                                    id="user-email"
                                    label="Email"
                                    type="email"
                                    variant="standard"
                                    required
                                    fullWidth
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    margin="dense"
                                    id="user-phone"
                                    label="Telefon"
                                    type="number"
                                    variant="standard"
                                    required
                                    fullWidth
                                    onChange={handleChange}
                                    onInput = {(e) =>{
                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,9)
                                      }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="password"
                                    value={password}
                                    margin="dense"
                                    id="user-password"
                                    label="Hasło"
                                    type="password"
                                    variant="standard"
                                    required={!editedTeacher.isEdited()}
                                    fullWidth
                                    onChange={handlePasswordChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={generatedPassword}
                                    margin="dense"
                                    id="generated-password"
                                    label=" "
                                    type="text"
                                    variant="standard"
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CopyToClipboard text={generatedPassword}>
                                                    <IconButton edge="end">
                                                        <FileCopyOutlinedIcon fontSize="small" />
                                                    </IconButton>
                                                </CopyToClipboard>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={6} className={classes.buttonWrapper}>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleGeneratePassword}
                                >
                                    Generuj hasło
                                </Button>
                            </Grid>
                        </Grid>
                        {matchesMobile ? (
                                <>
                                <Box mb={1}>
                                    <InputLabel>
                                    
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}>
                                            Kolor
                                            <Tooltip title={'Pamiętaj aby nie wybierać jaksrawych kolorów, ponieważ wartości w kalendarzą mogą stać się nieczytelne.'}>
                                                <InfoIcon color="warning" style={{paddingLeft:'10px'}} />
                                            </Tooltip>
                                        </div>
                                    
                                    </InputLabel>
                                </Box>
                                <SketchPicker
                                    className={classes.colorPickerForMobile}
                                    color={color}
                                    onChangeComplete={handleColorChange}
                                />
                                <Box mb={1} className={classes.PhotoLabel}>
                                    <InputLabel>Zdjęcie</InputLabel>
                                </Box>
                                {editedTeacher.photoPath && (
                                    <Box className={classes.photoPreviewMobile}>
                                        <img
                                            src={getPhotoUploadPreview()}
                                            alt="Teacher"
                                        />
                                    </Box>
                                )}
                                <label htmlFor="add-photo-btn">
                                    <input
                                        id="add-photo-btn"
                                        type="file"
                                        name="photoPath"
                                        accept="image/*"
                                        style={{ display: 'none'}}
                                        onChange={handlePhotoChange}
                                    />
                                    <Box></Box>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        component="span"
                                    >
                                        Dodaj
                                    </Button>
                                </label>
                                </>
                            ) : (
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Box mb={1}>
                                                <InputLabel>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexWrap: 'wrap',
                                                }}>
                                                    Kolor
                                                    <Tooltip title={'Pamiętaj aby nie wybierać jaksrawych kolorów, ponieważ wartości w kalendarzą mogą stać się nieczytelne.'}>
                                                        <InfoIcon color="warning" style={{paddingLeft:'10px'}} />
                                                    </Tooltip>
                                                </div>
                                                </InputLabel>
                                                    
                                            </Box>
                                            <SketchPicker
                                                disableAlpha={true}
                                                color={color}
                                                onChangeComplete={handleColorChange}
                                            />

                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box mb={1}>
                                                <InputLabel>Zdjęcie</InputLabel>
                                            </Box>
                                            {editedTeacher.photoPath && (
                                                <img
                                                    className={classes.img}
                                                    src={getPhotoUploadPreview()}
                                                    alt="Teacher"
                                                />
                                            )}
                                            <label htmlFor="add-photo-btn">
                                                <input
                                                    id="add-photo-btn"
                                                    type="file"
                                                    name="photoPath"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={handlePhotoChange}
                                                />
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    component="span"
                                                >
                                                    Dodaj
                                                </Button>
                                            </label>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        
                        <Box mt={2}>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Anuluj
                                </Button>
                                <Button color="primary" type="submit">
                                    Zapisz
                                </Button>
                            </DialogActions>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}

DialogEditTeacher.defaultProps = {
    title: 'Utwórz nowego użytkownika',
    contentText: 'Uzupełnij dane, aby utworzyć nowego użytkownika',
};

export default DialogEditTeacher