import moment from 'moment';

class Settings {
  constructor(data = {}) {
    const {
      id, startDate, endDate, isActive,
      maxLessonsPerWeek, maxLessons, defaultRate
    } = data;

    this.id = id  || '';
    this.startDate = startDate ? moment(startDate).startOf('day') : null;
    this.endDate = endDate ? moment(endDate).endOf('day') : null;
    this.isActive = isActive !== undefined ? isActive : false;
    this.maxLessonsPerWeek = maxLessonsPerWeek || 0;
    this.maxLessons = maxLessons || 0;
    this.defaultRate = defaultRate || 0;
  }

  isEdited() {
    return !!this.id;
  }

  getEditData() {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      isActive: this.isActive,
      maxLessonsPerWeek: this.maxLessonsPerWeek,
      maxLessons: this.maxLessons,
      defaultRate: this.defaultRate
    };
  }

  isDateActive(selectedDate) {
    const date = moment(selectedDate);
    if (this.startDate && this.endDate) {
      return date.isBetween(this.startDate, this.endDate);
    }
    if (this.startDate) {
      return date.isAfter(this.startDate);
    }
    if (this.endDate) {
      return date.isBefore(this.endDate);
    }
    return true;
  }
}

export default Settings;