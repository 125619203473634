import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    MenuItem,
    Select,
    Dialog,
    DialogTitle,
    IconButton,
    TextField,
} from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';
import DirectionsIcon from '@mui/icons-material/Directions';
import { Grid } from "@mui/material";
import moment from "moment";
import EditRoadIcon from '@mui/icons-material/EditRoad';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Tooltip } from '@mui/material';
import { getPermission } from '../../auth/ApiAuth';

const useStyles = makeStyles((theme) => ({
    LessonsList: {
        position: "absolute",
        top: "22%",
        left: "10%",
        width: "300px",
        height: "50px",
        zIndex: "1000",
        background: "rgb(255, 255, 255, 0.9)",
    },

    Button: {
        height: "50px",
    },
    TitleRoad: {
        paddingTop: '7px',
        width: '50%',
    },
    Icon: {
        width: '50%',
        textAlign: 'right'
    }

}));

const DialogRouteList = ({
    routes,
    onChange,
    onClick,
    handleShowSaveRoutes,
    isAdmin,
    onDelete,
    title,
}) => {

    const [manageRoad, setManageRoad] = useState(false)
    const [filteredRoads, setFilteredRoads] = useState(routes);
    const [availableRoutes, setAvailableRoutes] = useState(routes)

    useEffect(() =>{
        let filteredRoutes = routes.filter((route)=>{
            if (route['isArchived']) return false
            else return true
        })
        setAvailableRoutes(filteredRoutes)
        setFilteredRoads(filteredRoutes)
    },[routes])

    function filterRoads(value) {
        if (value === '') {
            setFilteredRoads(routes)
            return
        }
        let result = availableRoutes.filter(elem => {
            let valueLowerCase = value.toLowerCase()

            if (elem.title.toLowerCase().includes(valueLowerCase)) return true
            else return false
        })

        setFilteredRoads(result)
    }
  
    const openManageRoad = () =>{
        setManageRoad(!manageRoad)
    }


    const classes = useStyles();
    return (
        <Box>
            <Dialog
                open={true}
                fullWidth={true}
                onClose={handleShowSaveRoutes}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className={classes.Title}>
                    <div style={{
                        float:'left',
                        width:'80%',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <DirectionsIcon fontSize="large" /> {title}
                    </div>
                    <div style={{
                        float:'left',
                        align:'right',
                        textAlign:'right',
                        width:'20%',
                    }}>
                        <Tooltip title="Zarządzanie trasami" placement="left">
                            {getPermission('admin') ?
                                <IconButton size="small" onClick={openManageRoad}>
                                    <EditRoadIcon fontSize="large" color="warning" />
                                </IconButton>
                                : <></>
                            }
                        </Tooltip>
                    </div>
                </DialogTitle>
                {isAdmin ? (
                    <Select
                        variant="filled"
                        labelId="RouteInput"
                        label="Route"
                        fullWidth
                        onChange={onChange}
                    >
                        {availableRoutes.map((route) =>
                            <MenuItem value={route.pk} key={route.pk}>
                                <Grid container>
                                    <Grid item className={classes.TitleRoad}>
                                        {route.title}
                                    </Grid>

                                    <Grid item className={classes.Icon}>

                                        <IconButton
                                            onClick={() => onDelete(route.pk)}
                                            style={{ borderRadius: "50%" }}
                                        >
                                            <DeleteIcon color="error" />
                                        </IconButton>

                                    </Grid>
                                </Grid>
                            </MenuItem>
                        )}
                    </Select>
                ) : (
                    <Select
                        variant="filled"
                        labelId="RouteInput"
                        label="Route"
                        fullWidth
                        onChange={onChange}
                    >
                        {availableRoutes.filter((r)=>{if(new Date() - new Date(r.end)<0) return r; else return false}).filter((d) => d.driver !== null).map((route) =>
                            <MenuItem value={route} key={route.id}>
                                <Grid container>
                                    <Grid item className={classes.TitleRoad}>
                                        Kursant: {route.driverFullName}, Lekcja numer:  {route.driverLessons}, Start: {moment(new Date(route.start)).format('MM-DD HH:mm')}-{moment(new Date(route.end)).format('HH:mm')}
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        )}
                    </Select>
                )}
                {isAdmin ? (
                    <Button className={classes.Button} onClick={onClick}>
                        Stwórz nową trasę
                    </Button>) : (<></>)
                }
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={manageRoad}
            >
                <DialogTitle>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <EditRoadIcon fontSize="large"  style={{marginRight:'6px'}}/>
                        Zarządzanie zapisanymi trasami
                    </div>
                    <div style={{width:'100%', height:'1px', borderBottom:'1px solid black'}}></div>
                    <TextField
                        margin="dense"
                        id="name"
                        label="Wyszukaj trasę"
                        variant="outlined"
                        type="search"
                        onChange={(e) => filterRoads(e.target.value)}
                        style={{width:'100%'}}
                    ></TextField>
                </DialogTitle>
                <DialogContent>
                    {filteredRoads.map((route) =>
                        <MenuItem value={route.pk} key={'M'+route.pk}>
                            <Grid container>
                                <Grid item className={classes.TitleRoad}>
                                    {route.title}
                                </Grid>
                                <Grid item className={classes.Icon}>
                                    <Tooltip title="Usuń trasę" placement="left">
                                        <IconButton
                                            onClick={() => onDelete(route.pk)}
                                            style={{ borderRadius: "50%" }}
                                        >
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    )}
                </DialogContent>
                <DialogActions>
                <Button onClick={openManageRoad} color="primary">Zamknij</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default DialogRouteList