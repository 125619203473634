import {
    DialogTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
} from '@mui/material';

export const DeleteStartPointDialog = (props) => {
    return (
        <>
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        Czy na pewno chcesz usunąć dany punkt?
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogActions>
                        <Button onClick={props.onClose} color="primary">
                            Anuluj
                        </Button>
                        <Button onClick={props.onConfirm} color="error" autoFocus>
                            Usuń
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )

}

export default DeleteStartPointDialog