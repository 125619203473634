import { useState } from "react";
import {
    TextField,
    DialogTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Box,
    Typography
} from '@mui/material';


export const EditStartPointDialog = (props) => {


    const [name, setName] = useState(props.data.description)
    const [error, setError] = useState(false)

    const handleSubmit = () => {
        if (!error && name.length !== 0) {
            props.data.description = name
            props.onConfirm(props.data)
            setName('')
        }
        else setError(true)
    }
    const handleChangeText = (value) => {
        if (value.length === 0) {
            setError(true)
        } else {
            setName(value)
            setError(false)
        }
    }

    return (
        <>
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        Edycja punktu początkowego
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{mt: 1}}>
                        <TextField
                            fullWidth
                            label="Nazwa punktu"
                            type="text"
                            error={error}
                            defaultValue={props.data.description}
                            onChange={(e) => handleChangeText(e.target.value)}
                        />
                    </Box>
                    <Typography sx={{fontSize: 14, mt: 2}}>
                        Jeżeli lokalizacja punktu uległa zmianie ta informacja również zostanie zaktualizowana!
                    </Typography>
                    <DialogActions>
                        <Button onClick={props.onClose} color="error">
                            Anuluj
                        </Button>
                        <Button onClick={handleSubmit} color="primary" autoFocus>
                            Zapisz
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )

}

export default EditStartPointDialog