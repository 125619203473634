
import React from "react";
import { makeStyles } from '@mui/styles';
import { Backdrop, SpeedDial, SpeedDialIcon, SpeedDialAction, Box } from "@mui/material"
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import WarningIcon from '@mui/icons-material/Warning';
import ExploreIcon from '@mui/icons-material/Explore';

const useStyles = makeStyles(theme => ({
    speedDial: {
        position: "absolute",
        bottom: "5%",
        right: "4.4%",
    }
}));

const SpeedDialogMap = ({
    open,
    onMarkerClick,
    onErrorClick,
    handleShowPointList,
    onRouteClick,
    handleShowSaveRoutes,
    isClosed,
    isAddingPointLocked,
    isMistakesLocked,
    isChangeRouteLocked,
    isPointListLocked,
    isSavingRouteLocked
}) => {
    const actions = [
        { icon: <AddLocationAltIcon
            color={!isAddingPointLocked ? 'primary' : 'gray'} />,
            name: "Dodaj punkt",
            action: !isAddingPointLocked ? handleAddLocationClick : ()=>{}
        },
        { icon: <AddRoadIcon
            color={!isSavingRouteLocked ? 'primary' : 'gray'} />,
            name: "Zapisz trase",
            action: !isSavingRouteLocked ? handleAddRouteClick : ()=>{}
        },
        { icon: <WarningIcon
            style={{ color: isMistakesLocked && !isClosed ? 'red' : 'gray' }} />,
            name: "Dodaj błąd",
            action: isMistakesLocked && !isClosed ? handleAddErrorClick : ()=>{}
        },
        { icon: <FormatListNumberedIcon
            color={!isPointListLocked ? "primary" : "gray"} />,
            name: "Lista puntków",
            action: !isPointListLocked ? handleShowPointList : ()=>{}
        },
        { icon: <ExploreIcon
            color={!isChangeRouteLocked ? "primary" : "gray"}/>,
            name: "Trasy",
            action: !isChangeRouteLocked ? handleShowSaveRoutes : ()=>{}
        }
    ];

    function handleAddLocationClick() {
        onMarkerClick()
    }
    function handleAddErrorClick() {
        onErrorClick()
    }
    function handleAddRouteClick() {
        onRouteClick()
    }

    const classes = useStyles();

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Backdrop open={open} />
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                className={classes.speedDial}
                icon={<SpeedDialIcon />}
                direction="up"
            >
                {actions.map((action) =>
                    <SpeedDialAction
                        style={{ marginBottom: "10px", }}
                        icon={action.icon}
                        key={action.name}
                        tooltipTitle={action.name}
                        onClick={action.action}
                        tooltipOpen={true}
                        istouch="true"
                    />
                )}
            </SpeedDial>
        </Box>
    )
}

export default SpeedDialogMap