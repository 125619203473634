import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from "../images/drive_pro_logo.png";
import { apiAuth, getUserData, getToken } from '../auth/ApiAuth';
import { useHistory } from 'react-router-dom';
import useKeypress from 'react-use-keypress';
import Footer from "../components/Footer";


export function Login() {
  //react router nav
  let history = useHistory();

  //sates for label from form
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberLogin, setRememberLogin] = useState(false);

  //checkbox check value
  function changeRememberLogin() {
    if (rememberLogin === true) {
      setRememberLogin(false);
    } else {
      setRememberLogin(true);
    }
  }

  //If error
  useEffect(() => {

    if(getToken() != null){
      history.push("/calendar");
    }
  }, [history]);

  //If press enter login 
  useKeypress('Enter', () => {
    verify(email, password, rememberLogin);
  });

  //login
  async function verify(email, password, rememberLogin) {
    await apiAuth(email, password, rememberLogin);
    if (getToken() !== null || getToken() !== undefined) {
      await getUserData(getToken(), email);
      history.push("/calendar");
    }
  }

  return (
    <Box>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <AppBar
          style={{ background: '#3F51B5' }}
        >
          <Toolbar>
            <img
              src={logo}
              alt="drive-pro-logo"
              style={{
                height: '40px',
              }}
            />
          </Toolbar>
        </AppBar>

        

        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#F50057' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Logowanie
          </Typography>

          <Box component="form">
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Hasło"
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
            />

            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Zapamiętaj mnie"
              checked={rememberLogin}
              onChange={() => changeRememberLogin()}
            />

            <Button
              id="buttonLogin"
              style={{ background: '#3F51B5' }}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => verify(email, password, rememberLogin)}
            >
              Zaloguj
            </Button>

            <Typography
              align="center"
              style={{
                color:'rgb(63, 81, 181)',
                cursor: 'pointer',
              }}
              onClick={() => history.push("/ResetPass")}
              >
              Nie pamiętasz hasła?
            </Typography>

          </Box>
        </Box>
      </Container>
      <Footer/>
    </Box>
  );
}

export default Login;
