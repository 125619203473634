import L from "leaflet";
import { pointTypes } from "../../redux/const/config";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine";

const createRoutineMachineLayer = (
    { onLoad, waypoints, focusOnRoute }
) => {

    Array.move = function (x, y) {
        this.splice(y, 0, this.splice(x, 1)[0]);
        return this;
    };

    function compare(a, b) {
        if (a.value < b.value) {
            return -1;
        }
        if (a.value > b.value) {
            return 1;
        }
        return 0;
    }
    var wayPoints = waypoints.filter((w) => w.pointType !== pointTypes.mistake).sort(compare)
    var instance = L.Routing.control({
        waypoints: wayPoints,
        lineOptions: {
            styles: [{ color: "red", weight: 3 }]
        },
        show: false,
        collapsible: false,
        addWaypoints: false,
        routeWhileDragging: true,
        draggableWaypoints: true,
        fitSelectedRoutes: focusOnRoute,
        showAlternatives: false,
        createMarker: function () { return null; },
        router: new L.Routing.OSRMv1({
            // serviceUrl: `${process.env.REACT_APP_CUSTOM_OSRM_URL}`
        })


    }).on('routesfound', function (e) {
        var waypoint = e.waypoints.filter((w) => w.pointType !== pointTypes.mistake).map((w) => new L.Routing.Waypoint(w.latLng));
        const osm = L.Routing.osrmv1()
        try {
            osm.route(waypoint, function (err, routes) {
                if (routes !== undefined) {
                    var dist = (routes[0].summary.totalDistance);
                    var time = (routes[0].summary.totalTime);
                    onLoad(dist, time)
                }
            });
        }
        catch (er) {
        }
    });
    return instance;
};

const RoutingMachine = createControlComponent(createRoutineMachineLayer)

export default RoutingMachine;
