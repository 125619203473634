import { USER_EDITABLE_FIELDS } from '../redux/const/config';
import User from './User';

class Teacher extends User {
    constructor(data = {}) {
        super(data);

        const {
            color, photoPath, phoneNumber,
        } = data;

        this.color = color || '';
        this.photoPath = photoPath || '';
        this.phoneNumber = phoneNumber || '';
    }

    hasPhotoPath() {
        return typeof this.photoPath === 'string';
    }

    getTeacherFormData(password = '') {
        const formData = new FormData();
        Object.keys(this).forEach((key) => {
            if (USER_EDITABLE_FIELDS.includes(key)) {

                if (key === 'photoPath' && this.hasPhotoPath()) {
                    return;
                }
                formData.append(key, this[key]);


            }
        });
        if (password) {
            formData.append('password', password);
        }
        return formData;
    }
}

export default Teacher;
