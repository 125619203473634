import React from 'react';
import { makeStyles } from '@mui/styles';
import { Alert, Box, Fade } from '@mui/material';

const useStyles = makeStyles({
  alert: {
    height: 50,
    left: '50%',
    marginTop: '10px',
    position: 'fixed',
    top: 140,
    transform: 'translateX(-50%)',
    width: 400,
    zIndex: 2000,
  },
  alertDanger: {
    backgroundColor: '#dc3545',
  },
  alertSuccess: {
    backgroundColor: '#28a745',
  },
  text: {
    color: '#FFFFFF',
  },
});


function MapAlertCard(props) {
  const classes = useStyles();
  return (
    <Box className={classes.alert}>
      <Fade
        timeout={500}
        in={true}
      >
        <Alert variant="filled" severity={props.type}>
          {props.value}
        </Alert>
      </Fade>
    </Box>
  );
}

export default MapAlertCard;
