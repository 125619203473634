import { TEACHERS } from "../const/actionTypes";
import Teacher from "../../model/Teacher"

const initialState = {
    TeacherList: [],
    TeacherLoading: false,
    TeacherLoaded: false,
    editedTeacher: new Teacher()
};
const teacherReducer = (state = initialState, action) => {
    switch (action.type) {
        case TEACHERS.SET_TEACHERS:
            const TeacherList = action.item.map((teacher) => new Teacher(teacher))
            return {
                ...state,
                TeacherLoading: false,
                TeacherLoaded: true,
                TeacherList
            };
        case TEACHERS.SET_EDITED_TEACHER:
            return {
                ...state,
                editedTeacher: new Teacher(action.editedTeacher)
            };
        case TEACHERS.EDIT_TEACHER:
            return {
                ...state,
                editedTeacher: new Teacher({
                    ...state.editedTeacher,
                    ...action.editedTeacherData
                }),
            };
        case TEACHERS.RESET_EDITED_TEACHER:
            return {
                ...state,
                editedTeacher: new Teacher()
            };
        case TEACHERS.CLEAR_TEACHERS:
            return initialState;
        default:
            return state;
    }

};

export default teacherReducer;
