import {
    Container,
    IconButton,
    Box
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    useMapEvents
} from "react-leaflet"

import L from "leaflet"

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { useState } from "react";


const PointMapBox = ({
    Map,
    onMapLoad,
    points,
    onAddMarkerClick,
    onStartPointClick,
    onEdit,
    onStartPointDeleteClick
}) => {
    const [markerClick, setMarkerClick] = useState(false)
    var style_id = "ckvqikieg4wke15mguuhcu3mj"

    const cursor = ('url(' + icon + ')13 41,move');
    const useStyles = makeStyles((theme) => ({
        Popup: {
            alignItems: "center",
        },
        PopupContent: {
            margin: "10px 15px",
            maxWidth: "200px"
        },
        MapContainer: {
            cursor: onAddMarkerClick ? cursor : 'pointer',
        },
    }));

    const {
        center,
        zoom,
    } = Map

    const DefaultIcon = new L.icon({
        iconUrl: icon,
        iconShadow: iconShadow,
        iconAnchor: [14, -5],

    })

    const classes = useStyles();

    function AddMarker() {
        // eslint-disable-next-line
        const map = useMapEvents({
            click: (e) => {
                onStartPointClick(e.latlng)
            },
        })
        return (null)
    }
    function MouseEnterMap(e) {
        // eslint-disable-next-line
        const map = useMapEvents({
            mouseover: (e) => {
                e.target._container.style.cursor = onAddMarkerClick ? cursor : 'pointer'
            },
            mouseout: (e) => {
                e.target._container.style.cursor = ''
            }
        })


        return (null)
    }

    return (
        <Container maxWidth="100%" >
            <MapContainer id='mapCont' center={center} zoom={zoom} whenCreated={onMapLoad} style={{ width: '100%', height: '75vh' }}>
                <MouseEnterMap />
                <TileLayer
                    attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                    url={`https://api.mapbox.com/styles/v1/${process.env.REACT_APP_MAPBOX_USERNAME}/${style_id}/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_ACCES_TOKEN}`}
                />

                {onAddMarkerClick ? (
                    <AddMarker />
                ) : null}

                {points.map((marker) =>
                    <Marker
                        position={marker}
                        draggable={true}
                        icon={DefaultIcon}
                        key={marker.pk}
                        eventHandlers={{
                            dragend: (e) => {
                                let data = marker;
                                data.lat = e.target._latlng.lat
                                data.lng = e.target._latlng.lng
                                onEdit(data)
                            },
                            click: () => {
                                setMarkerClick(true)
                            }
                        }}
                    >
                        {markerClick ?
                            <Popup className={classes.Popup}>
                                <Box className={classes.PopupContent} >
                                    {marker.description}<br />
                                    <Box style={{display: 'table', margin: '0px auto'}}>
                                        <IconButton onClick={()=>onEdit(marker)}>
                                            <EditIcon color="primary"/>
                                        </IconButton>
                                        <IconButton onClick={()=>onStartPointDeleteClick(marker.pk)}>
                                            <DeleteIcon color="error"/>
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Popup>
                            :
                            null
                        }
                    </Marker>
                )}
            </MapContainer>
        </Container>
    )
}
export default PointMapBox