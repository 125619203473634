class Marker {
    constructor(data = {}) {
        const {
            pk,
            visiblePk,
            date,
            draggable,
            description,
            lat,
            lng,
            pointType,
            value,
            routeOnly,
            accuracy,
        } = data;

        this.pk = pk || '';
        this.visiblePk = visiblePk || '';
        this.date = date || '';
        this.draggable = draggable || false;
        this.description = description || '';
        this.lat = lat || '';
        this.lng = lng || '';
        this.pointType = pointType || '';
        this.value = value || 0;
        this.routeOnly = routeOnly;
        this.accuracy = accuracy || 0;
    }
}

export default Marker;