import { useState } from "react";
import {
    TextField,
    DialogTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl
} from '@mui/material';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';


export const ErrorDialogText = ({
    open,
    onConfirm,
    onClose,
}) => {

    const Values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const MistakesList = [
        {id: 0, name: "Wymuszenie pierwszeństwa", value: 2},
        {id: 1, name: "STOP", value: 3},
        {id: 2, name: "Kolizja", value: 10},
        {id: 3, name: "Inny", value: 0},
    ]

    const [desc, setDesc] = useState(MistakesList[0]['name'])
    const [value, setValue] = useState(MistakesList[0]['value'])
    const [isDisabled, setIsDisabled] = useState(true)

    const handleSubmit = () => {
        onConfirm(desc, value)

        setDesc("")
        setValue(10)
        setIsDisabled(true)
    }

    const handleChangeMistake = (mistakeId) => {
        let mistake = MistakesList.filter((mistake)=>{
            if (mistake['id'] === parseInt(mistakeId)) {
                return mistake
            }
            else return false
        })[0]

        if (mistake.name === 'Inny' && mistake.value === 0) setIsDisabled(false)
        else {
            setIsDisabled(true)
            setDesc(mistake.name)
            setValue(mistake.value)
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <ReportGmailerrorredIcon color="error" fontSize="large" sx={{mr: 1}}/>
                        Wybierz błąd
                    </div>
                </DialogTitle>
                <DialogContent>
                        <FormControl fullWidth>
                            <TextField
                                select
                                margin="dense"
                                label="Błędy predefiniowane"
                                onChange={(e) => handleChangeMistake(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                {MistakesList.map((elem)=>{
                                    return <option value={elem.id} key={elem.id}>{elem.name}</option>
                                })}
                            </TextField>
                            <TextField
                                label="Opis błędu"
                                type="text"
                                margin="dense"
                                disabled={isDisabled}
                                onChange={(e) => setDesc(e.target.value)}
                            />
                            <TextField
                                select
                                label="Waga błędu"
                                margin="dense"
                                disabled={isDisabled}
                                onChange={(e) => setValue(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                {Values.map((val) =>
                                    {
                                        return <option value={val} key={val}>{val}</option>
                                    }
                                )}
                            </TextField>
                        </FormControl>
                    <DialogActions>
                        <Button onClick={onClose} color="error">
                            Anuluj
                        </Button>

                        <Button onClick={handleSubmit} color="primary" autoFocus>
                            Tak
                        </Button>
                    </DialogActions>
                </DialogContent>

            </Dialog>
        </>
    )

}

export default ErrorDialogText