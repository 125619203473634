import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet'
import { IconButton } from '@mui/material';
import { Box, Checkbox, Container } from "@mui/material"
import DeleteIcon from "@material-ui/icons/Delete";
import L from "leaflet"
import "leaflet-routing-machine";
import 'leaflet/dist/leaflet.css';
import { makeStyles } from '@mui/styles';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import mistakeMarker from '../../images/mistakeMarker.png'
import startMarker from '../../images/startMarker.png'
import endMarker from '../../images/endMarker.png'
import LocationMarker from './FindUserLocation'
import RoutingMachine from "./RoutingMachine";
import { pointTypes } from "../../redux/const/config";

const MapBox = ({
    Map,
    onWaypointClick,
    Reload,
    onLoad,
    onDelete,
    onMarkerDragEnd,
    onMapDragEnd,
    onAddMarkerClick,
    onMapLoad,
    markerType,
    Route,
    routeFocus,
    isArchiveOpen,
}) => {

    const {
        center,
        zoom,
        userCurrentLocation,
    } = Map

    const {
        routes
    } = Route

    
    const DefaultIcon = new L.icon({
        iconUrl: icon,
        iconShadow: iconShadow,
        iconAnchor: [13, 41],
        popupAnchor: [2, -30],
    }),
    mistakeIcon = new L.icon({
        iconUrl: mistakeMarker,
        iconShadow: iconShadow,
        iconAnchor: [13, 51],
        iconSize: [50, 50],
        popupAnchor: [13, -12],
    }),
    startIcon = new L.icon({
        iconUrl: startMarker,
        iconShadow: iconShadow,
        iconAnchor: [21, 45],
        iconSize: [45, 45],
        popupAnchor: [3, -30],
    }),
    endIcon = new L.icon({
        iconUrl: endMarker,
        iconShadow: iconShadow,
        iconAnchor: [21, 45],
        iconSize: [45, 45],
        popupAnchor: [3, -30],
    })

    function AddMarker() {
        // eslint-disable-next-line
        const map = useMapEvents({
            click: (e) => {
                onWaypointClick(e.latlng, markerType, Route)
            },
        })
        return (null)
    }
    function DragComponent() {
        // eslint-disable-next-line
        const map = useMapEvents({
            dragend: () => {
                onMapDragEnd()

            }
        })
        return (null)
    }

    const startCursor = ('url(' + startMarker + ')13 41,move');

    function MouseEnterMap(e) {
        // eslint-disable-next-line
        const map = useMapEvents({
            mouseover: (e) => {
                if(markerType === pointTypes.startWaypoint){
                e.target._container.style.cursor = onAddMarkerClick ? startCursor : ''
            }else{
                e.target._container.style.cursor = onAddMarkerClick ? cursor : ''
            }
                
            },
            mouseout: (e) => {
                e.target._container.style.cursor = ''

            }
        })

        return (null)
    }


    const handleInfoLoad = (dist, time) => {
        onLoad(dist, time)
    };

    // ckvqikieg4wke15mguuhcu3mj  //routes
    // ckvql7bep7cpe15qt6te7bnh6  //satelite
    var style_id = "ckvqikieg4wke15mguuhcu3mj"
    const [markerClick, setMarkerClick] = useState(false)

    const cursor = ('url(' + icon + ')13 41,move');

    const useStyles = makeStyles((theme) => ({

        Popup: {
            alignItems: "center",
            padding: "5px",
            width: "60px",
            MaxHeight: "100px",
        },
        MapContainer: {
            cursor: onAddMarkerClick ? cursor : 'pointer',
        }

    }));

    const classes = useStyles();
    return (
        <Container maxWidth="100%" >
            <MapContainer id='mapCont' className={classes.MapContainer} center={center} zoom={zoom} whenCreated={onMapLoad} style={{ width: '100%', height: '75vh' }}>
                <TileLayer
                    attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                    url={`https://api.mapbox.com/styles/v1/${process.env.REACT_APP_MAPBOX_USERNAME}/${style_id}/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_ACCES_TOKEN}`}

                />
                {onAddMarkerClick ? (
                    <AddMarker

                    />
                ) : (
                    <></>
                )}
                <MouseEnterMap />
                <DragComponent />
                {Reload ? (
                    routes.map((marker) =>
                        <Marker
                            position={marker}
                            icon={
                                (() => {
                                    if (marker.pointType === pointTypes.waypoint) {
                                        return DefaultIcon
                                    }
                                    else if(marker.pointType === pointTypes.startWaypoint){
                                        return startIcon;
                                    }
                                    else if(marker.pointType === pointTypes.endWaypoint){
                                        return endIcon;
                                    }
                                    else if (marker.pointType === pointTypes.mistake) {
                                        return mistakeIcon
                                    }
                                })()
                            }

                            draggable={isArchiveOpen ? false : marker.routeOnly ? true: false }
                            key={marker.pk}
                            eventHandlers={{
                                dragend: (e) => {
                                    onMarkerDragEnd(e.target._latlng, marker)
                                    if (marker.pointType === pointTypes.waypoint || marker.pointType === pointTypes.endWaypoint || marker.pointType === pointTypes.startWaypoint) {
                                        setMarkerClick(false)
                                    }
                                },
                                click: (e) => {
                                    setMarkerClick(true)
                                }
                            }}
                        >
                            {markerClick ? (<Popup className={classes.Popup}>
                                {marker.pointType === pointTypes.mistake ? marker.description : marker.value}

                                {marker.pointType === pointTypes.mistake || marker.pointType === pointTypes.waypoint & isArchiveOpen!==true ? (
                                    <IconButton onClick={() => {
                                        if (marker.pointType !== pointTypes.mistake) {
                                            onDelete(marker.pk)
                                            setMarkerClick(false)
                                        }
                                    }}>
                                        {marker.pointType !== pointTypes.mistake ? (<DeleteIcon color="error" />) : (<></>)}
                                    </IconButton>
                                ) : (
                                    <></>
                                )}
                            </Popup>) :
                                (<></>)}

                        </Marker>
                    ))
                    :
                    (<></>)
                }


                <LocationMarker
                    position={userCurrentLocation}
                />

                {Reload ? (
                    <RoutingMachine
                        waypoints={routes}
                        onLoad={handleInfoLoad}
                        focusOnRoute={routeFocus}
                    />
                ) : (
                    <></>
                )}
            </MapContainer>
            <Box style={{ display: 'none' }}>
                Znacznik
                <Checkbox checked={onAddMarkerClick} />
            </Box>
            <Box style={{ display: 'none' }}>
                Route Locked
                <Checkbox checked={routeFocus} />
            </Box>
        </Container>
    )
}
export default MapBox