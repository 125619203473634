import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TextField,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Box,
  IconButton,
  Typography,
  Tooltip
} from '@mui/material/';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import InfoIcon from '@mui/icons-material/Info';

const DialogEditDriver = ({
  open,
  onChange,
  onClose,
  onSubmit,
  title,
  contentText,
  editedDriver,
}) => {
  const {
    first_name,
    last_name,
    email,
    phoneNumber,
    isActive,
  } = editedDriver;

  const [password, setPassword] = useState('');
  const [generatedPassword, setGeneratedPassword] = useState('');

  const handleChange = (e) => {
    onChange({
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeActive = () => {
    onChange({
      isActive: !isActive,
    });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const resetState = () => {
    setPassword('');
    setGeneratedPassword('');
  };

  const handleClose = () => {
    onClose();
    resetState();
  };

  const handleGeneratePassword = () => {
    let length = 12,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-=+/|,.<>?;:'",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    setGeneratedPassword(retVal)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const RegData = editedDriver.getUserEditData(password);
    const driverData = editedDriver;
    onSubmit(RegData, driverData);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {contentText}
          </DialogContentText>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="first_name"
                  value={first_name}
                  margin="dense"
                  id="user-firstName"
                  label="Imię"
                  type="text"
                  required
                  fullWidth
                  onChange={handleChange}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="last_name"
                  value={last_name}
                  margin="dense"
                  id="user-lastName"
                  label="Nazwisko"
                  type="text"
                  required
                  fullWidth
                  onChange={handleChange}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="email"
                  value={email}
                  margin="dense"
                  id="user-email"
                  label="Email"
                  type="email"
                  required
                  fullWidth
                  onChange={handleChange}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="phoneNumber"
                  value={phoneNumber}
                  margin="dense"
                  id="user-phone"
                  label="Telefon"
                  type="number"
                  fullWidth
                  onChange={handleChange}
                  variant="standard"
                  onInput = {(e) =>{
                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,9)
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="password"
                  value={password}
                  margin="dense"
                  id="user-password"
                  label="Hasło"
                  type="password"
                  required={!editedDriver.isEdited()}
                  fullWidth
                  onChange={handlePasswordChange}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={generatedPassword}
                  margin="dense"
                  id="generated-password"
                  label=" "
                  type="text"
                  fullWidth
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <CopyToClipboard text={generatedPassword}>
                          <IconButton edge="end">
                            <FileCopyOutlinedIcon fontSize="small" />
                          </IconButton>
                        </CopyToClipboard>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handleGeneratePassword}
                >
                  Generuj hasło
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography
                    variant='subtitle2'
                  > 
                    Jeżeli pole 'aktywny' jest niezaznaczone,
                    użytkownik zostanie przeniesiony do archiwum.
                      <Tooltip title="Archiwum znajduje się w prawej górnej części listy kursantów" placement='left'>
                        <InfoIcon style={{float:'right'}} color='warning'/>
                      </Tooltip>
                  </Typography>
                </Box>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                      name="isActive"
                      checked={isActive}
                      onChange={handleChangeActive}
                    />
                  )}
                  label="Aktywny"
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Anuluj
                </Button>
                <Button color="primary" type="submit">
                  Zapisz
                </Button>
              </DialogActions>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );

}

DialogEditDriver.defaultProps = {
  title: 'Utwórz nowego użytkownika',
  contentText: 'Uzupełnij dane, aby utworzyć nowego użytkownika',
};

export default DialogEditDriver