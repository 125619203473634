import { useEffect, useState } from 'react';
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Box,
    Typography,
} from '@mui/material/';
import { pointTypes } from '../../redux/const/config';

const LessonStatistics = (props) => {
    const [mistakes, setMistakes] = useState([])

    function timeConv(value) {
        var sec_num = parseInt(value, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    }

    function mapMistakes(){
        if (mistakes.length>0)
            return mistakes.map((elem)=><Box>- {elem.value} pkt - {elem.description ? elem.description : 'brak opisu'}</Box>)
        else
            return <Box>Brak błędów!</Box>
    }

    function filterPoints(){
        let mistakes = props.mistakes.filter((point)=>{
            if (point.pointType === pointTypes.mistake) return true
            else return false
        })
        setMistakes(mistakes)
    }

    useEffect(()=>{
        filterPoints();
        // eslint-disable-next-line
    },[])

    return (
        <Dialog open={true} fullWidth={true}>
            <DialogTitle id="alert-dialog-title" style={{borderBottom:'1px solid #737373', margin:'10px'}}>
                Podsumowanie lekcji:
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{mb: 2, mt: 1}}>
                    <Grid item xs={4}>
                        <Box>

                            Czas przejazdu:
                            <Typography variant='h5'>
                                {timeConv(props.lesson.drivingTime)}
                            </Typography>

                            Przejechana odległość:
                            <Typography variant='h5'>
                                {parseFloat(props.lesson.distanceTraveled/1000).toFixed(2)} km
                            </Typography>

                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Box>
                            <Box sx={{mb: 1}}>Lista błędów:</Box>
                            {mapMistakes()}
                        </Box>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button color="error" onClick={props.onClose}>Zamknij</Button>
                </DialogActions>
            </DialogContent>

        </Dialog>
    )
}

export default LessonStatistics