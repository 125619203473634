import { useState } from "react";
import {
    TextField,
    DialogTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    formInput:{
        margin: '20px 0px 20px 0px'
    }
}))

export const StartPointDialog = (props) => {
    const classes = useStyles();

    const [name, setName] = useState('')
    const [error, setError] = useState(false)

    const handleSubmit = () => {
        if (!error && name.length !== 0) {
            props.onConfirm(name)
            setName('')
        }
        else setError(true)
    }
    const handleChangeText = (value) => {
        if (value.length === 0) {
            setError(true)
        } else {
            setName(value)
            setError(false)
        }
    }

    return (
        <>
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        Dodaj punkt odbioru
                    </div>
                </DialogTitle>
                <DialogContent>
                        <FormControl fullWidth>
                            <TextField
                                label="Nazwa punktu"
                                type="text"
                                className={classes.formInput}
                                error={error}
                                onChange={(e) => handleChangeText(e.target.value)}
                            />
                        </FormControl>
                    <DialogActions>
                        <Button onClick={props.onClose} color="error">
                            Anuluj
                        </Button>
                        <Button onClick={handleSubmit} color="primary" autoFocus>
                            Zapisz
                        </Button>
                    </DialogActions>
                </DialogContent>

            </Dialog>
        </>
    )

}

export default StartPointDialog