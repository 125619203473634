import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { Button } from '@mui/material';
import { Tooltip } from '@mui/material';
import Zoom from '@mui/material/Zoom';

const CurrentLocationButton = ({
    findLocation,
    onClick,
}) => {

    return (
        <Button onClick={onClick} >
            <Tooltip title="Pokaż obecną lokalizację" placement='left' TransitionComponent={Zoom} arrow>
            {findLocation ? (
                <GpsFixedIcon >
                </GpsFixedIcon>
            ) : (
                <GpsNotFixedIcon >
                </GpsNotFixedIcon>
            )}
            </Tooltip>
        </Button>
    )
}

export default CurrentLocationButton