import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, LayerGroup, Circle } from 'react-leaflet'
import L from "leaflet"

import { Container, IconButton } from "@mui/material"
import { makeStyles } from '@mui/styles';
import DeleteIcon from "@material-ui/icons/Delete";

import LocationMarker from './FindUserLocation'

import RoutingMachine from "./RoutingMachine";

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import mistakeMarker from '../../images/mistakeMarker.png'
import startMarker from '../../images/startMarker.png'
import endMarker from '../../images/endMarker.png'
import { pointTypes } from "../../redux/const/config";

const InstructorMapBox = ({
    Map,
    onWaypointClick,
    Reload,
    onLoad,
    onDelete,
    onMarkerDragEnd,
    onMapDragEnd,
    onAddMarkerClick,
    onMapLoad,
    markerType,
    Route,
    routeFocus,
    isArchiveOpen,
}) => {


    const useStyles = makeStyles((theme) => ({

        Popup: {
            alignItems: "center",
            padding: "5px",
            width: "60px",
            maxHeight: "100px",
            whiteSpace: 'pre-wrap', 
            overflowWrap: 'break-word'
        },
        MapContainer: {
            cursor: onAddMarkerClick ? cursor : 'pointer',
        },
        startButtonNav: {
            position: "relative",
            zIndex: "1000",
            bottom: "40px",
            left: "5%"
        }

    }));

    const classes = useStyles();

    const {
        center,
        zoom,
        userCurrentLocation,
    } = Map

    const {
        routes
    } = Route

    const DefaultIcon = new L.icon({
        iconUrl: icon,
        iconShadow: iconShadow,
        iconAnchor: [13, 41],
        popupAnchor: [0, -30],
    }),
        mistakeIcon = new L.icon({
            iconUrl: mistakeMarker,
            iconShadow: iconShadow,
            iconAnchor: [13, 51],
            iconSize: [50, 50],
            popupAnchor: [13, -35],
        }),
        startIcon = new L.icon({
            iconUrl: startMarker,
            iconShadow: iconShadow,
            iconAnchor: [21, 45],
            iconSize: [45, 45],
            popupAnchor: [3, -35],
        }),
        endIcon = new L.icon({
            iconUrl: endMarker,
            iconShadow: iconShadow,
            iconAnchor: [21, 45],
            iconSize: [45, 45],
            popupAnchor: [3, -35],
        })

    var style_id = "ckvqikieg4wke15mguuhcu3mj"

    function AddMarker() {
        // eslint-disable-next-line
        const map = useMapEvents({
            click: (e) => {
                onWaypointClick(e.latlng, markerType, Route)
            },
        })
        return (null)
    }

    function DragComponent() {
        // eslint-disable-next-line
        const map = useMapEvents({
            dragend: () => {
                onMapDragEnd()

            }
        })
        return (null)
    }

    function MouseEnterMap(e) {
        // eslint-disable-next-line
        const map = useMapEvents({
            mouseover: (e) => {
                if (markerType === pointTypes.waypoint) {
                    e.target._container.style.cursor = onAddMarkerClick ? cursor : ''
                }
                else {
                    e.target._container.style.cursor = onAddMarkerClick ? error : ''
                }
            },
            mouseout: (e) => {
                e.target._container.style.cursor = ''
            }
        })


        return (null)
    }
    const handleInfoLoad = (dist, time) => {
        onLoad(dist, time)
    };
    const mapLoad = (e) => {
        onMapLoad(e)
    }

    const [markerClick, setMarkerClick] = useState(false)
    const cursor = ('url(' + icon + ')13 41,move');
    const error = ('url(' + mistakeMarker + ')13 51,move');
    return (
        <Container maxWidth="100%" >
            <MapContainer id='mapCont' whenCreated={mapLoad} center={center} zoom={zoom} style={{ width: '100%', height: '75vh' }}>
                <TileLayer

                    attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                    url={`https://api.mapbox.com/styles/v1/${process.env.REACT_APP_MAPBOX_USERNAME}/${style_id}/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_ACCES_TOKEN}`}

                />
                {onAddMarkerClick ? (
                    <AddMarker

                    />
                ) : (
                    <></>
                )}
                <DragComponent />
                <MouseEnterMap />
                <LocationMarker
                    position={userCurrentLocation}
                />
                {Reload ? (
                    routes.map((marker) =>
                        <Marker
                            position={marker}
                            icon={
                                (() => {
                                    if (marker.pointType === pointTypes.waypoint) {
                                        return DefaultIcon
                                    }
                                    else if (marker.pointType === pointTypes.startWaypoint) {
                                        return startIcon;
                                    }
                                    else if (marker.pointType === pointTypes.endWaypoint) {
                                        return endIcon;
                                    }
                                    else if (marker.pointType === pointTypes.mistake) {
                                        return mistakeIcon
                                    }
                                })()
                            }

                            draggable={isArchiveOpen ? false : marker.routeOnly ? (marker.navStart? false : true) : false }
                            key={marker.pk}
                            eventHandlers={{
                                dragend: (e) => {
                                    onMarkerDragEnd(e.target._latlng, marker)
                                    if (marker.pointType === pointTypes.waypoint || marker.pointType === pointTypes.endWaypoint || marker.pointType === pointTypes.startWaypoint) {
                                        setMarkerClick(false)
                                    }
                                },
                                click: (e) => {
                                    setMarkerClick(true)
                                }
                            }}
                        >
                            <LayerGroup>
                                <Circle
                                    center={marker}
                                    stroke={true}
                                    radius={50}

                                />
                            </LayerGroup>
                            {markerClick ? (<Popup className={classes.Popup}>
                                {marker.pointType === pointTypes.mistake ? marker.description : marker.pointType === pointTypes.startWaypoint ? marker.description : marker.value}
                                {marker.pointType === pointTypes.mistake || marker.pointType === pointTypes.waypoint & isArchiveOpen!==true ? (
                                    < IconButton onClick={() => {
                                        if (marker.pointType !== pointTypes.mistake) {
                                        }
                                        onDelete(marker.pk)
                                        setMarkerClick(false)
                                    }}>
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                ) : (
                                    <></>
                                )}
                            </Popup>) :
                                (<></>)}

                        </Marker>
                    )
                ) : (<></>)}
                {Reload ? (
                    <RoutingMachine
                        focusOnRoute={routeFocus}
                        waypoints={routes}
                        onLoad={handleInfoLoad}
                    />
                ) : (
                    <></>
                )}

            </MapContainer>
        </Container>
    )
}
export default InstructorMapBox