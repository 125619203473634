import { combineReducers } from 'redux';
import driverReducer from './driver';
import lessonReducer from './lessons';
import teacherReducer from './teacher';
import calendarReducer from './calendar'
import settingsReducer from './settings';
import mapReducer from './map';
import navigationReducer from './navigation';

const reducers = combineReducers({
    driver: driverReducer,
    teacher: teacherReducer,
    lesson: lessonReducer,
    calendar: calendarReducer,
    settings: settingsReducer,
    map: mapReducer,
    navigation: navigationReducer,
})

export default reducers;