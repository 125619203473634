import $ from 'jquery';
import eventEmitter from '../event/EventEmitter'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/rest-auth/login/`

export async function callAjax(baseurl, callback = function(){}){
  let results;
  return $.ajax({
    url: baseurl,
    type: "GET",
    "headers": {
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods':'GET',
        'Access-Control-Allow-Headers':'application/json',
        'Authorization': 'Token ' + getToken(),
    },
    success: function(result){
      results = result;
    }
  }).done(function(){
    if (typeof callback != 'function') callback = false;
    if (callback){
      callback(results);
    }
  })
};

export async function postAjax(baseurl, data, callback = function(){}){
  let results;
  return $.ajax({
    url: baseurl,
    type: "POST",
    data: data,
    "headers": {
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods':'POST',
        'Access-Control-Allow-Headers':'application/json',
        'Authorization': 'Token ' + getToken(),
    },
    success: function(result){
      results = result;
      eventEmitter.emit('popupAlert', ['Operacja przebiegła pomyślnie!', 'success'])
    },
    error: function(error){
      eventEmitter.emit('popupAlert', ['Błąd operacji spróbuj ponownie!', 'error'])
    }
  }).done(function(xhr, textStatus){
    if (typeof callback != 'function') callback = false;
    if (callback){
      callback(results);
    }
  })
};

export async function deleteAjax(baseurl, callback = function(){}){
  let results;
  return $.ajax({
    url: baseurl,
    type: "DELETE",
    "headers": {
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods':'DELETE',
        'Access-Control-Allow-Headers':'application/json',
        'Authorization': 'Token ' + getToken(),
    },
    success: function(result){
      results = result;
      eventEmitter.emit('popupAlert', ['Operacja przebiegła pomyślnie!', 'success'])
    },
    error: function(error){
      eventEmitter.emit('popupAlert', ['Błąd operacji spróbuj ponownie!', 'error'])
    }
  }).done(function(xhr, textStatus){
    if (typeof callback != 'function') callback = false;
    if (callback){
      callback(results);
    }
  })
};

export async function putAjax(baseurl, data, processData = true, callback = function(){}){
  let results;
  return $.ajax({
    url: baseurl,
    type: "PUT",
    processData: processData,
    contentType: processData ? 'application/x-www-form-urlencoded; charset=UTF-8' : false,
    data: data,
    "headers": {
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods':'PUT',
        'Access-Control-Allow-Headers':'application/json',
        'Authorization': 'Token ' + getToken(),
    },
    success: function(result){
      results = result;
      eventEmitter.emit('popupAlert', ['Operacja przebiegła pomyślnie!', 'success'])
    },
    error: function(error){
      eventEmitter.emit('popupAlert', ['Błąd operacji spróbuj ponownie później!', 'error'])
    }
  }).done(function(xhr, textStatus){
    if (typeof callback != 'function') callback = false;
    if (callback){
      callback(results);
    }
  })
};

export async function patchAjax(baseurl, data, processData = true, callback = function(){}){
  let results;
  return $.ajax({
    url: baseurl,
    type: "PATCH",
    processData: processData,
    contentType: processData ? 'application/x-www-form-urlencoded; charset=UTF-8' : false,
    data: data,
    "headers": {
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods':'PATCH',
        'Access-Control-Allow-Headers':'application/json',
        'Authorization': 'Token ' + getToken(),
    },
    success: function(result){
      results = result;
      eventEmitter.emit('popupAlert', ['Operacja przebiegła pomyślnie!', 'success'])
    },
    error: function(error){
      eventEmitter.emit('popupAlert', ['Błąd operacji spróbuj ponownie!', 'error'])
    }
  }).done(function(xhr, textStatus){
    if (typeof callback != 'function') callback = false;
    if (callback){
      callback(results);
    }
  })
};

export async function apiAuth(email, password, rememberLogin) {
    await $.ajax({
      url: baseUrl,
      type: "POST",
      contentType: 'application/json',
      data: JSON.stringify({ "email": email, "password": password }),
      "headers": {
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Headers':'application/json',
      },
      success: function(result){
        if(rememberLogin === true){
          localStorage.setItem('token', result.key);
        }else{
          sessionStorage.setItem('token', result.key);
        }
        // eslint-disable-next-line
        if(result.isSystemActive == false && result.accountType == "student"){
          eventEmitter.emit('popupAlert', ['Niepoprawne dane logowania bądź twoje konto jest nieaktywne!', 'error'])
        }else{
          eventEmitter.emit('popupAlert', ['Zalogowano pomyślnie!', 'success'])
        }
      },
      error: function(error){
        eventEmitter.emit('popupAlert', ['Błędny login lub hasło!', 'error'])
      }
  })
}

export async function resetPassword(email){
  await $.ajax({
    url: `${process.env.REACT_APP_BASE_URL}/password-reset/`,
    type: 'POST',
    contentType: 'application/json',
    data: JSON.stringify({"email": email}),
    "headers":{
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Headers':'application/json',
    },
    success: function(result){
      eventEmitter.emit('popupAlert', ['Link reserujący został wysłany na podany adres!', 'success'])
    },
    error: function(error){
      eventEmitter.emit('popupAlert', ['Niepoprawny adres email, bądź konto o podanym adresie nie istnieje.', 'error'])
    }
  })
}

export async function changePassword(uid, token, password, passwordConfirm){
  await $.ajax({
    url: `${process.env.REACT_APP_BASE_URL}/ResetPassConfirm/${uid}/${token}/`,
    type: 'POST',
    contentType: 'application/json',
    data: JSON.stringify({
      "uid": uid,
      "token": token,
      "new_password1": password,
      "new_password2": passwordConfirm
    }),
    "headres":{
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Headers':'application/json',
    },
    success: function (result){
      eventEmitter.emit('popupAlert', [result.detail, 'success'])
    },
    error: function(error){
      eventEmitter.emit('popupAlert', ['Błąd zmiany hasła!', 'error'])
    }
  })
}

export async function getUserData(token, email) {
  const userDataUrl = `${process.env.REACT_APP_BASE_URL}/api/user/?email=`+email.toLowerCase();
  await $.ajax({
    url: userDataUrl,
    type: "GET",
    contentType: 'application/json',
    "headers": {
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Headers':'application/json',
      Authorization: "Token " + token,
    },
    success: function(result){
      localStorage.setItem('permission', btoa(result[0].accountType))
      localStorage.setItem('userName', result[0].username);
      localStorage.setItem('userPK', result[0].pk);
      localStorage.setItem('imagePath', result[0].photoPath);
      localStorage.setItem('email',result[0].email)
      localStorage.setItem('activeFilter', 'null')
    },
    error: function(error){
      console.log("Error get account data");
    }
  })
}


export function checkToken(token){
    if((token !== null) && (token !== undefined) && (token.toString().length === 40 )){
        return false;
    }else{
        return true;
    }
}

export function getPermission(type){
  if(type === atob(localStorage.getItem('permission'))){
    return  true
  } else{
    return false
  }
}

export function getToken(){
    if (localStorage.getItem('token') != null){
      return localStorage.getItem('token')
    }else if(sessionStorage.getItem('token') != null){
      return sessionStorage.getItem('token')
    }else{
      return null;
    }
}


export function logout(){
  eventEmitter.emit('popupAlert', ['Wylogowano pomyślnie', 'success'])
  localStorage.removeItem('token');
  sessionStorage.removeItem('token');
  localStorage.removeItem('userName');
  localStorage.clear();
  window.location.href = '../';
}