export const USER_EDITABLE_FIELDS = [
    'first_name',
    'last_name',
    'email',
    'password',
    'isActive',
    'username',
    'accountType',
    'pk',
    'color',
    'photoPath',
    'phoneNumber',
];

export const LOCATION_MAX_LENGTH = 20;

export const DATE_QUERY_FORMAT = 'YYYY-MM-DD';

export const pointTypes ={ 
    startWaypoint   : "startWaypoint",
    waypoint        : "waypoint",
    endWaypoint     : "endWaypoint",
    mistake         : "mistake",
}