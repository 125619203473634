import { CALENDAR } from "../const/actionTypes";

const initialState = {
    isOpen: false,
    startDate: null,
    endDate: null
};
const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case CALENDAR.SET_CALENDAR_DIALOG:
            return {
                ...state,
                isOpen: action.isOpen
            };
        case CALENDAR.SET_CALENDAR_DATES:
            return {
                ...state,
                startDate: action.startDate,
                endDate: action.endDate,
            };
        default:
            return state;
    }
};

export default calendarReducer;
