import React, { useState } from "react";
import { connect } from 'react-redux';
import { useEffect } from "react";
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import Navbar from "../components/Navbar";
import { Box, Button } from "@mui/material";
import {
    addRemovedPk,
    editRoute,
    changeMarkerOrder,
    getAllRoutes,
    setEditedRoute,
    resetEditedRoute,
    createRoute,
    updateRoute,
    setUserCurrentLocation,
    deletePoint,
    deleteRoute,
    setRouteViibility,
    getAllTeacherPoints,
    updateTeacherPoint,
    setNavigationRoute,
    resetNavigationRoute,
    setTeacherCurrentLesson,
    resetTeacherCurrentLesson,
    deleteNavigationMarker,
    setIsAdminValue,
    getAllPoints,
} from "../redux/actions/map";
import getAllTeachers from "../redux/actions/teacher"
import { pointTypes } from "../redux/const/config";
import { setPrevLocation, resetPrevLocation, setDistance, resetDistance, setSpeed, resetSpeed } from "../redux/actions/navigation"
import { getLessons, getAllLessons, clearLessons } from '../redux/actions/lessons'
import L from "leaflet"
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import { deleteMarker } from "../redux/actions/map";
import { setDisTime, dragMarker } from "../redux/actions/map";
import { getPermission, checkToken, getToken, postAjax, deleteAjax, putAjax } from "../auth/ApiAuth"
import { setTime, resetTime } from "../redux/actions/navigation"
import moment from "moment";

import { Typography } from '@mui/material';
import { Tooltip } from '@mui/material';
import Zoom from '@mui/material/Zoom';

import MapFixedSettings from "../components/MapComponents/MapFixedSettings";
import MapBox from "../components/MapComponents/MapBox";
import SpeedDialogMap from "../components/MapComponents/SpeedDialogMap";
import ErrorDialogText from "../components/MapComponents/ErrorDialogText";
import WaypointsList from "../components/MapComponents/DraggableList/WaypointList";
import DialogLessonsRoute from "../components/MapComponents/DialogRoutesList";
import AddRouteDialog from "../components/MapComponents/AddRouteDialog";
import SaveRouteDialog from "../components/MapComponents/SaveRouteDialog";
import CurrentLocationButton from "../components/MapComponents/CurrentLocationButton";
import InstructorMapBox from "../components/MapComponents/InstructorMapBox";
import ArchiveDialog from "../components/MapComponents/ArchiveDialog";
import NavigationSummary from "../components/MapComponents/NavigationSummary"
import LessonStatistics from "../components/MapComponents/DialogLessonStats"
import MapAlertCard from "../components/MapAlertCard";
import PointMapBox from "../components/MapComponents/PointMapBox"
import EditStartPointDialog from "../components/MapComponents/EditStartPointDialog"
import DeleteStartPointDialog from "../components/MapComponents/DeleteStartPointDialog"
import Route from "../model/Route"

import { makeStyles } from '@mui/styles';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

import NavigationIcon from '@mui/icons-material/Navigation';
import CloseIcon from '@mui/icons-material/Close';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import EditLocationOutlinedIcon from '@mui/icons-material/EditLocationOutlined';
import PublicIcon from '@mui/icons-material/Public';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LiveMapBox from "../components/MapComponents/LiveMapBox";
import Point from "../model/Point"
import Footer from "../components/Footer";
import StartPointDialog from "../components/MapComponents/StartPointDialog";
import eventEmitter from "../event/EventEmitter";

const useStyles = makeStyles((theme) => ({
    showRoad: {
        marginTop: '5px',
    },
    lockRoad: {
        marginTop: '5px',
    },
    mapStyle: {
        marginTop: '5px',
    },
    miniIconBox: {
        zIndex: 1000,
        position: "absolute",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-between",
        top: "2.5%",
        right: '1.5%',
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        borderRadius: '3px',
        backdropFilter: "blur(1px)",
        paddingBottom: 5,
    },
    startNavBtn: {
        position: 'absolute!important',
        zIndex: 1000,
        bottom: '40px',
        left: '1.3%',
    },
    pauseNavBtn: {
        position: 'absolute!important',
        zIndex: 1000,
        bottom: '100px',
        left: '1.3%',
    },
    iconNav: {
        backgroundColor: '#fff!important',
        border: "1px solid #969696!important",
        borderRadius: '4px',
    }

}))



const Map = ({
    mapState,
    editedRoute,
    Routes,
    reloadMap,
    lessonsRoutes,
    allLessons,
    teachersPoints,
    currentLesson,
    navigationRoute,
    navigation,
    dispatchSetTime,
    dispatchResetTime,
    startPoints,
    teachersList
}) => {
    const classes = useStyles();

    const accountTypes = {
        admin   : 'admin',
        teacher : 'teacher',
        student : 'student'
    }

    let history = useHistory();
    const dispatch = useDispatch();
    if (checkToken(getToken()) || getPermission(accountTypes.student)) {
        history.push("/Login");
    }

    const reorder = (list, startIndex, endIndex) => {
        list.sort(compare)
        var tmp = list[startIndex].value;
        list[startIndex].value = list[endIndex].value;
        list[endIndex].value = tmp
        return list;
    };

    function compare(a, b) {
        if (a.value < b.value) {
            return -1;
        }
        if (a.value > b.value) {
            return 1;
        }
        return 0;
    }


    const handleListDragEnd = (result) => {
        if (!result.destination) return;
        if (result.destination.index === 0 || result.destination.index === editedRoute.routes.length - 1) return;
        if (result.source.droppableId === result.destination.droppableId) {
            dispatch(setRouteViibility(false))
            dispatch(changeMarkerOrder(
                editedRoute,
                reorder(editedRoute.routes, result.source.index, result.destination.index),
                getPermission(accountTypes.admin)))
            setTimeout(function () {
                dispatch(setRouteViibility(true))
            }, 100);
        }
        else {

        }
    }


    const handleAddWaypointMarkerClick = (coords, type, Route) => {

        dispatch(setRouteViibility(false))
        coords.pointType = type
        if (type === pointTypes.mistake) {
            coords.pk = "tempErrPk" + mapState.count
            coords.routeOnly = true
            setErrorDialogOpen(true)
            seteLatLng(coords)
        }
        else {
            coords.pk = "tempPk" + mapState.count
            if (Route) {
                dispatch(editRoute(Route, coords, mapState.count, getPermission(accountTypes.admin)))

                if (Route.pk === "tempPk" && Route.routes.length === 1) {
                    setAddMaarkerClick(true)
                }
            }
            setAddMaarkerClick(false)
            document.getElementById('mapCont').style.cursor = ''
        }
        setTimeout(function () {
            dispatch(setRouteViibility(true))
            setAlertboxTextValue("Masz niezapisane zmiany w edytowanej trasie!")
        }, 100);
    }

    const handleAddStartpointMarkerClick = (latlng) => {
        seteLatLng(latlng)
        document.getElementById('mapCont').style.cursor = 'pointer'
        setIsAddStartpointDialogOpen(true)
    }

    const handleDragEnd = (dragedData, marker) => {
        dispatch(setRouteViibility(false))
        if (startNav) dispatch(dragMarker(navigationRoute, dragedData, getPermission(accountTypes.admin), startNav,marker))
        else dispatch(dragMarker(editedRoute, dragedData, getPermission(accountTypes.admin), startNav,marker))
        setTimeout(function () {
            dispatch(setRouteViibility(true))
            setAlertboxTextValue("Masz niezapisane zmiany w edytowanej trasie!")
        }, 300);
            
    }

    const handleInfoLoad = (Distance, Time) => {
        dispatch(setDisTime(Distance, Time))
    }

    const handleMarkerRemove = (index) => {
        dispatch(setRouteViibility(false))
        if (String(index).includes("temp")) {
            if (startNav) {
                dispatch(deleteNavigationMarker(index))
            } else {
                dispatch(deleteMarker(index))
                dispatch(setIsAdminValue(editedRoute, getPermission(accountTypes.admin)))
            }
        }
        else {
            if (startNav) {
                dispatch(deleteNavigationMarker(index))
            } else {
                dispatch(deleteMarker(index))
                dispatch(addRemovedPk(index))
                dispatch(setIsAdminValue(editedRoute, getPermission(accountTypes.admin)))
            }


        }
        setTimeout(function () {
            dispatch(setRouteViibility(true))
        }, 100);
        if (mapState.routeSavingNeeded === true) {
            setAlertboxTextValue("Masz niezapisane zmiany w edytowanej trasie!")
            setAlertBox(true)
        }
    }

    const handleSpeedDialogAddClick = () => {
        if (startPointsMapBoxOpen) setAddMaarkerClick(true)
        else if (editedRoute.pk !== "") {
            setAddMaarkerClick(true)
            setMarkerType(pointTypes.waypoint)
        }
    }

    const handleSpeedDialogErrorClick = () => {
        if (editedRoute.pk !== "") {
            if (accountType !== accountTypes.admin) {
                handleAddWaypointMarkerClick(mapState.userCurrentLocation,pointTypes.mistake,navigationRoute)
            }
        }

    }

    const handleSpeedDialogRouteClick = () => {
        if (editedRoute.pk !== "") setSaveRouteDialogOpen(true)

    }
    const handleSaveRouteDialogClose = () => {
        setSaveRouteDialogOpen(false)
    }

    const handleSaveRouteDialogConfirm = () => {
        dispatch(setRouteViibility(false))
        if (editedRoute.pk === "tmpPK") {
            if (editedRoute.routes.length < 2) {
                eventEmitter.emit('popupAlert',[' Musisz dodać punkt końcowy przed zapisaniem trasy ! ','error']);
            }
            else {
                dispatch(createRoute(editedRoute,null,mapState.routeTime,mapState.routeDistance))
            }
        }
        else {
            let removedPkList = mapState.removedPk
            if (removedPkList.length !== 0) {
                removedPkList.map((pk) => dispatch(deletePoint(pk)))
            }
            dispatch(updateRoute(editedRoute,mapState.routeDistance,mapState.routeTime,defaultStartWaypoint))
        }
        setSaveRouteDialogOpen(false)
        setTimeout(function () {
            dispatch(setRouteViibility(true))
        }, 200);
    }

    const handleErrorDialogConfirm = (description, valuee) => {
        const p = new Point(eLatLng)
        p.description = description
        p.value = valuee
        if (startNav) setTimeout(() => { dispatch(editRoute(navigationRoute, p, mapState.count, getPermission(accountTypes.admin), startNav)) }, 100)
        else setTimeout(() => { dispatch(editRoute(editedRoute, p, mapState.count, getPermission(accountTypes.admin), startNav)) }, 100)
        setErrorDialogOpen(false)
        setAddMaarkerClick(false)
    }

    const handleLessonsChange = (e) => {
        var tmp = e.target.value
        let tmpRoute = new Route(Routes.filter((w) => w.pk === tmp.route)[0])
        if (accountType !== null) {
            if (accountType === accountTypes.teacher) {
                dispatch(resetEditedRoute())
                dispatch(resetTeacherCurrentLesson())
                if(tmp.startPoint!==null){
                    setDefaultStartWaypoint(new Point(tmpRoute.routes.filter((p)=> p.pointType===pointTypes.startWaypoint)[0]))
                    let tmpStartPoint = startPoints.filter((p)=> p.pk === tmp.startPoint)[0]
                    tmpRoute.routes.filter((p)=> p.pointType===pointTypes.startWaypoint).map((p) => {
                    p.lat = tmpStartPoint.lat
                    p.lng = tmpStartPoint.lng
                    p.description = tmpStartPoint.description
                    p.routeOnly= tmpStartPoint.routeOnly
                    return p
                })
                }
                if(Routes !== undefined)dispatch(setEditedRoute(tmpRoute))
                dispatch(setTeacherCurrentLesson(tmp))
                setShowSaveRoutes(false)
                dispatch(setRouteViibility(true))
            }
            else {
                dispatch(resetEditedRoute())
                if(Routes !== undefined)dispatch(setEditedRoute(Routes.filter((w) => w.pk === tmp)[0]))
                setShowSaveRoutes(false)
                dispatch(setRouteViibility(true))
            }
            setIsArchiveShowingRoute(false)
            setIsNavStartDisabled(false)
            setIsNavPauseDisabled(false)
            setIsAddingPointLocked(false)
            setIsSavingRouteLocked(false)
            setIsPointListLocked(false)
            setArchiveOpen(false)
        }

    }


    const handleErrorDialogClose = () => {
        setErrorDialogOpen(false)
    }
    const handleArchiveDialogClose = () => {
        setShowArchive(false)
    }
    const handleLessonStatisticsClose = () => {
        setShowStatistics(false)
    }
    const handleNavigationSummaryClose = () => {
        setIsNavSummaryOpen(false)
    }

    const handleShowPointList = () => {
        if (showPointList === true) {
            setShowPointList(false)
        }
        else setShowPointList(true)
    }

    const handleShowSaveRoutes = () => {
        if (showSaveRoutes === true) {
            setShowSaveRoutes(false)
            dispatch(setRouteViibility(true))
        }
        else {
            setShowSaveRoutes(true)
            dispatch(setRouteViibility(false))
        }
    }

    const handleAddNewRouteClick = (e) => {
        setShowSaveRoutes(false)
        setAddRouteDialogOpen(true)
    }

    const handleAddNewRouteClose = (e) => {
        setShowSaveRoutes(true)
        setAddRouteDialogOpen(false)
    }

    const handleFindLocationButtonClick = () => {
        setFindLocationButton(true)
        mapCont.flyTo(mapState.userCurrentLocation, 16)


    }

    const handleUserLocationData = (latlng) => {
        dispatch(setUserCurrentLocation(latlng))
    }

    const handleMapLoad = (map) => {
        L.control.scale().addTo(map)
        setMapContainer(map)
    }

    const handleRouteDeleteClick = (deletePK) => {
        dispatch(deleteRoute(deletePK))
    }

    const handleAddNewRouteConfirm = (desc, title) => {
        var tmpRoute = new Route()
        tmpRoute.pk = "tmpPK"
        tmpRoute.title = title
        tmpRoute.desc = desc
        dispatch(resetEditedRoute())
        dispatch(setEditedRoute(tmpRoute))
        setMarkerType(pointTypes.waypoint)
        setAddRouteDialogOpen(false)
        setAddMaarkerClick(true)
        setIsAddingPointLocked(false)
        setIsSavingRouteLocked(false)
        setIsPointListLocked(false)
        setArchiveOpen(false)
    }

    const handleMapDragEnd = () => {
        setFindLocationButton(false)
    }

    const handleCallRouteReload = () => {
        dispatch(setRouteViibility(false))
        setTimeout(function () {
            dispatch(setRouteViibility(true))
        }, 100);
    }

    const handleIsRouteVisable = () => {
        if (reloadMap) {
            dispatch(setRouteViibility(false))
            setViewIcon(false)
        }
        else {
            dispatch(setRouteViibility(true))
            setViewIcon(true)
        }
    }
    const handleRouteFocusClick = () => {
        if (routeFocus) {
            setRouteFocus(false)
            setLockIcon(false)
        }
        else {
            setRouteFocus(true)
            setLockIcon(true)

        }
    }
    const handleArchiveLessonChange = (routeID, lessonID) => {

        dispatch(resetEditedRoute())
        dispatch(setEditedRoute(Routes.filter((w) => w.pk === routeID)[0]))
        handleCallRouteReload()
        setShowArchive(false)
        setIsArchiveShowingRoute(true)
        setIsAddingPointLocked(true)
        setIsPointListLocked(true)
        setIsSavingRouteLocked(true)
        setIsNavStartDisabled(true)
        setIsNavPauseDisabled(true)
        setCurrentArchiveLesson(lessonID)
        setArchiveOpen(true)
    }

    const handleNavigationStartButton = () => {
        if (startNav === false) {
            if (editedRoute.routes.length !== 0) {
                if(editedRoute.isArchived!==true){
                    let now = moment(new Date()).format('YYYY-MM-DD HH:mm')
                    let start = moment(currentLesson.start).format('YYYY-MM-DD HH:mm')
                    let end = moment(currentLesson.end).format('YYYY-MM-DD HH:mm')
                  if (moment(now).isBetween(start, end)) {
                    setIsNavigationPaused(false)
                    setStartNav(true)
                    setRouteFocus(false)
                    setIsAddingPointLocked(true)
                    setIsChangeRouteLocked(true)
                    setLockIcon(false)
                    mapCont.flyTo(mapState.userCurrentLocation, 16)
                    setStartedCoords(new Point(mapState.userCurrentLocation));
                    }else{
                        eventEmitter.emit('popupAlert',[' Włączenie nawigacji możliwe tylko w czasie trwania lekcji ! ','warning']);
                    }
                }
            }
            else {
                eventEmitter.emit('popupAlert',[' Najpierw wybierz lekcję ! ','warning']);
            }
        }
        else {
            setIsNavigationPaused(true)
            setIsNavSummaryOpen(true)
        }
    }

    const handleFinishNavigationConfirm = (e,mistakes) => {
        let tmpRoute = new Route(editedRoute)
        tmpRoute.routes = editedRoute.routes.map((p) => new Point(p));
        mistakes.forEach(element => {
           tmpRoute.routes = tmpRoute.routes.concat(element)
        });
       tmpRoute.routes.filter((p) => p.value === 0).map((p) => {
        p.lat = startedCoords.lat
        p.lng = startedCoords.lng
        p.description = startedCoords.description
        return p
        })
        dispatch(createRoute(tmpRoute,currentLesson.id,e.navStats.time,e.navStats.distance))  
        dispatch(clearLessons())
        dispatch(resetEditedRoute())
        setTimeout(()=>{
            endNav(tmpRoute)
        },100)
        localStorage.removeItem('navigationState');
    }

    const handleNavigationSummaryExit = ()=>{
        endNav(editedRoute)

    }
    
    const endNav =(route)=>{
        dispatch(getAllLessons())
            dispatch(resetNavigationRoute())
            dispatch(resetPrevLocation())
            dispatch(resetDistance())
            dispatch(resetSpeed()) 
            handleCallRouteReload()
            setStartNav(false)
            setRouteFocus(true)
            setLockIcon(true)
            setIsChangeRouteLocked(false)
            setIsNavSummaryOpen(false)
            dispatchResetTime()
            dispatch(setEditedRoute(route))
    }

    const handleNavigationPauseButton = () => {

        setIsNavigationPaused(isNavigationPaused => !isNavigationPaused)

        //TODO: logika pauzy nawigacji

    }

    const handleChangeMapStyle = () => {
        if (changeStyleMap === true) {
            setStyleMap("ckvqikieg4wke15mguuhcu3mj")
            setChangeStyleMap(false)
        }
        else {
            setStyleMap("ckvql7bep7cpe15qt6te7bnh6")
            setChangeStyleMap(true)
        }
        L.tileLayer(`https://api.mapbox.com/styles/v1/${process.env.REACT_APP_MAPBOX_USERNAME}/${styleMap}/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_ACCES_TOKEN}`).addTo(mapCont)
    }

    const handleSwitchViewClick = (type) => {
        switch (type){
            case 'live':
                if (startPointsMapBoxOpen) setStartPointsMapBoxOpen(false)
                if (liveMapBoxOpen) {
                    setLiveMapBoxOpen(false)
                    if (isRouteSelected) {
                        setIsAddingPointLocked(false)
                        setIsPointListLocked(false)
                        setIsSavingRouteLocked(false)
                    }
                    else {
                        setIsAddingPointLocked(true)
                        setIsPointListLocked(true)
                        setIsSavingRouteLocked(true)
                    }
                    setIsChangeRouteLocked(false)
                }
                else {
                    dispatch(getAllTeachers())
                    setLiveMapBoxOpen(true)
                    setIsAddingPointLocked(true)
                    setIsChangeRouteLocked(true)
                    setIsPointListLocked(true)
                    setIsSavingRouteLocked(true)
                }
                break;
            case 'points':
                if (liveMapBoxOpen) setLiveMapBoxOpen(false)

                if (startPointsMapBoxOpen) {
                    setStartPointsMapBoxOpen(false)
                    if (isRouteSelected) {
                        setIsAddingPointLocked(false)
                        setIsPointListLocked(false)
                        setIsSavingRouteLocked(false)
                    }
                    else {
                        setIsAddingPointLocked(true)
                        setIsPointListLocked(true)
                        setIsSavingRouteLocked(true)
                    }
                    setIsChangeRouteLocked(false)
                }
                else {
                    setStartPointsMapBoxOpen(true)
                    setIsAddingPointLocked(false)
                    setIsChangeRouteLocked(true)
                    setIsPointListLocked(true)
                    setIsSavingRouteLocked(true)
                }
                break;
            default:
                break;
        }
    }

    function renderMapBox(){
        if (!liveMapBoxOpen && !startPointsMapBoxOpen)
            return <MapBox
                Map={mapState}
                Route={editedRoute}
                onAddMarkerClick={add}
                markerType={mType}
                Reload={reloadMap}
                onUserLatLng={handleUserLocationData}
                onWaypointClick={handleAddWaypointMarkerClick}
                onLoad={handleInfoLoad}
                onMarkerDragEnd={handleDragEnd}
                onMapDragEnd={handleMapDragEnd}
                onDelete={handleMarkerRemove}
                onMapLoad={handleMapLoad}
                routeFocus={routeFocus}
                isArchiveOpen={archiveOpen}
                />
        else if (liveMapBoxOpen)
            return <LiveMapBox
                Map={mapState}
                onMapLoad={handleMapLoad}
                teacherPoints={teachersPoints}
                teachersList={teachersList}
                />
        else if (startPointsMapBoxOpen)
            return <PointMapBox
                    Map={mapState}
                    onAddMarkerClick={add}
                    onMapLoad={handleMapLoad}
                    points={startPoints}
                    onStartPointClick={handleAddStartpointMarkerClick}
                    onStartPointDeleteClick={handleDeleteStartPointClick}
                    onEdit={handleEditStartPointClick}
                />
        else return <p>Błąd ładowania mapy! Spróbuj odświeżyć stronę.</p>
    }

    //dodawanie startpointów
    const handleAddStartPointCancel = () => {
        setIsAddStartpointDialogOpen(false)
        setAddMaarkerClick(false)
    }

    const handleAddStartPointConfirm = async (name) => {
        setIsAddStartpointDialogOpen(false)
        setAddMaarkerClick(false)

        let data = {
            "lat": eLatLng.lat,
            "lng": eLatLng.lng,
            "pointType": "startWaypoint",
            "draggable": true,
            "value": 0,
            "description": name,
            "routeOnly": false
        }

        let baseurl = `${process.env.REACT_APP_BASE_URL}/api/point/`;
        await postAjax(baseurl, data)

        dispatch(getAllPoints())
    }

    //usuwanie startpointów
    const handleDeleteStartPointClick = (pk) => {
        setDeleteStartPointID(pk)
        setIsDelStartpointDialogOpen(true)
    }

    const handleDeleteStartPointConfirm = async () => {
        setIsDelStartpointDialogOpen(false)
        let baseurl = `${process.env.REACT_APP_BASE_URL}/api/point/` + DeleteStartPointID + '/';
        await deleteAjax(baseurl)
        dispatch(getAllPoints())
    }

    //edycja startpointów
    const handleEditStartPointClick = async (marker) => {
        setEditStartPointData(marker)
        setIsEditStartpointDialogOpen(true)
    }

    const handleEditStartPointConfirm = async (data) => {
        setIsEditStartpointDialogOpen(false)
        setAddMaarkerClick(false)
        let baseurl = `${process.env.REACT_APP_BASE_URL}/api/point/` + data.pk + '/';
        await putAjax(baseurl, data)
        dispatch(getAllPoints())
    }

    const handleEditStartPointClose = () => {
        setIsEditStartpointDialogOpen(false)
        setAddMaarkerClick(false)
        dispatch(getAllPoints())
    }

    // eslint-disable-next-line
    const [open, setOpen]                                               = useState(false);
    const [add, setAddMaarkerClick]                                     = useState(false);
    const [showPointList, setShowPointList]                             = useState(false)
    const [showSaveRoutes, setShowSaveRoutes]                           = useState(false)
    const [mType, setMarkerType]                                        = useState(null);
    const [ErrorDialogOpen, setErrorDialogOpen]                         = useState(false)
    const [addRouteDialogOpen, setAddRouteDialogOpen]                   = useState(false)
    const [saveRouteDialogOpen, setSaveRouteDialogOpen]                 = useState(false)
    const [findLocationButton, setFindLocationButton]                   = useState(false)
    const [liveMapBoxOpen, setLiveMapBoxOpen]                           = useState(false)
    const [startPointsMapBoxOpen, setStartPointsMapBoxOpen]             = useState(false)
    const [liveMapBoxRefresh, setLiveMapBoxRefresh]                     = useState(false)

    const [eLatLng, seteLatLng]                                         = useState(null)
    const [mapCont, setMapContainer]                                    = useState(null)
    const [accountType, setAccountType]                                 = useState(null);
    const [startedCoords,setStartedCoords]                              = useState(null);
    const [isAlertBox, setAlertBox]                                     = useState(false)
    const [alertBoxTextValue, setAlertboxTextValue]                     = useState(null)
    const [routeFocus, setRouteFocus]                                   = useState(true)
    const [showArchive, setShowArchive]                                 = useState(false)
    const [currentArchiveLesson, setCurrentArchiveLesson]               = useState(null)
    const [showStatistics, setShowStatistics]                           = useState(false)
    const [isArchiveShowingRoute, setIsArchiveShowingRoute]             = useState(false)
    const [startNav, setStartNav]                                       = useState(false)
    const [isRouteSelected, setIsRouteSelected]                         = useState(false)
    const [isAddStartpointDialogOpen, setIsAddStartpointDialogOpen]     = useState(false)
    const [isEditStartpointDialogopen, setIsEditStartpointDialogOpen]   = useState(false)
    const [isDelStartpointDialogOpen, setIsDelStartpointDialogOpen]     = useState(false)
    const [archiveOpen, setArchiveOpen]                                 = useState(false)

    const [EditStartPointData, setEditStartPointData]                   = useState(null)
    const [DeleteStartPointID, setDeleteStartPointID]                   = useState(null)

    const [isAddingPointLocked, setIsAddingPointLocked]                 = useState(true)
    const [isChangeRouteLocked, setIsChangeRouteLocked]                 = useState(false)
    const [isSavingRouteLocked, setIsSavingRouteLocked]                 = useState(true)
    const [isPointListLocked, setIsPointListLocked]                     = useState(true)

    const [lockIcon, setLockIcon]                                       = useState(true)
    const [viewIcon, setViewIcon]                                       = useState(true)

    const [styleMap, setStyleMap]                                       = useState("ckvql7bep7cpe15qt6te7bnh6")
    const [changeStyleMap, setChangeStyleMap]                           = useState(true)
    const [defaultStartWaypoint,setDefaultStartWaypoint]                = useState(null)

    const [isNavStartDisabled, setIsNavStartDisabled]                   = useState(false)
    const [isNavPauseDisabled, setIsNavPauseDisabled]                   = useState(false)
    const [isNavSummaryOpen, setIsNavSummaryOpen]                       = useState(false)
    const [isNavigationPaused, setIsNavigationPaused]                   = useState(false)
    

    let startDate = moment(new Date()).format('YYYY-MM-DD');
    let endDate = new Date(startDate)
    endDate.setDate(endDate.getDate() + 7)
    endDate= moment(new Date(endDate)).format('YYYY-MM-DD')
    
    //WCZYTANIE NAJBLIŻSZEJ LUB OBECNIEJ TRWAJĄCEJ LEKCJI INSTRUKORA
    useEffect(() => {
        dispatch(setRouteViibility(false))
        var today = new Date()
        if (accountType !== null) {
            if (accountType === accountTypes.teacher) {
                try {
                    const closest = lessonsRoutes.reduce((a, b) => {
                        const adiff = a.end - today;
                        return adiff > 0 && adiff < b.end - today ? a : b;
                    });
                    setTimeout(function () {
                        if(today- closest.end < 0 && Routes !==undefined && closest.route){
                            let tmpRoute = new Route(Routes.filter((r) => r.pk === closest.route)[0])
                            tmpRoute.routes = Routes.filter((r) => r.pk === closest.route)[0].routes.map((p) => new Point(p));
                            if( closest.startPoint !== null &&
                                    startPoints.length>0 &&
                                tmpRoute.isArchived!== true){
                                    setDefaultStartWaypoint(new Point(tmpRoute.routes.filter((p)=> p.pointType===pointTypes.startWaypoint)[0]))
                                    let tmpStartPoint = new Point(startPoints.filter((p)=> p.pk === closest.startPoint)[0])
                                    let tmp = new Route(tmpRoute)
                                    tmp.routes = tmpRoute.routes.map((p) => new Point(p));
                                    tmp.routes.filter((p)=> p.pointType===pointTypes.startWaypoint).map((p) => {
                                        p.lat = tmpStartPoint.lat
                                        p.lng = tmpStartPoint.lng
                                        p.description = tmpStartPoint.description
                                        p.routeOnly= tmpStartPoint.routeOnly
                                        return p
                                    })
                                    dispatch(setEditedRoute(tmp))
                                    dispatch(setTeacherCurrentLesson(closest))
                                    dispatch(setRouteViibility(true))
                            }
                            else{
                            dispatch(setEditedRoute(tmpRoute))
                            dispatch(setTeacherCurrentLesson(closest))
                            dispatch(setRouteViibility(true))
                            }
                            if(!tmpRoute.isArchived){
                                setIsAddingPointLocked(false)
                                setIsSavingRouteLocked(false)
                                setIsPointListLocked(false)
                                
                            } 
                        }
                    }, 500);
                }
                catch (er) {
                }
            }
        }
        // eslint-disable-next-line
    }, [Routes])

    //ODSWIEŻANIE LOKAZLIACJI UZYTKOWNIKA W TRAKCIE TRWANIA NAWIGACJI
    useEffect(() => {
        if (accountType !== null) {
            if (accountType === accountTypes.teacher) {
                if (startNav) {
                    if(navigationRoute.routes.length===0){
                        const eR = new Route(editedRoute)
                        eR.routes = editedRoute.routes.map((p) => new Point(p));
                        eR.routes = eR.routes.map((p)=>{
                            p.navStart = true
                            return p
                        })

                        //eslint-disable-next-line
                        if (eR.routes.length != 0) {
                            try {
                                eR.routes.filter((p) => p.value === 0).map((p) => {
                                    p.lat = mapState.userCurrentLocation.lat
                                    p.lng = mapState.userCurrentLocation.lng
                                    return p
                                })
                                dispatch(setNavigationRoute(eR, true))
                                handleCallRouteReload()
                            }
                            catch (err) {
                                console.log(err)
                            }
                        }
                    }
                    else{
                        const eR = new Route(navigationRoute)
                        eR.routes = navigationRoute.routes.map((p) => new Point(p));
                        // eslint-disable-next-line
                        if (eR.routes.length != 0) {
                            try {
                                eR.routes.filter((p) => p.value === 0).map((p) => {
                                    p.lat = mapState.userCurrentLocation.lat
                                    p.lng = mapState.userCurrentLocation.lng
                                    return p
                                })
                                dispatch(setNavigationRoute(eR, true))
                                handleCallRouteReload()
                            }
                            catch (err) {
                                console.log(err)
                            }
                        }
                    }
                    
                }
            }
        }
        //eslint-disable-next-line
    }, [mapState.userCurrentLocation, startNav])

    //LICZENIE STATSYTYK W TRAKCIE TRWANIA NAWIGACJI
    useEffect(() => {
        var prevPoint = L.latLng(navigation.prevLocation);
        var currPoint = L.latLng(mapState.userCurrentLocation);
        if (accountType !== null) {
            if (accountType === accountTypes.teacher) {
                if (startNav) {
                    if (navigation.prevLocation.length === 0) {
                        dispatch(setPrevLocation(mapState.userCurrentLocation))
                    }
                    else {
                        if (navigation.prevLocation.lat === mapState.userCurrentLocation.lat && navigation.prevLocation.lng === mapState.userCurrentLocation.lng) {
                        }
                        else {
                            try {

                                var dist = prevPoint.distanceTo(currPoint)
                                dispatch(setPrevLocation(mapState.userCurrentLocation))
                                dispatch(setDistance(dist, navigation.distance))
                                let speed = Math.round(dist)
                                dispatch(setSpeed(speed))
                                
                            }
                            catch (er) {
                            }
                        }
                    }
                }
            }
        }
        //eslint-disable-next-line
    }, [mapState.userCurrentLocation])

    //POBIERANIE OBECJENJE LOKALIZACJI UŻYTKONIKA
    useEffect(() => {
        let userTypes = ['admin', 'teacher', 'student']
        for (const item of userTypes) {
            if (getPermission(item)) setAccountType(item);
        }
        if (navigator.geolocation) {
            if (accountType === accountTypes.teacher) {
                navigator.geolocation.watchPosition(function (position) {
                    dispatch(setUserCurrentLocation(position.coords.latitude, position.coords.longitude, position.coords.accuracy))
                }, function (err) {
                    eventEmitter.emit('popupAlert',[' Błąd pobierania lokalizacji ! ','error']);
                },
                );
            }
            else {
                navigator.geolocation.watchPosition(function (position) {

                    dispatch(setUserCurrentLocation(position.coords.latitude, position.coords.longitude, position.coords.accuracy))
                }, function (err) {
                    eventEmitter.emit('popupAlert',[' Błąd pobierania lokalizacji ! ','error']);
                },
                );
            }
        }
        // eslint-disable-next-line
    }, [dispatch])

    //WYŚWIETLANIE ALERTU
    useEffect(() => {
        if (mapState.routeSavingNeeded === true) {
            setAlertboxTextValue("Masz niezapisane zmiany w edytowanej trasie!")
            setAlertBox(true)
        }
        else setAlertBox(false)
    }, [mapState.routeSavingNeeded])

    //POBIERANIE WSZYSTKICH DANYCH DO APLIKACJI
    useEffect(() => {
        if (accountType !== null) {
            if (accountType === accountTypes.teacher) {
                dispatch(getLessons(startDate, endDate,parseInt(localStorage.getItem("userPK"))))
                dispatch(getAllTeacherPoints(localStorage.getItem('userPK')))

                if(localStorage.getItem("navigationState") !== null){
                    dispatch(setNavigationRoute(JSON.parse(localStorage.getItem("navigationState"))))
                }
            }
            dispatch(clearLessons())
            dispatch(getAllLessons())
            dispatch(getAllRoutes())
            dispatch(getAllPoints())
        }
        setArchiveOpen(false)
        // eslint-disable-next-line
    }, [accountType, accountTypes.teacher, setAccountType, dispatch])

    //WZNAWIANIE NAVIGACJI PO ODŚWIEŻENIU APLIKACJI
    useEffect(()=>{
        if(accountType === accountTypes.teacher)
        if(localStorage.getItem("navigationState") !== null){
            if(editedRoute.routes.length !== 0){
                eventEmitter.emit('popupAlert',[' Wznawianie navigacji... ','success']);
                setIsNavigationPaused(false)
                setStartNav(true)
                setRouteFocus(false)
                setIsAddingPointLocked(true)
                setIsChangeRouteLocked(true)
                setLockIcon(false)
                mapCont.flyTo(mapState.userCurrentLocation, 16)
                setStartedCoords(mapState.userCurrentLocation);
            }
        }
    // eslint-disable-next-line
    },[editedRoute.routes])

    //POBIERANIE LOKALIZACJI INSTRUKORÓW
    useEffect(() => {
        if (liveMapBoxOpen) {
            setTimeout(() => {
                setLiveMapBoxRefresh(!liveMapBoxRefresh)
                dispatch(getAllTeacherPoints())
            }, 4000)
        }
        else {
            if (accountType !== null) {
                if (accountType === accountTypes.teacher) {
                }
                else {
                }
            }
        }
        // eslint-disable-next-line
    }, [liveMapBoxOpen, liveMapBoxRefresh])

    //AKTUALIZACJA OBECNEJ LOKALIZACJI INSTRUKTORA DO BAZY
    useEffect(() => {
        if (accountType !== null) {
            if (accountType === accountTypes.teacher) {
                if (teachersPoints.length > 0) {
                    //eslint-disable-next-line
                    dispatch(updateTeacherPoint(teachersPoints.filter((tp) => tp.teacher == localStorage.getItem('userPK')), mapState.userCurrentLocation))
                }
            }
        }
        //eslint-disable-next-line
    }, [mapState.userCurrentLocation])

    //USUWANIE PUNKTÓW Z OBECNIE PROWADZONEJ NAWIGACJI PRZEZ INSTRUKTORA
    useEffect(() => {
        if (navigationRoute.routes.length > 0) {
            var startPoint = navigationRoute.routes.filter((p) => p.value === 0);
            var waypointsValue = [];
            navigationRoute.routes.forEach(element => {
                if (element.value > 0 && element.value !== 100) {
                    waypointsValue = waypointsValue.concat(element.value)
                }
            });
            var currPoint = L.latLng(startPoint[0]);
            var nextPoint = L.latLng(navigationRoute.routes.filter((p) => p.value === Math.min(...waypointsValue))[0]);
            if (nextPoint !== undefined) {
                var dist = currPoint.distanceTo(nextPoint);
                if (dist < 50) {
                    if(navigationRoute.routes.filter((p) => p.value === Math.min(...waypointsValue))[0].pointType !==pointTypes.mistake)
                        dispatch(deleteNavigationMarker(navigationRoute.routes.filter((p) => p.value === Math.min(...waypointsValue))[0].pk));
                }
            }

        }
        // eslint-disable-next-line
    }, [navigationRoute, deleteMarker])

    //USTAWIA FLAGĘ CZY ZOSTAŁA WCZYTANA JAKAŚ LEKCJA DO MAPY
    useEffect(()=>{
        if (editedRoute.isArchived) setIsNavStartDisabled(true)
        else setIsNavStartDisabled(false)
        if (editedRoute.routes.length>0) setIsRouteSelected(true)
        else setIsRouteSelected(false)
    // eslint-disable-next-line
    },[editedRoute.routes])


    return (
        <Box component="main" sx={{ width: '100%' }} >
            
            <Navbar isWarning={moment().isBetween(moment(currentLesson.start), moment(currentLesson.end)) && !startNav && !editedRoute.isArchived}/>
            <Box sx={{
                mb: 9,
            }} />

            <Box>
                <Box style={{ zIndex: "1000", width: '100%' }}>
                    <MapFixedSettings
                        routeData={mapState}
                        isNavigationStared={startNav}
                        isNavigationPaused={isNavigationPaused}
                        onArchiveClick={() => { setShowArchive(true) }}
                        onSetTime={dispatchSetTime}
                        globalTime={navigation.time}
                        currentLesson={currentLesson}
                        onStatisticsClick={() => { setShowStatistics(true) }}
                        isStatsDisabled={isArchiveShowingRoute}
                    />
                </Box>
                <Box style={{ position: 'absolute', width: "100%" }}>

                    {accountType === accountTypes.admin ?
                            renderMapBox()
                        : (
                            <><InstructorMapBox
                                Map={mapState}
                                Route={startNav ? navigationRoute : editedRoute}
                                onAddMarkerClick={add}
                                markerType={mType}
                                Reload={reloadMap}
                                onUserLatLng={handleUserLocationData}
                                onWaypointClick={handleAddWaypointMarkerClick}
                                onLoad={handleInfoLoad}
                                onMarkerDragEnd={handleDragEnd}
                                onMapDragEnd={handleMapDragEnd}
                                onDelete={handleMarkerRemove}
                                onMapLoad={handleMapLoad}
                                routeFocus={routeFocus}
                                Navigate={startNav}
                                isArchiveOpen={archiveOpen}
                            />

                                <Button className={classes.pauseNavBtn} disabled={isNavPauseDisabled || !startNav} onClick={handleNavigationPauseButton}>
                                    {isNavigationPaused ? (
                                        <Tooltip title="Kontynuuj nawigajcę" placement='right' TransitionComponent={Zoom} arrow>
                                            <PlayArrowIcon className={classes.iconNav} sx={{color: isNavPauseDisabled || !startNav ? 'gray' : 'green' }} fontSize="large" />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Zatrzymaj nawigajcę" placement='right' TransitionComponent={Zoom} arrow>
                                            <PauseIcon className={classes.iconNav} color={isNavPauseDisabled || !startNav ? 'gray' : 'warning'} fontSize="large" />
                                        </Tooltip>
                                    )}
                                </Button>
                                <Button className={classes.startNavBtn} disabled={isNavStartDisabled} onClick={handleNavigationStartButton}>
                                    {startNav ? (
                                        <Tooltip title="Zakończ nawigajcę" placement='right' TransitionComponent={Zoom} arrow>
                                            <CloseIcon className={classes.iconNav} color="error" fontSize="large" />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Rozpocznij nawigajcę" placement='right' TransitionComponent={Zoom} arrow>
                                            <NavigationIcon className={classes.iconNav} color={isNavStartDisabled ? 'gray' : 'primary'} fontSize="large" />
                                        </Tooltip>
                                    )}
                                </Button>
                            </>
                        )
                    }

                    <SpeedDialogMap
                        open={open}
                        onMarkerClick={handleSpeedDialogAddClick}
                        onErrorClick={handleSpeedDialogErrorClick}
                        onRouteClick={handleSpeedDialogRouteClick}
                        handleShowPointList={handleShowPointList}
                        handleShowSaveRoutes={handleShowSaveRoutes}
                        isClosed={isArchiveShowingRoute}
                        isMistakesLocked={startNav}
                        isAddingPointLocked={isAddingPointLocked}
                        isChangeRouteLocked={isChangeRouteLocked}
                        isRouteSelected={isRouteSelected}
                        isPointListLocked={isPointListLocked}
                        isSavingRouteLocked={isSavingRouteLocked}
                    />

                    {showPointList ? (
                        <WaypointsList
                            items={editedRoute}
                            onDragEnd={handleListDragEnd}
                        />
                    ) : (
                        <></>
                    )}

                    <Box className={classes.miniIconBox}>
                        <Typography align='center' variant='caption' style={{ fontWeight: 'bold', color: '#666666', paddingTop: '5px' }}>
                            {navigation.speed} M/s <br />
                            {(navigation.distance / 1000).toFixed(3)} Km
                        </Typography>


                        <CurrentLocationButton
                            findLocation={findLocationButton}
                            onClick={handleFindLocationButtonClick}
                        />

                        <Button className={classes.showRoad} onClick={handleIsRouteVisable}>
                            <Tooltip title="Pokaż trasę" placement='left' TransitionComponent={Zoom} arrow>
                                {viewIcon ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </Tooltip>

                        </Button>

                        <Button className={classes.lockRoad} onClick={handleRouteFocusClick}>
                            <Tooltip title="Blokada automatycznego skalowania trasy" placement='left' TransitionComponent={Zoom} arrow>
                                {lockIcon ? <LockIcon /> : <LockOpenIcon />}
                            </Tooltip>

                        </Button>

                        <Button className={classes.mapStyle} onClick={handleChangeMapStyle}>
                            <Tooltip title="Styl mapy" placement='left' TransitionComponent={Zoom} arrow>
                                <PublicIcon />
                            </Tooltip>
                        </Button>
                        {accountType === accountTypes.admin ? (
                            <>
                                <Button className={classes.mapStyle} onClick={() => handleSwitchViewClick('live')} >
                                    <Tooltip title="Pokaż instruktorów" placement='left' TransitionComponent={Zoom} arrow>
                                        {liveMapBoxOpen ? <DirectionsCarOutlinedIcon sx={{filter: 'drop-shadow(0 0 5px red)'}}/> : <DirectionsCarIcon />}
                                    </Tooltip>
                                </Button>
                                <Button className={classes.mapStyle} onClick={() => handleSwitchViewClick('points')} >
                                    <Tooltip title="Edycja punktów początkowych" placement='left' TransitionComponent={Zoom} arrow>
                                        {startPointsMapBoxOpen ? <EditLocationOutlinedIcon sx={{filter: 'drop-shadow(0 0 5px red)'}}/> : <EditLocationAltIcon />}
                                    </Tooltip>
                                </Button>
                            </>
                        ) : (<></>)}

                    </Box>
                </Box>

                <ErrorDialogText
                    open={ErrorDialogOpen}
                    onConfirm={handleErrorDialogConfirm}
                    onClose={handleErrorDialogClose}
                />

            </Box>

            {showSaveRoutes ? (
                <DialogLessonsRoute
                    routes={accountType === accountTypes.admin ? Routes : lessonsRoutes}
                    onChange={handleLessonsChange}
                    onClick={handleAddNewRouteClick}
                    handleShowSaveRoutes={handleShowSaveRoutes}
                    isAdmin={accountType === accountTypes.admin ? true : false}
                    onDelete={handleRouteDeleteClick}
                    title={accountType === accountTypes.admin ? 'Lista dostępnych tras ' : 'Wybierz swoją lekcję '}
                />
            ) : null}

            {showArchive ?
                <ArchiveDialog
                    routes={Routes}
                    lessons={allLessons}
                    onChange={handleArchiveLessonChange}
                    onClose={handleArchiveDialogClose}
                    isAdmin={accountType === accountTypes.admin ? true : false}
                /> : null
            }

            {isNavSummaryOpen ?
                <NavigationSummary
                    onConfirm={handleFinishNavigationConfirm}
                    onCancel={handleNavigationSummaryClose}
                    onExit={handleNavigationSummaryExit}
                    navStats={navigation}
                    mistakes={navigationRoute.routes}
                />
                : null
            }
            {showStatistics ?
                <LessonStatistics
                    onClose={handleLessonStatisticsClose}
                    mistakes={editedRoute.routes}
                    lesson={allLessons.filter((elem)=> elem.id === currentArchiveLesson)[0]}
                />
                : null
            }
            {isAddStartpointDialogOpen ?
                <StartPointDialog
                    onClose={handleAddStartPointCancel}
                    onConfirm={handleAddStartPointConfirm}
                />
                : null
            }
            {isEditStartpointDialogopen ?
                <EditStartPointDialog
                    onClose={handleEditStartPointClose}
                    onConfirm={handleEditStartPointConfirm}
                    data={EditStartPointData}
                />
                : null
            }
            {isDelStartpointDialogOpen ?
                <DeleteStartPointDialog
                    onClose={()=>setIsDelStartpointDialogOpen(false)}
                    onConfirm={handleDeleteStartPointConfirm}
                />
                : null
            }

            <AddRouteDialog
                open={addRouteDialogOpen}
                onConfirm={handleAddNewRouteConfirm}
                onClose={handleAddNewRouteClose}

            />
            <SaveRouteDialog
                Route={editedRoute}
                open={saveRouteDialogOpen}
                onClose={handleSaveRouteDialogClose}
                onConfirm={handleSaveRouteDialogConfirm}
            />
            <Footer/>
            {isAlertBox ? <MapAlertCard type="warning" value={alertBoxTextValue} /> : ''}
            {isNavigationPaused && !isNavSummaryOpen && startNav ? <MapAlertCard type="warning" value="Nawigacja została zatrzymana." /> : null}

        </Box>

    )
}
const mapStateToProps = (state) => ({
    mapState: state.map,
    lessonsRoutes: state.lesson.lessonsList,
    allLessons: state.lesson.allLessonsList,
    Routes: state.map.Routes,
    editedRoute: state.map.editedRoute,
    reloadMap: state.map.showRoute,
    teachersPoints: state.map.teachersPoints,
    navigationRoute: state.map.navigationRoute,
    currentLesson: state.map.teacherCurrrentLesson,
    navigation: state.navigation,
    startPoints: state.map.Points,
    teachersList: state.teacher.TeacherList
})

const mapDispatchToProps = (dispatch) => ({
    dispatchSetTime     : () => dispatch(setTime()),
    dispatchResetTime   : () => dispatch(resetTime()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Map)