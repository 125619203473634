import { LESSONS } from "../const/actionTypes";
import moment from 'moment'

const initialState = {
    lessonsList: [],
    allLessonsList: [],
    lessonsLoaded: false,
    isCounterLoaded: false,
    userCounter: {'userID': null, 'counter': null, 'realCounter': null},
    typeCounter: {'accountType': null, 'counter': []}
};
const lessonReducer = (state = initialState, action) => {
    switch (action.type) {
        case LESSONS.SET_LESSONS:
            const lessonsList = action.item.map((lesson) =>{
                lesson.start = moment(lesson.start).toDate()
                lesson.end = moment(lesson.end).toDate()
                return lesson
            } )
            return {
                ...state,
                lessonsLoaded: true,
                allLessonsList: [...state.allLessonsList],
                lessonsList
            };
        case LESSONS.SET_ALL_LESSONS:
            return {
                ...state,
                allLessonsList: [...state.allLessonsList, action.item],
                lessonsList: [...state.lessonsList]
            };
        case LESSONS.CLEAR_LESSONS:
            return {
                ...state,
                lessonsList: [],
                allLessonsList: [],
                lessonsLoaded: false,
            }
        case LESSONS.SET_USER_COUNTER:
            return {
                ...state,
                isCounterLoaded: true,
                userCounter: {'userID': action.item.userID, 'counter': action.item.counter, 'realCounter': action.item.realCounter}
            }
        case LESSONS.SET_TYPE_COUNTER:
            return {
                ...state,
                isCounterLoaded: true,
                typeCounter: {'accountType': action.item.accountType, 'counter': action.item.counter}
            }
        case LESSONS.SET_COUNTER_LOADING:
            return {
                ...state,
                isCounterLoaded: action.item
            }
        default:
            return state;
    }
};

export default lessonReducer;
