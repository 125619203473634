import { NAVIGATION } from "../const/actionTypes";

export const setPrevLocation = (prevLocation) => {
    return {
        type: NAVIGATION.SET_PREV_LOCATION,
        prevLocation
    }
}
export const resetPrevLocation = () => {
    return {
        type: NAVIGATION.RESET_PREV_LOCATION
    }
}
export const setDistance = (distance, dst) => {
    distance = distance + dst
    return {
        type: NAVIGATION.SET_DISTANCE,
        distance
    }
}
export const resetDistance = () => {
    return {
        type: NAVIGATION.RESET_DISTANCE
    }
}
export const setSpeed = (speed) => {
    return {
        type: NAVIGATION.SET_SPEED,
        speed
    }
}
export const resetSpeed = () => {
    return {
        type: NAVIGATION.RESET_SPEED
    }
}
export const setTime = () => {
    return {
        type: NAVIGATION.SET_TIME,
    }
}
export const resetTime = () => {
    return {
        type: NAVIGATION.RESET_TIME
    }
}

export default (
    setPrevLocation,
    resetPrevLocation,
    setDistance,
    resetDistance
)