import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography, ListItem, ListItemText, List } from "@mui/material"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HeightIcon from '@mui/icons-material/Height';
import { pointTypes } from "../../../redux/const/config";

const theme = createTheme({
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingLeft: "20px",
                    paddingRight: "20px"
                },
            },
        }
    },
});

const useStyles = makeStyles((theme) => ({
    markerList: {
        position: "absolute",
        top: "38%",
        right: "2%",
        borderRadius: "4px",
        zIndex: "1000",
        background: "rgb(255, 255, 255, 0.85)",
        boxShadow: "0px 0px 5px #000",
        overflow: "scroll",
        maxHeight: "40%",
        paddingTop: "15px",
    },
    title: {
        margin:'auto'
    }
}));

const WaypointsList = ({

    items,
    onDragEnd
}) => {

    const {
        routes
    } = items

    function compare(a, b) {
        if (a.value < b.value) {
            return -1;
        }
        if (a.value > b.value) {
            return 1;
        }
        return 0;
    }

    function getName(value, type, isPrimary){
        switch(type){
            case pointTypes.waypoint:
                if (isPrimary) return 'Punkt nawigacyjny'
                else return 'Lp. ' + value
            case pointTypes.startWaypoint:
                if (isPrimary) return 'Punkt początkowy'
                break
            case pointTypes.endWaypoint:
                if (isPrimary) return 'Punkt końcowy'
                break
            default:
                break
        }
    }

    var wayPoints = routes.filter((w) => w.pointType !== pointTypes.mistake).sort(compare)

    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <Box className={classes.markerList} >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <HeightIcon />
                    <Typography
                        variant="subtitle2"
                        align="center"
                    >
                        Przesuń punkt
                    </Typography>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="12345">
                        {(provided) => (
                            <List ref={provided.innerRef}
                            >
                                {wayPoints.map((item, index) =>
                                (
                                    <Draggable key={item.pk} isDragDisabled={!item.draggable} draggableId={String(item.pk)} index={index}>
                                        {(provided, snapshot) => {
                                            const style = {
                                                backgroundColor: snapshot.isDragging ? 'rgba(209, 209, 209, 0.49)' : 'rgba(255, 255, 255, 0)',
                                                fontSize: 18,
                                                ...provided.draggableProps.style,
                                            };
                                            return (
                                                <ListItem{...provided.draggableProps}{...provided.dragHandleProps} ref={provided.innerRef} style={style}>
                                                    <div style={{borderBottom: '1px dotted gray', borderTop: '1px dotted gray'}}>
                                                        <ListItemText
                                                            primary={getName(item.value, item.pointType, true)}
                                                            secondary={getName(item.value, item.pointType, false)}
                                                        />
                                                    </div>
                                                </ListItem>
                                            )
                                        }}
                                    </Draggable>
                                )
                                )}
                                {provided.placeholder}
                            </List>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        </ThemeProvider>
    )
}

export default WaypointsList