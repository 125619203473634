import { React, useEffect, useState, Fragment } from 'react';
import {
    TableBody,
    TableRow,
    TableHead,
    TableFooter,
    TablePagination,
    TableCell,
    Paper,
    Table,
    IconButton,
    TextField,
    InputAdornment,
    Tooltip,
    Collapse,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import CalculateIcon from '@mui/icons-material/Calculate';

import Calculator from './Calculator'

import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: 100,
        overflowX: 'auto',
    },

}));

const infoRow = {
    marginTop: "7px",
    marginBottom: "7px",
    marginLeft: "5px"

}

const searchBlock = {
    display: 'block',
}

const searchNone = {
    display: 'none',
}
const UserList = ({
    users,
    counters,
    onAddUser,
    onEditUser,
    onDeleteUser,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [filteredUsers, setFilteredUsers] = useState(users)
    const [mappedUsers, setMappedUsers] = useState([])
    const [paginatedUsers, setPaginatedUsers] = useState()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [rowCounter, setRowCounter] = useState(users.length)
    const [viewSearch, setViewSearch] = useState(false)

    const isCounterLoaded = useSelector(state => state.lesson.isCounterLoaded)

    function searchUser() {
        if (viewSearch === false) {
            setViewSearch(true)
        } else {
            setViewSearch(false)
        }
    }

    function handleRowsPerPageChange(e) {
        setRowsPerPage(parseInt(e.target.value, 10))
        setPage(0)
    }

    function paginateUsers(array = mappedUsers) {
        if (rowsPerPage === -1) {
            setPaginatedUsers(mappedUsers)
            return
        }
        let result = mappedUsers.slice(page * rowsPerPage, (parseInt(page, 10) + 1) * rowsPerPage)
        setPaginatedUsers(result)
    }

    function filterUsers(value) {
        if (value === '') {
            setFilteredUsers(users)
            return
        }
        let result = users.filter(elem => {
            let valueLowerCase = value.toLowerCase()

            if (elem.first_name.toLowerCase().includes(valueLowerCase)) return true
            else if (elem.last_name.toLowerCase().includes(valueLowerCase)) return true
            else if (elem.email.toLowerCase().includes(valueLowerCase)) return true
            else if (elem.email.toLowerCase().includes(valueLowerCase)) return true
            else if (elem.phoneNumber.toLowerCase().includes(valueLowerCase)) return true
            else return false
        })

        setFilteredUsers(result)
        setRowCounter(result.length)
    }

    function getCounter(userID){
        let results = counters.filter((counter) => {
            // eslint-disable-next-line
            if (counter.pk == userID) return true
            else return false
        })

        if (results.length > 0)
            return results[0]['summaryTimeOfLessons'] / 60
        else
            return <RotateRightIcon style={{animation: 'spin 1s linear infinite'}}/>
    }

    function Row(userDict) {
        const [open, setOpen] = useState(false)

        var user = userDict['user']

        return (
            <Fragment>
                <TableRow sx={{ '& > *, &': { borderBottom: 'unset' } }}>
                    {matchesMobile ?
                            <TableCell >
                                <div style={infoRow}>{user.first_name}</div>
                                <div style={infoRow}>{user.last_name}</div>
                                <div style={infoRow}>{user.email}</div>
                                <div style={infoRow}>{user.phoneNumber}</div>
                            </TableCell>
                        :
                            <>
                                <TableCell scope="row" >{user.first_name}</TableCell>
                                <TableCell scope="row" >{user.last_name}</TableCell>
                                <TableCell scope="row" >{user.email}</TableCell>
                                <TableCell scope="row" >{user.phoneNumber || 'brak'}</TableCell>
                            </>
                    }

                    <TableCell scope="row" align="center" style={{borderBottom: 'unset'}}>
                        { getCounter(user.pk) }
                    </TableCell>

                    <TableCell align="right" style={{borderBottom: 'unset'}}>
                        <Tooltip title="Szczegółowe statystyki">
                            <IconButton
                                onClick={() => setOpen(!open)}
                                style={{ borderRadius: "50%" }}
                            >
                                <CalculateIcon color="primary"/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edytuj dane instruktora">
                            <IconButton
                                onClick={() => onEditUser(user)}
                                style={{ borderRadius: "50%" }}
                            >
                                <EditIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Usuń instruktora">
                            <IconButton
                                onClick={() => onDeleteUser(user)}
                                style={{ borderRadius: "50%" }}
                            >
                                <DeleteIcon color="error" />
                            </IconButton>
                        </Tooltip>
                    </TableCell>

                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Calculator user={user}/>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </Fragment>
        )

    }

    function mapUsers(array) {
        let results = array.map((user) =>
            <Row key={user.pk} user={user} />
        )

        if (results.length === 0) {
            results = [<TableRow key={'EmptyUsers'}>
                <TableCell colSpan={6}>
                    Nie znaleziono użytkowników
                </TableCell>
            </TableRow>]
        }

        if (Array.isArray(results)) {
            let paginatedResults = results.slice(page * rowsPerPage, (parseInt(page, 10) + 1) * rowsPerPage)
            setPaginatedUsers(paginatedResults);
        }

        return results;
    }

    /* eslint-disable */
    useEffect(() => {
        setMappedUsers(mapUsers(filteredUsers))
    }, [filteredUsers, isCounterLoaded])

    useEffect(() => {
        setFilteredUsers(users)
        setRowCounter(users.length)
    }, [users])

    useEffect(() => {
        if (mappedUsers.length !== 0 && Array.isArray(mappedUsers)) paginateUsers()
    }, [rowsPerPage, page])

    /* eslint-enable */
    return (
        <Paper className={classes.root}>
            <Table padding={matchesMobile ? "none" : "normal"}>
                <TableHead>
                    <TableRow style={viewSearch ? searchBlock : searchNone}>
                        <TableCell align="right" colSpan={6}>
                            <TextField
                                label="Szukaj użytkownika"
                                variant="standard"
                                size="small"
                                type="search"
                                onChange={e => filterUsers(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </TableCell>
                        <TableCell colSpan={6}></TableCell>
                    </TableRow>
                    <TableRow>
                        {matchesMobile ?
                                <TableCell >
                                    <div style={infoRow}>Instruktor</div>
                                </TableCell>
                            :
                                <>
                                    <TableCell scope="row" >Imię</TableCell>
                                    <TableCell scope="row" >Nazwisko</TableCell>
                                    <TableCell scope="row" >Email</TableCell>
                                    <TableCell scope="row" >Telefon</TableCell>

                                </>
                        }

                        <TableCell align="center">
                            Liczba lekcji w tym miesiącu
                        </TableCell>

                        <TableCell align="right">
                            <Tooltip title="Tworzenie nowego instruktora">
                                <IconButton onClick={onAddUser} style={{ borderRadius: "50%" }}>
                                    <PersonAddIcon color='primary' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Wyszukaj instruktora">
                                <IconButton onClick={searchUser}>
                                    <SearchIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                        </TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {paginatedUsers}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            count={rowCounter}
                            onPageChange={(e, newPage) => { setPage(newPage); }}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[10, 20, 50, { value: -1, label: 'Wszystko' }]}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count !== -1 ? count : `więcej niż ${to}`}`}
                        />
                    </TableRow>
                </TableFooter>
            </Table>

        </Paper>
    );
};


export default UserList




