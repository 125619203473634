import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { makeStyles } from "@mui/styles";

import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    SideBarBox: {
        margin: 2,
    },
    SideBarButton: {
        marginTop: 5,
        width: 100,
        height: '97%',
    },
    mobile: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    desktop: {
        flexDirection: 'column',
    },
    reverseColorText: {
        textShadow: '0px 0px 2px black',
    }

}));

export const SideBar = ({
        onFilterChange,
    }) => {
    const classes = useStyles();
    const teachers = useSelector(state => state.teacher)

    const matches = useMediaQuery('(min-width:600px)', { noSsr: true });

    function ShowBasicButton(pk, firstName, lastName, color, path, phoneNumber) {
        return (
            <Box className={classes.SideBarBox} key={pk}>
                <Button
                    variant="contained"
                    value={pk}
                    className={classes.SideBarButton}
                    style={{ backgroundColor: color }}
                    onClick={() => onFilterChange(pk)}
                >
                    <Box>
                        <Typography variant="caption">
                            <Avatar
                                src={path}
                                sx={{ width: 70, height: 70 }}
                                style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 5, marginBottom: 10 }}
                            ></Avatar>
                            <Box className={classes.reverseColorText} >{firstName} {lastName}</Box>
                            <Box className={classes.reverseColorText} >{phoneNumber}</Box>
                        </Typography>
                    </Box>
                </Button>
            </Box>
        )
    }

    function ShowButtons() {
        let buttons = [];
        for (let array of teachers.TeacherList) {
            buttons.push(ShowBasicButton(array['pk'], array['first_name'], array['last_name'], array['color'], array['photoPath'], array['phoneNumber']));
        }
        return buttons;
    }

    return (
        <Box
            className={!matches ? classes.mobile : classes.desktop}
            sx={{
                marginRight: 2,
                display: 'flex',
            }}
        >
            {ShowButtons()}
            <Box className={classes.SideBarBox}>
                <Button
                    variant="contained"
                    size="small"
                    className={classes.SideBarButton}
                    value='null'
                    onClick={() => onFilterChange()}
                >
                    <Typography variant="caption">
                        POKAŻ WSZYSTKICH
                    </Typography>
                </Button>
            </Box>

        </Box>
    )
}

export default SideBar;