import { MAPS } from "../const/actionTypes";
import Point from "../../model/Point"
import $ from 'jquery';
import { callAjax,putAjax, getToken } from "../../auth/ApiAuth";
import moment from "moment";
import Route from "../../model/Route"
import { pointTypes } from "../const/config";
import eventEmitter from "../../event/EventEmitter";
import axios from "axios";

export const setTeacherPoints = tPoints => {
    return {
        type: MAPS.SET_TEACHER_POINTS,
        tPoints
    }
}

export function dragMarker (route, dragedMarker, isAdmin, isNav, marker) {

    return async(dispatch)=>{
        const newRoute = new Route(route)
        if(marker.pointType!==pointTypes.mistake){
            dragedMarker = new Point(await ReverseGeocoding(dragedMarker))
        }

        newRoute.routes = route.routes.map((p) => new Point(p));
        newRoute.routes = newRoute.routes.map(function (point) {
            if (point.pk === marker.pk) {
                point.lat = dragedMarker.lat
                point.lng = dragedMarker.lng
                point.description = dragedMarker.description
            }
            return point
        })
        newRoute.createdByAdmin = isAdmin
        if (isNav) {
            dispatch ({
                type: MAPS.NAVIGATION_MARKER_DRAGED,
                newRoute
            })
        }
        else {
            dispatch ({
                type: MAPS.EDITED_ROUTE_POINT_DRAGGED,
                newRoute
            })
        }
    }
    

}
export const setRoute = Data => {
    return {
        type: MAPS.SET_ROUTES,
        Data
    }
}
export const setDisTime = (Distance, Time) => {
    return {
        type: MAPS.SET_ROUTE_INFO,
        Distance,
        Time
    }
}
export const deleteMarker = (index) => {
    return {
        type: MAPS.DELETE_MARKER,
        index
    }
}
export const deleteNavigationMarker = (index) => {
    return {
        type: MAPS.DELETE_NAVIGATION_MARKER,
        index
    }
}
export const addRemovedPk = (removedPk) => {
    return {
        type: MAPS.ADD_REMOVED_ID,
        removedPk
    }
}
export const setEditedRoute = (editedRoute) => {
    return {
        type: MAPS.SET_EDITED_ROUTE,
        editedRoute
    }
}
export const resetEditedRoute = () => {
    return {
        type: MAPS.RESET_EDITED_ROUTE
    }
}
export const resetEditedMarker = () => {
    return {
        type: MAPS.RESET_EDITED_MARKER
    }
}
export const setPoint = Data => {
    return {
        type: MAPS.SET_POINT,
        Data
    }
}

export const setUserCurrentLocation = (lat, lng, acc) => {
    var latlng = {}
    latlng.lat = lat
    latlng.lng = lng
    latlng.accuracy = acc
    return async dispatch => {
        latlng = new Point(await ReverseGeocoding(latlng))
        dispatch({
            type: MAPS.SET_USER_CURRENT_LOCATION,
            latlng
        })    
    }
}

export const setRouteViibility = (visable) => {
    return {
        type: MAPS.SET_MAP_VISIBILITY,
        visable
    }
}
export const setNavigationRoute = (navigationRoute, save=false) => {

    if(save === true){
        localStorage.setItem('navigationState', JSON.stringify(navigationRoute))
    }
    return {
        type: MAPS.SET_NAVIGATION_ROUTE,
        navigationRoute
    }
}
export const resetNavigationRoute = () => {
    return {
        type: MAPS.RESET_NAVIGATION_ROUTE,

    }
}

export const setIsAdminValue = (editedRoute, isAdmin) => {
    editedRoute.createdByAdmin = isAdmin
    return {
        type: MAPS.SET_IS_CREATED_BY_ADMIN,
        editedRoute
    }
}
export const setTeacherCurrentLesson = (currentLesson) => {
    return {
        type: MAPS.SET_TEACHER_CURRENT_LESSON,
        currentLesson
    }
}
export const resetTeacherCurrentLesson = () => {
    return {
        type: MAPS.RESET_TEACHER_CURRENT_LESSON,
    }
}

async function ReverseGeocoding(point){
    await axios({
        method: 'get',
        url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${point.lng},${point.lat}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCES_TOKEN}`,
        responseType: 'application/json'
      }).then(function(value){
          point.description = value.data.features[0].text
      }).catch(()=>{
        eventEmitter.emit('popupAlert',[' Błąd pobierania opisu punktu ','error']); 
      })
    return point;
}

export function editRoute (oldRoute, newPoints, count, isAdmin, isNav)  {

    return async(dispatch)=>{

        newPoints.date = moment(new Date()).format('YYYY-MM-DD HH:mm')

        // DODANIE OPISU ZAWIERAJĄCEGO ULICĘ PUNKTU
        if(newPoints.pointType!==pointTypes.mistake){
            newPoints = new Point(await ReverseGeocoding(newPoints))
        }
        
        if (oldRoute.routes.filter((w) => w.pointType === pointTypes.waypoint).length === 0) {
            if (newPoints.pointType !== pointTypes.mistake) newPoints.value = 1
        }
        else {
            if (newPoints.pointType === pointTypes.waypoint) {
                newPoints.value = Math.max.apply(null, oldRoute.routes.filter((w) => w.pointType === pointTypes.waypoint).map((p) => p.value)) + 1
            }
        }
    
        //if new route
        if (oldRoute.pk === "tmpPK") {
            count += 1
            let edited = new Route(oldRoute)
            edited.routes = oldRoute.routes.map((p) => new Point(p));
            newPoints.routeOnly = true;
            if (edited.routes.length === 0) {
                newPoints.value = 0;
                newPoints.pointType = pointTypes.startWaypoint
                edited.createdByAdmin = true
                edited.routes = edited.routes.concat(new Point(newPoints))
                        dispatch({
                            type: MAPS.ADD_POINT_TO_ROUTE,
                            edited,
                            count
                        })
            }
            else if (edited.routes.length === 1) {
                newPoints.value = 100;
                newPoints.pointType = pointTypes.endWaypoint
                edited.routes = edited.routes.concat(new Point(newPoints))
                dispatch({
                    type: MAPS.ADD_POINT_TO_ROUTE,
                    edited,
                    count
                })
            }
            else {
                newPoints.draggable = true;
                edited.routes = edited.routes.concat(new Point(newPoints))
                dispatch({
                    type: MAPS.ADD_POINT_TO_ROUTE,
                    edited,
                    count
                })
            }
        }
    
        
        else if (oldRoute.pk !== "") {
            if (newPoints.pointType === pointTypes.waypoint) {
                count += 1
                let edited = new Route(oldRoute)
                edited.routes = oldRoute.routes.map((p) => new Point(p));
                newPoints.draggable = true;
                edited.routes = edited.routes.concat(new Point(newPoints))
                edited.createdByAdmin = isAdmin
                dispatch({
                    type: MAPS.ADD_POINT_TO_ROUTE,
                    edited,
                    count
                })
            }
            else {
                if (isNav) {
                    count += 1
                    let edited = new Route(oldRoute)
                    edited.routes = oldRoute.routes.map((p) => new Point(p));
                    newPoints.draggable = true;
                    edited.routes = edited.routes.concat(new Point(newPoints))
                    edited.createdByAdmin = isAdmin
                    dispatch({
                        type: MAPS.ADD_NAVIGATION_MARKER,
                        edited,
                        count
                    })
                } else {
                    count += 1
                    let edited = new Route(oldRoute)
                    edited.routes = oldRoute.routes.map((p) => new Point(p));
                    newPoints.draggable = true;
                    edited.routes = edited.routes.concat(new Point(newPoints))
                    edited.createdByAdmin = isAdmin
                    dispatch({
                        type: MAPS.ADD_POINT_TO_ROUTE,
                        edited,
                        count
                    })
                }
    
            }
        }
        else {
            dispatch ({
                type: MAPS.SET_NONE
            })
        }
    }
    
}
export const changeMarkerOrder = (Route, Markers, isAdmin) => {
    let edited = Route
    edited.routes = Markers
    edited.createdByAdmin = isAdmin
    return {
        type: MAPS.CHANGE_MARKER_ORDER,
        edited
    }
}

export function createRoute(Route,lessonID=null,time=0,dist=0) {
    Route.defaultLength=dist
    Route.defaultTime= time
    Route.routes.map((p) => {
        delete p.visiblePk
        p.routeOnly=true
        return null
    })
    if(lessonID!=null){
        Route.isArchived = true
        Route.title = Route.title + " [ARCHIWUM]" 
    }
    return async (dispatch) => {
        try {
            await $.ajax({
                url: `${process.env.REACT_APP_BASE_URL}/api/route/`,
                type: "POST",
                contentType: 'application/json',
                data: JSON.stringify(Route),
                "headers": {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
                    Authorization: "Token " + getToken(),
                },
                success: function (response) {
                    if(lessonID!=null && response.pk !=null ){
                            let data={
                                route: response.pk,
                                teacher : localStorage.getItem("userPK"),
                                drivingTime : time,
                                distanceTraveled: dist,
                            }
                            let baseurl = `${process.env.REACT_APP_BASE_URL}/api/lessons/` + lessonID + '/'
                            putAjax(baseurl, data).catch(e => {
                                eventEmitter.emit('popupAlert',['Wystąpił problem z zapisem przejechanej trasy','error']);
                             });
                             eventEmitter.emit('popupAlert',[' Lekcja została zakończona pomyślnie ','success']);  
                            dispatch(getAllRoutes())
                    }
                    else{
                        eventEmitter.emit('popupAlert',[' Trasa dodana pomyślnie ','success']);
                        dispatch(getAllRoutes());
                        dispatch(setEditedRoute(response)) 
                    } 
                },
                error: function (error) {
                    eventEmitter.emit('popupAlert',['coś się popsuło','error']);
                }
            })
        }
        catch (err) {
            eventEmitter.emit('popupAlert',['coś się popsuło','error']);
        }
    }
}

export function deletePoint(removedPk) {
    return async (dispatch) => {
        try {
            await $.ajax({
                url: `${process.env.REACT_APP_BASE_URL}/api/point/` + removedPk + "/",
                type: "DELETE",
                contentType: 'application/json',
                "headers": {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
                    Authorization: "Token " + getToken(),
                },
                success: function (response) {
                    dispatch(getAllRoutes())
                },
                error: function (error) {
                }
            })
        }
        catch (err) {
            console.log(err)
        }
    }
}

export function updateRoute(r,dist=0,time=0,sPoint=null) {
    r.defaultLength=dist
    r.defaultTime=time
    // eslint-disable-next-line
    if (r.createdByAdmin === false && r.createdBy!=localStorage.getItem("userPK")) {
        const route = new Route(r)
        route.routes = r.routes.map((p) => new Point(p));
        if(sPoint!==null){
            route.routes[0] = sPoint
        }
        route.routes.map((p) => {
            delete p.pk
            delete p.visiblePk
            return null
        })
        route.title = route.title + ", Instruktor : " + localStorage.getItem('userName')
        return async (dispatch) => {
            try {
                await $.ajax({
                    url: `${process.env.REACT_APP_BASE_URL}/api/route/`,
                    type: "POST",
                    contentType: 'application/json',
                    data: JSON.stringify(route),
                    "headers": {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
                        Authorization: "Token " + getToken(),
                    },
                    success: function (response) {
                        eventEmitter.emit('popupAlert',[' Trasa została zaktualizowana ','success']);
                        dispatch(getAllRoutes())
                        dispatch(setEditedRoute(response))
                    },
                    error: function (error) {
                        eventEmitter.emit('popupAlert',[' Błąd aktulizacji trasy ','error']);
                    }
                })
            }
            catch (err) {
                console.log(err)
            }
        }
    }
    return async (dispatch) => {
        const routee = new Route(r)
        routee.routes = r.routes.map((p) => new Point(p));
        if(sPoint!==null){
            routee.routes[0] = sPoint
        }
        routee.routes.map((p) => {
            if (String(p.pk).includes("temp")) {
                delete p.visiblePk
                return null
            }
            return null
        })
        try {
            await $.ajax({
                url: `${process.env.REACT_APP_BASE_URL}/api/route/${routee.pk}/`,
                type: "PATCH",
                contentType: 'application/json',
                data: JSON.stringify(routee),
                "headers": {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
                    Authorization: "Token " + getToken(),
                },
                success: function (response) {
                    eventEmitter.emit('popupAlert',[' Trasa została zaktualizowana','success']);
                    dispatch(getAllRoutes())
                    dispatch(setEditedRoute(response))
                },
                error: function (error) {
                    eventEmitter.emit('popupAlert',[' Błąd aktulizacji trasy ','error']);
                }
            })
        }
        catch (err) {
            console.log(err)
        }
    }
}
export function updateTeacherPoint(Route, userLocation) {
    Route[0].lat = userLocation.lat
    Route[0].lng = userLocation.lng
    return async (dispatch) => {
        try {
            await $.ajax({
                url: `${process.env.REACT_APP_BASE_URL}/api/teacherPoint/` + Route[0].id + "/",
                type: "PATCH",
                contentType: 'application/json',
                data: JSON.stringify(Route[0]),
                "headers": {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
                    Authorization: "Token " + getToken(),
                },
                success: function (response) {
                    dispatch(getAllTeacherPoints())
                },
                error: function (error) {
                    console.log(error);
                }
            })
        }
        catch (err) {
            console.log(err)
        }
    }
}

export function deleteRoute(deletePK) {
    return async (dispatch) => {
        try {
            await $.ajax({
                url: `${process.env.REACT_APP_BASE_URL}/api/route/` + deletePK + "/",
                type: "DELETE",
                contentType: 'application/json',
                "headers": {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
                    Authorization: "Token " + getToken(),
                },
                success: function (response) {
                    dispatch(getAllRoutes())
                    dispatch(resetEditedRoute())
                    setTimeout(function () {
                        dispatch(setRouteViibility(false))
                    }, 100);
                    eventEmitter.emit('popupAlert',[' Trasa została usunięta ','success']);
                },
                error: function (error) {
                    eventEmitter.emit('popupAlert',[' Błąd usuwania trasy ','error']);
                }
            })
        }
        catch (err) {
            console.log(err)
        }
    }
}

const fetchTeacherPoints = async () => {
    let baseurl = `${process.env.REACT_APP_BASE_URL}/api/teacherPoint/`
    return callAjax(baseurl).catch(()=>{
        eventEmitter.emit('popupAlert',[' BŁĄD POBIERANIA DANYCH! ','error']);
    });
}
const fetchRoutes = async () => {
    let baseurl = `${process.env.REACT_APP_BASE_URL}/api/route/`
    return callAjax(baseurl).catch(()=>{
        eventEmitter.emit('popupAlert',[' BŁĄD POBIERANIA DANYCH! ','error']);
    });;
}
const fetchPoints = async () => {
    let baseurl = `${process.env.REACT_APP_BASE_URL}/api/point/?type=startWaypoint`
    return await callAjax(baseurl).catch(()=>{
        eventEmitter.emit('popupAlert',[' BŁĄD POBIERANIA DANYCH! ','error']);
    });;
}


export function getAllTeacherPoints(teacherId = null) {
    return async (dispatch) => {
        try {
            const Points = await fetchTeacherPoints();
            if (teacherId) {
                // eslint-disable-next-line
                dispatch(setTeacherPoints(Points.filter((tp) => tp.teacher == teacherId)));
            }
            else {
                dispatch(setTeacherPoints(Points));
            }

        }
        catch (err) {
            console.log(err)
        }
    }
}

export function addPoint(PointData, pk) {
    PointData.date = moment(new Date()).format('YYYY-MM-DD HH:mm')
    let point = new Point(PointData)
    point.route = [pk]
    return async (dispatch) => {
        try {
            await $.ajax({
                url: `${process.env.REACT_APP_BASE_URL}/api/point/`,
                type: "POST",
                contentType: 'application/json',
                data: JSON.stringify(point),
                "headers": {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
                    Authorization: "Token " + getToken(),
                },
                success: function (response) {
                    dispatch(getAllRoutes())
                },
                error: function (error) {
                    console.log(error);
                }
            })
        }
        catch (err) {
            console.log(err)
        }
    }
}
export function getAllRoutes() {
    return async (dispatch) => {
        try {
            const Route = await fetchRoutes();
            dispatch(setRoute(Route));
        }
        catch (err) {
            console.log(err)
        }
    }
}
export function getAllPoints() {
    return async (dispatch) => {
        try {
            const Point = await fetchPoints();
            dispatch(setPoint(Point));
        }
        catch (err) {
            console.log(err)
        }
    }
}


export default (
    setTeacherPoints,
    resetEditedRoute,
    addRemovedPk,
    editRoute,
    dragMarker,
    setUserCurrentLocation,
    changeMarkerOrder,
    deleteMarker,
    deleteNavigationMarker,
    setIsAdminValue,
    setEditedRoute,
    resetEditedMarker,
    setRouteViibility,
    setNavigationRoute,
    setTeacherCurrentLesson,
    resetTeacherCurrentLesson,
    resetNavigationRoute,
    getAllPoints
);