import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { Checkbox, Box, TextField, Button, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import "react-dates/lib/css/_datepicker.css";
import  SettingsCard from './SettingsCard'
import DatePicker from './DatePicker'
import store from '../redux/store'
import "react-dates/initialize"



const useStyles = makeStyles((theme) => ({

  lessonsField:{
    textAlign: 'center',
  },
  buttonContainer:{
    display: 'flex',
    justifyContent: 'flex-end',
  },
  singleButton:{
    paddingLeft:"10px",
    marginLeft:"10px!important",
    marginRight:"10px!important",
    marginBottom:"50px!important"
  }
}));


const SettingsForm=({settings, onChange, onSave, onDelete, showValidDialog, hideValidDialog}) => {
    const classes = useStyles();

    const {
      startDate, endDate, isActive,
      maxLessonsPerWeek, maxLessons,
      defaultRate
    } = settings;

    const[blockedButton, setblockedButton] = useState(false);

    useEffect(() => {
      validMaxLessons()
    })

    const handleChange = (e) =>{
      try{
        const data = {};
          if (e.target.type === 'checkbox') {
            data[e.target.name] = e.target.checked;
          } else {
            data[e.target.name] = e.target.type === 'number'
            ? Number(e.target.value) : e.target.value;
          }
          onChange(data);
          validMaxLessons()
      }catch(Err){
        console.log("fetch error")
      }
    }

    function validMaxLessons(){
      var maxLessonsPW;
      var maxLessons;

      if(store.getState().settings.editedSettings.maxLessonsPerWeek != null){
        maxLessonsPW = store.getState().settings.editedSettings.maxLessonsPerWeek
      }else{
        maxLessonsPW = store.getState().settings.settingsList.maxLessonsPerWeek
      }

      if(store.getState().settings.editedSettings.maxLessons != null){
        maxLessons = store.getState().settings.editedSettings.maxLessons
      }else{
        maxLessons = store.getState().settings.settingsList.maxLessons
      }

      if(maxLessonsPW>maxLessons){
        showValidDialog()
        setblockedButton(true)
      }else if(maxLessonsPW<=maxLessons){
        hideValidDialog()
        setblockedButton(false)
      }
    }

    return (
      <Container component="main" maxWidth="md">
          <Navbar/>

          <Box sx={{marginTop: 20}}></Box>

          <SettingsCard
            title="Aktywne daty"
            >
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              onChange={onChange}
            />
          </SettingsCard>

          <SettingsCard
            title="System aktywny dla kursantów"
            >
            <Box
              display="flex"
              justifyContent="center">
              <Checkbox
                color="primary"
                name="isActive"
                onChange={handleChange}
                checked={isActive}
                />
            </Box>
          </SettingsCard>

          <SettingsCard
            title="Maksymalna liczba lekcji kursanta w tygodniu"
            >
              <TextField
                className={classes.lessonsField}
                variant="outlined"
                name="maxLessonsPerWeek"
                value={maxLessonsPerWeek}
                margin="dense"
                type="number"
                inputProps={{
                  min: 0,
                  style: { textAlign: 'center' }
                }}
                onChange={handleChange}
                placeholder="12345"
                fullWidth
              />
          </SettingsCard>

          <SettingsCard
            title="Maksymalna liczba lekcji kursanta"
            >
              <TextField
                className={classes.lessonsField}
                variant="outlined"
                name="maxLessons"
                value={maxLessons}
                margin="dense"
                type="number"
                inputProps={{
                  min: 0,
                  style: { textAlign: 'center' }
                }}
                onChange={handleChange}
                placeholder="12345"
                fullWidth
              />
          </SettingsCard>
          
          <SettingsCard
            title="Domyślna stawka godzinowa"
            >
              <TextField
                className={classes.lessonsField}
                variant="outlined"
                name="defaultRate"
                value={defaultRate}
                margin="dense"
                type="number"
                inputProps={{
                  min: 0,
                  style: { textAlign: 'center' }
                }}
                onChange={handleChange}
                placeholder="12345"
                fullWidth
              />
          </SettingsCard>

          <div className={classes.buttonContainer}>
            <Button
              className={classes.singleButton}
              color="error"
              variant="contained"
              onClick={onDelete}
              >
              Usuń ustawienia
            </Button>

            <Button
              className={classes.singleButton}
              color="primary"
              variant="contained"
              onClick={onSave}
              disabled={blockedButton}
              >
              Zapisz zmiany
            </Button>
          </div>

      </Container>
  );
}

export default SettingsForm;