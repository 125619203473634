import { SETTINGS } from '../const/actionTypes.js';
import $ from 'jquery';
import moment from 'moment';
import { callAjax, getToken } from '../../auth/ApiAuth.js';

export const setSettings = item =>{
    return {
        type: SETTINGS.SET_CURRENT_SETTINGS,
        item
    }
}

export function saveSetting(settingsData ,id){
    settingsData.startDate=moment(settingsData.startDate).format("YYYY-MM-DD")
    settingsData.endDate=moment(settingsData.endDate).format("YYYY-MM-DD")

    var url= document.location.href;
    window.history.pushState({}, "", url.split("?")[0]);
    
    var sendType = "post"
    var sendURL = `${process.env.REACT_APP_BASE_URL}/api/setting/`

    if(id.length !== 0){
        sendURL= `${process.env.REACT_APP_BASE_URL}/api/setting/`+id+'/'
        sendType="put"
    }

    return async(dispatch)=>{
        await $.ajax({
            url: sendURL,
            type: sendType,
            data: JSON.stringify(settingsData),
            contentType: 'application/json',
            "headers": {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
                Authorization: "Token " + getToken(),
            },
            success: function (response) {
                let url = window.location.href+"?success=1";
                window.location.href = url
            },
            error: function (error) {
                let url = window.location.href+"?success=0";
                window.location.href = url
                console.log(error);
            }
        })
    }
    
    }

export function resetSetting(id){
    const settingsData = {
        startDate: null,
        endDate: null,
        isActive: false,
        maxLessonsPerWeek: null,
        maxLessons: null
    }

    var url= document.location.href;
    window.history.pushState({}, "", url.split("?")[0]);
    
    return async(dispatch)=>{
        await $.ajax({
            url: `${process.env.REACT_APP_BASE_URL}/api/setting/`+id+`/`,
            type: "PUT",
            data: JSON.stringify(settingsData),
            contentType: 'application/json',
            "headers": {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
                Authorization: "Token " + getToken(),
            },
            success: function (response) {
                let url = window.location.href+"?success=1";
                window.location.href = url
            },
            error: function (error) {
                let url = window.location.href+"?success=0";
                window.location.href = url
                console.log(error);
            }
        })
    }
}

const fetchSettings = async () => {
    let baseUrl = `${process.env.REACT_APP_BASE_URL}/api/setting/?last`;
    return callAjax(baseUrl);
}


export const editSettings = (editedSettings) => ({
    type: SETTINGS.EDIT_SETTINGS,
    editedSettings,
  });

const clearSettings = () => ({
    type: SETTINGS.CLEAR_SETTINGS_STATE
})

export function getAllSettings(){
    return async(dispatch) => {
        try{
            const settings = await fetchSettings();
            dispatch(clearSettings())
            dispatch(setSettings(settings[0]));
        }catch(err){}
    }
}

export default(
    setSettings,
    getAllSettings,
    saveSetting,
    resetSetting,
    editSettings
)