import React, { useState } from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from "../images/drive_pro_logo.png";
import { resetPassword } from '../auth/ApiAuth';
import { useHistory } from 'react-router-dom';
import useKeypress from 'react-use-keypress';
import Footer from "../components/Footer";
import { Fade } from '@mui/material';


export function ResetPass() {
  //react router nav
  let history = useHistory();

  //sates for label from form
  const [email, setEmail] = useState("");
  const [passFieldState, setPassFieldState] = useState(true);
  const [boxFormState, setBoxFormState] = useState(true);

  //If press enter
  useKeypress('Enter', () => {
    resetPassword(email).then(function(result){
      setPassFieldState(false)
      setTimeout(() => {
        setBoxFormState(false)
      }, 1000)
    })
  });

  function resetPasswordOnClick(){
    resetPassword(email).then(function(result){
      setPassFieldState(false)
      setTimeout(() => {
        setBoxFormState(false)
      }, 1000)
    })
  }

  return (
    <Box>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <AppBar
          style={{ background: '#3F51B5' }}
        >
          <Toolbar>
            <img
              src={logo}
              alt="drive-pro-logo"
              style={{
                height: '40px',
              }}
            />
          </Toolbar>
        </AppBar>

        

        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#4D98E9' }}>
            <RotateLeftIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset hasła
          </Typography>

          <Typography 
            variant="body2"
            align="center"
            style={{
                color:'#727272',
                paddingTop:'20px',
                paddingBottom:'20px',
            }}
            >
            Wprowadź adres e-mail zarejestrowanego konta, aby zresetować swoje hasło.
            Jeżeli adres jest poprawny otrzymasz na skrzynkę link resetujący.
          </Typography>
          {boxFormState ? (
              <>
              <Fade in={passFieldState} timeout={800}>
              <Box component="form">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button
                    id="buttonLogin"
                    style={{ background: '#3F51B5' }}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => resetPasswordOnClick()}
                  >
                    Wyślij link resetujący
                  </Button>
                
                <Typography
                  align="center"
                  style={{
                    color:'rgb(63, 81, 181)',
                    cursor: 'pointer',
                  }}
                  onClick={() => history.push("/login")}
                  >
                  Zaloguj się
                </Typography>

              </Box>
            </Fade>
            </>
          ) : (
            <>
              <Box>
                <Typography
                  variant="h6"
                  style={{
                    color:'#096BCC',
                    paddingTop:'30px',
                }}
                >
                  Sprawdź swoją skrzynkę pocztową.
                </Typography>

                <Typography
                  align="center"
                  style={{
                    paddingTop:'50px',
                    color:'rgb(63, 81, 181)',
                    cursor: 'pointer',
                  }}
                  onClick={() => history.push("/login")}
                  >
                  Powrót do strony logowania
                </Typography>
              </Box>
            </>
          )}
          
        </Box>
      </Container>
      <Footer/>
    </Box>
  );
}

export default ResetPass;
