import React, { useState } from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from "../images/drive_pro_logo.png";
import { changePassword } from '../auth/ApiAuth';
import { useHistory } from 'react-router-dom';
import useKeypress from 'react-use-keypress';
import Footer from "../components/Footer";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import eventEmitter from '../event/EventEmitter'
import { useParams } from "react-router-dom";

export function ResetPassConfirm() {
  //react router nav
  let history = useHistory();
  const { id } = useParams();
  const { token } = useParams();

  //sates for label from form
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  //If press enter
  useKeypress('Enter', () => {
    validatePassword(password, passwordConfirm)
  });

 async function validatePassword(password, passwordConfirm) {
        if(password === passwordConfirm){
            const success = changePassword(id, token, password, passwordConfirm)            
            success.then(
              (result) => {
                setTimeout(() =>{
                  history.push("/login")
                },1200)
              });
        }else{
            eventEmitter.emit('popupAlert', ['Hasła się różnią!', 'error'])
        }
  }

  return (
    <Box>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <AppBar
          style={{ background: '#3F51B5' }}
        >
          <Toolbar>
            <img
              src={logo}
              alt="drive-pro-logo"
              style={{
                height: '40px',
              }}
            />
          </Toolbar>
        </AppBar>

       

        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#3FBF3F' }}>
            <VpnKeyIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Potwierdź nowe hasło
          </Typography>

          <Typography 
            variant="body2"
            align="center"
            style={{
                color:'#727272',
                paddingTop:'20px',
                paddingBottom:'20px',
            }}
            >
            Wprowadź dwukrotnie nowe hasło aby zatwierdzić zmiany.
          </Typography>
           

          <Box component="form">
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Nowe hasło"
              name="password"
              type='password'
              autoFocus
              onChange={(e) => setPassword(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="passwordConfirm"
              label="Potwierdź nowe hasło"
              name="passwordConfirm"
              type='password'
              autoFocus
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />

            <Button
              id="buttonLogin"
              style={{ background: '#3F51B5' }}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => validatePassword(password, passwordConfirm)}
            >
              Zresetuj hasło
            </Button>

            <Typography
              align="center"
              style={{
                color:'rgb(63, 81, 181)',
                cursor: 'pointer',
              }}
              onClick={() => history.push("/login")}
              >
              Zaloguj się
            </Typography>

          </Box>
        </Box>
      </Container>
      <Footer/>
    </Box>
  );
}

export default ResetPassConfirm;
