class Marker {
    constructor(data = {}) {
        const {
            pk,
            title,
            desc,
            routes,
            createdByAdmin,
            createdBy,
            isArchived,
        } = data;

        this.pk = pk || '';
        this.title = title || '';
        this.desc = desc || '';
        this.routes = routes || [];
        this.createdByAdmin = createdByAdmin || false;
        this.createdBy = createdBy || null;
        this.isArchived = isArchived || false;
    }
}

export default Marker;