import React from 'react';
import { CardContent, Typography, Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';



const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'visible',
        },
      },
    }
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'visible',
    marginBottom: 10,
  },
}));

const SettingsCard = ({title, children}) => {

    
    const classes = useStyles();

    return (
      <ThemeProvider theme={theme}>
        <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={8}>
              <Typography variant="subheading" component="h3" >
                <b>{title}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>{children}</Grid>
          </Grid>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}

export default SettingsCard;
