import {
    Container,
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup
} from "react-leaflet"

import L from "leaflet"

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { useState } from "react";

const LiveMapBox = ({
    Map,
    onMapLoad,
    teacherPoints,
    teachersList,
}) => {

    const {
        center,
        zoom,
    } = Map
    var style_id = "ckvqikieg4wke15mguuhcu3mj"
    const useStyles = makeStyles((theme) => ({

        Popup: {
            alignItems: "center",
            padding: "5px",
            width: "60px",
            height: "100px",
        },
    }));
    const [markerClick, setMarkerClick] = useState(false)
    const DefaultIcon = new L.icon({
        iconUrl: icon,
        iconShadow: iconShadow,
        iconAnchor: [13, 41],

    })
    const classes = useStyles();
    return (
        <Container maxWidth="100%" >
            <MapContainer id='mapCont' center={center} zoom={zoom} whenCreated={onMapLoad} style={{ width: '100%', height: '75vh' }}>
                <TileLayer

                    attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                    url={`https://api.mapbox.com/styles/v1/${process.env.REACT_APP_MAPBOX_USERNAME}/${style_id}/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_ACCES_TOKEN}`}

                />
                {teacherPoints.map((marker) =>
                    <Marker
                        position={marker}
                        draggable={false}
                        icon={DefaultIcon}
                        key={marker.pk}
                        eventHandlers={{
                            click: (e) => {
                                setMarkerClick(true)
                            }
                        }}
                    >
                        {markerClick ? (<Popup className={classes.Popup}>
                            {teachersList!==null? teachersList.filter((t)=>{if(t.pk===marker.teacher)return t; else return false})[0].last_name : marker.teacher}
                        </Popup>) :
                            (<></>)}
                    </Marker>
                )}
            </MapContainer>
        </Container>
    )
}
export default LiveMapBox