import { SETTINGS } from "../const/actionTypes";
import Settings from "../../model/Settings";

const initialState = {
  settingsList : new Settings(),
    settingsLoading: false,
    settingsLoaded: false,
    editedSettings: {}
};

const settingsReducer = (state = initialState, action) => {
    switch(action.type){
        case SETTINGS.SET_CURRENT_SETTINGS:
            return{
                ...state,
                settingsLoading: true,
                settingsList: new Settings(action.item)
            };
        case SETTINGS.SET_SETTINGS_LOADING:
            return{
                //...state,
                settingsLoading: action.loading,
                settingsLoaded: action.loading,
            }
        case SETTINGS.CLEAR_SETTINGS_STATE:
            return initialState
        case SETTINGS.EDIT_SETTINGS: {
            return {
              ...state,
              settingsList: new Settings({
                ...state.settingsList,
                ...action.editedSettings,
              }),
              editedSettings: {
                ...state.editedSettings,
                ...action.editedSettings,
              },
            };
          }
          
        case SETTINGS.RESET_EDITED_SETTINGS: {
          return {
            ...state,
            editedSettings: {},
          };
        }
        default:
            return state;
    }
};

export default settingsReducer;