import React from 'react';
import { makeStyles } from '@mui/styles';
import { AppBar, Toolbar, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../images/drive_pro_logo.png';
import AppBarCollapse from "./AppBarCollapse";

const useStyles = makeStyles((theme) => ({
    navigation:{
      backgroundColor: '#3f51b5!important',
      color:"#fff",
    }
  }));

export default function Navbar(props){
    const classes = useStyles();
    return(
    <Box sx={{flexGrow: 1}}>
        <AppBar position="fixed" className={classes.navigation}>
            <Toolbar>
              <RouterLink to="/calendar">
                <img
                src={logo}
                alt="drive-pro-logo"
                style={{ height: '40px',}}
                />
              </RouterLink>
                <Box sx={{ flexGrow: 1 }}></Box>

                <AppBarCollapse
                  isWarning={props.isWarning}
                />
            </Toolbar>
        </AppBar>
    </Box>


    )
  }
