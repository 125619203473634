import User from './User';

class Driver extends User {
    constructor(data = {}) {
        super(data);

        const {
            phoneNumber,
            distanceTraveled,
            drivingTime,
            mistakesCount,
            mistakesValue,
        } = data;

        this.phoneNumber = phoneNumber || '';
        this.distanceTraveled = distanceTraveled || 0;
        this.mistakesCount = mistakesCount || 0;
        this.mistakesValue = mistakesValue || 0;
        this.drivingTime = drivingTime || 0;
    }
}

export default Driver;