import { DRIVERS } from "../const/actionTypes";
import Driver from "../../model/Driver";

const initialState = {
    driversList: [],
    driversLoading: false,
    driversLoaded: false,
    editedDriver: new Driver()

};
const driverReducer = (state = initialState, action) => {
    switch (action.type) {
        case DRIVERS.SET_DRIVERS:
            const driversList = action.item.map((driver) => new Driver(driver))
            return {
                ...state,
                driversLoading: false,
                driversLoaded: true,
                driversList
            };
        case DRIVERS.CLEAR_DRIVERS:
            return initialState;
        case DRIVERS.SET_EDITED_DRIVER:
            return {
                ...state,
                editedDriver: new Driver(action.editedDriver)
            }
        case DRIVERS.SET_DRIVERS_LOADING:
            return {
                ...state,
                driversLoading: action.loading,
            };
        case DRIVERS.EDIT_DRIVER:
            return {
                ...state,
                editedDriver: new Driver({
                    ...state.editedDriver,
                    ...action.editedDriverData,
                }),
            }
        case DRIVERS.RESET_EDITED_DRIVER: {
            return {
                ...state,
                editedDriver: new Driver(),
            };
        }
        default:
            return state;
    }

};

export default driverReducer;
